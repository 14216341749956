/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React, { Component } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga';

class InsulinList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      filtered: [],
      disabled: false,
    };
  }

  //we get the data from the system on this call
  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.loadData(this);
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  setError(error, disabled, loading) {
    this.setState({
      error,
      disabled,
      loading,
    });
  }

  loadData(mythis) {
    // GET request using fetch with error handling
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
    };

    fetch(window.configuration.InsulinURL, requestOptions)
      .then(async (response) => {
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          return null;
        }
        //did we get an error?
        //no
        else {
          var insulins = await response.json();

          //create our rows
          var insulinObjects = insulins.map(function (insulin) {
            return {
              id: insulin.id,
              tradeName: insulin.tradeName,
              insulinType: insulin.insulinType,
              notes: insulin.notes,
              enabled: insulin.enabled,
              addedByName: insulin.addedByName
                ? insulin.addedByName
                : 'unknown',
              dateAdded: new Date(insulin.dateAdded).toLocaleDateString(),
              updatedByName: insulin.updatedByName
                ? insulin.updatedByName
                : 'unknown',
              dateUpdated: new Date(insulin.dateUpdated).toLocaleDateString(),
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  <ReactTooltip />
                  <Link to={'/in/f/insulin?id=' + insulin.id}>
                    <Button
                      data-tip="edit"
                      className="btn-icon btn-round"
                      color={'success'}
                      size="sm"
                    >
                      <i className="fa fa-edit" />
                    </Button>
                  </Link>
                  {/* spacer between buttons */ ' '}
                  <Button
                    data-tip="remove"
                    onClick={() => {
                      //confirm that they want to remove it
                      confirmAlert({
                        closeOnEscape: true,
                        closeOnClickOutside: true,
                        onClickOutside: () => {},
                        onKeypressEscape: () => {},
                        willUnmount: () => {},
                        afterClose: () => {},
                        message:
                          'Are you sure you want to remove the insulin ' +
                          insulin.tradeName +
                          '?',
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                              // DELETE request using fetch with error handling
                              var requestOptionsDelete = {
                                method: 'DELETE',
                                headers: {
                                  'Content-Type': 'application/json',
                                  SECRET: localStorage.getItem('SECRET'),
                                  PASSPHRASE: localStorage.getItem(
                                    'PASSPHRASE',
                                  ),
                                },
                              };

                              fetch(
                                window.configuration.InsulinURL +
                                  '?id=' +
                                  insulin.id,
                                requestOptionsDelete,
                              )
                                .then(async (response) => {
                                  // check for error response
                                  //did we get an error?
                                  //no
                                  if (response.ok) {
                                    //refresh the page
                                    myThis.loadData(myThis);
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            },
                          },
                          {
                            label: 'No',
                          },
                        ],
                      });
                    }}
                    className="btn-icon btn-round"
                    color={'danger'}
                    size="sm"
                  >
                    <i className="fa fa-times" />
                  </Button>
                </div>
              ),
            };
          });

          //grab our response
          this.setState({
            data: insulinObjects,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          loading: false,
        });
        return null;
      });
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  render() {
    //are we loading?
    //yes
    if (this.state.loading === true) {
      return (
        <>
          <Loader
            height="0"
            width="0"
            type="ThreeDots"
            color="#00BFFF"
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              background: '#99CCFF',
              opacity: '0.6',
              zIndex: '998',
              height: '100%',
              width: '100%',
            }}
          />
        </>
      );
    }
    return (
      <>
        {this.state.loading ? (
          <Loader
            height="0"
            width="0"
            type="ThreeDots"
            color="#00BFFF"
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              background: '#99CCFF',
              opacity: '0.6',
              zIndex: '998',
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          ''
        )}
        <PanelHeader size="sm" />
        <div className="content" id="top">
          <Row>
            <Col xs={12} md={8}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Insulins</CardTitle>
                  <CardTitle>
                    <Link style={{ color: 'blue' }} to={'/in/f/insulin'}>
                      Add a new insulin
                    </Link>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable={true}
                    filtered={this.state.filtered}
                    onFilteredChange={(filtered, column, value) => {
                      this.onFilteredChangeCustom(
                        value,
                        column.id || column.accessor,
                      );
                    }}
                    defaultFilterMethod={(filter, row, column) => {
                      const id = filter.pivotId || filter.id;
                      if (typeof filter.value === 'object') {
                        return row[id] !== undefined
                          ? filter.value.indexOf(row[id]) > -1
                          : true;
                      } else {
                        return row[id] !== undefined
                          ? String(row[id].toLowerCase()).indexOf(
                              filter.value.toLowerCase(),
                            ) > -1
                          : true;
                      }
                    }}
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'tradeName',
                        width: 300,
                      },
                      {
                        Header: 'Type',
                        accessor: 'insulinType',
                        width: 200,
                      },
                      {
                        Header: 'Updated',
                        accessor: 'dateUpdated',
                        width: 90,
                        //sort the dates
                        sortMethod: (a, b) => {
                          a = new Date(a).getTime();
                          b = new Date(b).getTime();
                          return b > a ? 1 : -1;
                        },
                      },
                      {
                        Header: 'Updated By',
                        accessor: 'updatedByName',
                        width: 150,
                      },
                      {
                        Header: '',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={this.state.data.length}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default InsulinList;
