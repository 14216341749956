import React from 'react';

const SendIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 74.29 74.29"
  >
    <path
      id="Icon"
      d="M0,33.3,70.29,0l-33.3,70.29-7.4-29.6L0,33.3Z"
      transform="translate(2 2)"
      fill="none"
      stroke="#000a3e"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="6"
    />
  </svg>
);

export default SendIcon;
