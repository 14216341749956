/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Form,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import moment from 'moment';

const queryString = require('query-string');

const optionsTiming = [
  { index: 0, value: '7', label: 'Last 7 days' },
  { index: 1, value: '14', label: 'Last 2 weeks' },
  { index: 2, value: '30', label: 'Last 30 days' },
  { index: 3, value: '60', label: 'Last 60 days' },
];

class GlucoseReadingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newRows: [],
      data: [],
      timingIndex: 0,
      timing: '7',
      patientId: '',
      nickName: '',
      firstName: '',
      lastName: '',
      testPatient: false,
      insulinMorning: '',
      insulinBreakfast: '',
      insulinLunch: '',
      insulinDinner: '',
      insulinNighttime: '',
      error: '',
      loading: true,
    };
  }

  Cancel() {
    this.props.history.push('/in/l/patients#top');
  }

  GoToPrescriptions() {
    // GET request using fetch with error handling
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
    };

    var qs = queryString.parse(this.props.location.search);

    fetch(
      window.configuration.InsulinPrescriptionURL +
        '?patientId=' +
        qs['patientId'],
      requestOptions,
    )
      .then(async (response) => {
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          return null;
        }
        //did we get an error?
        //no
        else {
          var insulinPrescriptions = await response.json();

          if (insulinPrescriptions.length > 0) {
            this.props.history.push(
              '/in/f/insulinPrescription?id=' +
                insulinPrescriptions[0].id +
                '&patientId=' +
                this.state.patientId +
                '&testPatient=' +
                this.state.testPatient,
            );
            return;
          } else {
            this.props.history.push(
              '/in/l/insulinprescriptions?patientId=' +
                this.state.patientId +
                '&testPatient=' +
                this.state.testPatient +
                '#top',
            );
            return;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.props.history.push(
      '/in/l/insulinprescriptions?patientId=' +
        this.state.patientId +
        '&testPatient=' +
        this.state.testPatient +
        '#top',
    );
  }

  componentDidMount() {
    this.loadData(this.state.timingIndex, this.state.timing);
  }

  loadData(timingIndex, timing) {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);

      //this is our base to get the data
      var requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          SECRET: localStorage.getItem('SECRET'),
          PASSPHRASE: localStorage.getItem('PASSPHRASE'),
        },
      };
      var qs = queryString.parse(this.props.location.search);
      //do we have a group id?
      //yes
      if (qs['patientId']) {
        fetch(
          window.configuration.GlucoseReadingURL +
            '?patientId=' +
            qs['patientId'] +
            '&startDate=' +
            moment(new Date()).add(-timing, 'day').format('YYYY-MM-DD'),
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to edit the patient',
                  disabled: true,
                  loading: false,
                });
              }
            }
            //did we get an error?
            //no
            else {
              var readingResponse = await response.json();

              //did we get a good one?
              //yes
              if (readingResponse) {
                var index = 0;
                var colorBackground = '';

                //create our rows
                var readingObjects = readingResponse.map(function (reading) {
                  index++;
                  colorBackground =
                    colorBackground === '#eef8ff' ? '' : '#eef8ff';

                  return (
                    <Row
                      key={'reading' + index}
                      style={{ height: 80, backgroundColor: colorBackground }}
                    >
                      <Col xs={12} md={2} style={{ paddingTop: 20 }}>
                        {moment(new Date(reading.readDate)).format('MMMM D')}
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{
                          textAlign: 'center',
                          paddingTop: 20,
                          backgroundColor:
                            reading.morning.status === 'hypo'
                              ? 'red'
                              : reading.morning.status === 'goal'
                              ? ''
                              : reading.morning.status === 'high'
                              ? 'yellow'
                              : reading.morning.status === 'danger'
                              ? 'lightblue'
                              : '',
                        }}
                      >
                        <Label
                          style={{
                            color:
                              reading.morning.status === 'hypo'
                                ? 'white'
                                : reading.morning.status === 'goal'
                                ? 'black'
                                : reading.morning.status === 'high'
                                ? 'black'
                                : reading.morning.status === 'danger'
                                ? 'black'
                                : 'black',
                          }}
                        >
                          {reading.morning && reading.morning.reading
                            ? reading.morning.reading
                            : '-'}
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{ textAlign: 'center', paddingTop: 20 }}
                      >
                        <Label style={{ color: 'black', width: '100%' }}>
                          {reading.morning && reading.morning.unitsAdministered
                            ? reading.morning.unitsAdministered
                            : '-'}
                        </Label>
                        <Label style={{ color: 'black', width: '100%' }}>
                          {reading.morning &&
                          reading.morning.unitsAdministered &&
                          reading.morning.insulinName
                            ? reading.morning.insulinName
                            : '-'}
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{
                          textAlign: 'center',
                          paddingTop: 20,
                          backgroundColor:
                            reading.breakfast.status === 'hypo'
                              ? 'red'
                              : reading.breakfast.status === 'goal'
                              ? ''
                              : reading.breakfast.status === 'high'
                              ? 'yellow'
                              : reading.breakfast.status === 'danger'
                              ? 'lightblue'
                              : '',
                        }}
                      >
                        <Label
                          style={{
                            color:
                              reading.breakfast.status === 'hypo'
                                ? 'white'
                                : reading.breakfast.status === 'goal'
                                ? 'black'
                                : reading.breakfast.status === 'high'
                                ? 'black'
                                : reading.breakfast.status === 'danger'
                                ? 'black'
                                : 'black',
                          }}
                        >
                          {reading.breakfast && reading.breakfast.reading
                            ? reading.breakfast.reading
                            : '-'}
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{ textAlign: 'center', paddingTop: 20 }}
                      >
                        <Label style={{ color: 'black', width: '100%' }}>
                          {reading.breakfast &&
                          reading.breakfast.unitsAdministered
                            ? reading.breakfast.unitsAdministered
                            : '-'}
                        </Label>
                        <Label style={{ color: 'black', width: '100%' }}>
                          {reading.breakfast &&
                          reading.breakfast.unitsAdministered &&
                          reading.breakfast.insulinName
                            ? reading.breakfast.insulinName
                            : '-'}
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{
                          textAlign: 'center',
                          paddingTop: 20,
                          backgroundColor:
                            reading.lunch.status === 'hypo'
                              ? 'red'
                              : reading.lunch.status === 'goal'
                              ? ''
                              : reading.lunch.status === 'high'
                              ? 'yellow'
                              : reading.lunch.status === 'danger'
                              ? 'lightblue'
                              : '',
                        }}
                      >
                        <Label
                          style={{
                            color:
                              reading.lunch.status === 'hypo'
                                ? 'white'
                                : reading.lunch.status === 'goal'
                                ? 'black'
                                : reading.lunch.status === 'high'
                                ? 'black'
                                : reading.lunch.status === 'danger'
                                ? 'black'
                                : 'black',
                          }}
                        >
                          {reading.lunch && reading.lunch.reading
                            ? reading.lunch.reading
                            : '-'}
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{ textAlign: 'center', paddingTop: 20 }}
                      >
                        <Label style={{ color: 'black', width: '100%' }}>
                          {reading.lunch && reading.lunch.unitsAdministered
                            ? reading.lunch.unitsAdministered
                            : '-'}
                        </Label>
                        <Label style={{ color: 'black', width: '100%' }}>
                          {reading.lunch &&
                          reading.lunch.unitsAdministered &&
                          reading.lunch.insulinName
                            ? reading.lunch.insulinName
                            : '-'}
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{
                          textAlign: 'center',
                          paddingTop: 20,
                          backgroundColor:
                            reading.dinner.status === 'hypo'
                              ? 'red'
                              : reading.dinner.status === 'goal'
                              ? ''
                              : reading.dinner.status === 'high'
                              ? 'yellow'
                              : reading.dinner.status === 'danger'
                              ? 'lightblue'
                              : '',
                        }}
                      >
                        <Label
                          style={{
                            color:
                              reading.dinner.status === 'hypo'
                                ? 'white'
                                : reading.dinner.status === 'goal'
                                ? 'black'
                                : reading.dinner.status === 'high'
                                ? 'black'
                                : reading.dinner.status === 'danger'
                                ? 'black'
                                : 'black',
                          }}
                        >
                          {reading.dinner && reading.dinner.reading
                            ? reading.dinner.reading
                            : '-'}
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{ textAlign: 'center', paddingTop: 20 }}
                      >
                        <Label style={{ color: 'black', width: '100%' }}>
                          {reading.dinner && reading.dinner.unitsAdministered
                            ? reading.dinner.unitsAdministered
                            : '-'}
                        </Label>
                        <Label style={{ color: 'black', width: '100%' }}>
                          {reading.dinner &&
                          reading.dinner.unitsAdministered &&
                          reading.dinner.insulinName
                            ? reading.dinner.insulinName
                            : '-'}
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{
                          textAlign: 'center',
                          paddingTop: 20,
                          backgroundColor:
                            reading.nighttime.status === 'hypo'
                              ? 'red'
                              : reading.nighttime.status === 'goal'
                              ? ''
                              : reading.nighttime.status === 'high'
                              ? 'yellow'
                              : reading.nighttime.status === 'danger'
                              ? 'lightblue'
                              : '',
                        }}
                      >
                        <Label
                          style={{
                            color:
                              reading.nighttime.status === 'hypo'
                                ? 'white'
                                : reading.nighttime.status === 'goal'
                                ? 'black'
                                : reading.nighttime.status === 'high'
                                ? 'black'
                                : reading.nighttime.status === 'danger'
                                ? 'black'
                                : 'black',
                          }}
                        >
                          {reading.nighttime && reading.nighttime.reading
                            ? reading.nighttime.reading
                            : '-'}
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{ textAlign: 'center', paddingTop: 20 }}
                      >
                        <Label style={{ color: 'black', width: '100%' }}>
                          {reading.nighttime &&
                          reading.nighttime.unitsAdministered
                            ? reading.nighttime.unitsAdministered
                            : '-'}
                        </Label>
                        <Label style={{ color: 'black', width: '100%' }}>
                          {reading.nighttime &&
                          reading.nighttime.unitsAdministered &&
                          reading.nighttime.insulinName
                            ? reading.nighttime.insulinName
                            : '-'}
                        </Label>
                      </Col>
                    </Row>
                  );
                });

                this.setState({
                  error: '',
                  loading: false,
                  disabled: false,
                  newRows: readingObjects,
                  testPatient: qs['testPatient'] === 'true',
                  timingIndex,
                  timing,
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: error,
              disabled: false,
              loading: false,
            });
          });

        fetch(
          window.configuration.PatientURL + '?id=' + qs['patientId'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to edit the patient',
                  disabled: true,
                  loading: false,
                });
              }
            }
            //did we get an error?
            //no
            else {
              var patientResponse = await response.json();

              //did we get a good one?
              //yes
              if (patientResponse.id) {
                this.setState({
                  error: '',
                  loading: false,
                  disabled: false,
                  patientId: patientResponse.id,
                  firstName: patientResponse.firstName,
                  lastName: patientResponse.lastName,
                  nickName: patientResponse.nickName,
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: error,
              disabled: false,
              loading: false,
            });
          });

        fetch(
          window.configuration.PatientURL + '?id=' + qs['patientId'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to edit the patient',
                  disabled: true,
                  loading: false,
                });
              }
            }
            //did we get an error?
            //no
            else {
              var patientResponse = await response.json();

              //did we get a good one?
              //yes
              if (patientResponse.id) {
                this.setState({
                  error: '',
                  loading: false,
                  disabled: false,
                  patientId: patientResponse.id,
                  firstName: patientResponse.firstName,
                  lastName: patientResponse.lastName,
                  nickName: patientResponse.nickName,
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: error,
              disabled: false,
              loading: false,
            });
          });
      }
      //do we have a group id?
      //no
      else {
        this.setState({ loading: false });
      }
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  _handleKeyDown = (e) => {
    try {
      //did they press enter?
      //yes
      if (e.key === 'Enter') {
        this.submit(null, null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //this submits the form
  submit(e, navigation) {
    //did we disable the button?
    //yes
    if (this.state.disabled) {
      return;
    }

    //do we have a name or generic name?
    //no
    if (
      this.state.tradeName.length === 0 &&
      this.state.genericName.length === 0
    ) {
      this.setState({
        error: "Please enter the insulin's trade or generic name",
      });
      return;
    }

    this.setState({
      disabled: true,
      error: '',
      loading: true,
    });

    // POST request using fetch with error handling
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
      body: JSON.stringify({
        //we set id because this is the insulin record
        id: this.state.id,
        tradeName: this.state.tradeName,
        genericName: this.state.genericName,
        insulinType: this.state.insulinType,
        notes: this.state.notes,
      }),
    };

    fetch(window.configuration.InsulinURL, requestOptions)
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          //did we get an unauthorized?
          //no
          else {
            //set the error
            this.setState({
              error: 'We were unable to add the insulin',
              disabled: false,
              loading: false,
            });
          }
          // get error message from body or default to response status
          const error = response.status;
          return Promise.reject(error);
        }
        //did we get an error?
        //no
        else {
          this.Cancel();
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          disabled: false,
          loading: false,
        });
      });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader
            height="0"
            width="0"
            type="ThreeDots"
            color="#00BFFF"
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              background: '#99CCFF',
              opacity: '0.6',
              zIndex: '998',
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          ''
        )}
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Form>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      {'Patient: ' +
                        this.state.firstName +
                        ' ' +
                        this.state.lastName +
                        (this.state.testPatient === true
                          ? ' (TEST PATIENT)'
                          : '')}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row style={{ paddingBottom: 20 }}>
                      <Col xs={12} md={4}>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          options={optionsTiming}
                          onChange={(item) => {
                            this.loadData(item.index, item.value);
                          }}
                          value={optionsTiming[this.state.timingIndex]}
                          placeholder="Select a timeframe"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={2}></Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                      >
                        <Label>MORNING</Label>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                      >
                        <Label>BREAKFAST</Label>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                      >
                        <Label>LUNCH</Label>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                      >
                        <Label>DINNER</Label>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                      >
                        <Label>NIGHT</Label>
                      </Col>
                    </Row>
                    <Row style={{ paddingBottom: 5 }}>
                      <Col xs={12} md={2}>
                        Patient Log
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #eee',
                        }}
                      >
                        <Label>
                          Glucose
                          <br />
                          (mg/dL)
                        </Label>
                      </Col>
                      <Col xs={12} md={1} style={{ textAlign: 'center' }}>
                        <Label>
                          Insulin
                          <br />
                          Units + Name
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #eee',
                        }}
                      >
                        <Label>
                          Glucose
                          <br />
                          (mg/dL)
                        </Label>
                      </Col>
                      <Col xs={12} md={1} style={{ textAlign: 'center' }}>
                        <Label>
                          Insulin
                          <br />
                          Units + Name
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #eee',
                        }}
                      >
                        <Label>
                          Glucose
                          <br />
                          (mg/dL)
                        </Label>
                      </Col>
                      <Col xs={12} md={1} style={{ textAlign: 'center' }}>
                        <Label>
                          Insulin
                          <br />
                          Units + Name
                        </Label>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #eee',
                        }}
                      >
                        <Label>
                          Glucose
                          <br />
                          (mg/dL)
                        </Label>
                      </Col>
                      <Col xs={12} md={1} style={{ textAlign: 'center' }}>
                        <Label>
                          Insulin
                          <br />
                          Units + Name
                        </Label>
                      </Col>
                      <Col xs={12} md={1} style={{ textAlign: 'center' }}>
                        <Label>
                          Glucose
                          <br />
                          (mg/dL)
                        </Label>
                      </Col>
                      <Col xs={12} md={1} style={{ textAlign: 'center' }}>
                        <Label>
                          Insulin
                          <br />
                          Units + Name
                        </Label>
                      </Col>
                    </Row>
                    {this.state.newRows}
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button
                      style={{ marginLeft: 10 }}
                      color="primary"
                      onClick={() => this.Cancel()}
                    >
                      No Changes
                    </Button>{' '}
                    <Button
                      style={{ marginLeft: 10 }}
                      color="success"
                      onClick={() => this.GoToPrescriptions()}
                    >
                      Change Prescription
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default GlucoseReadingsForm;
