/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React, { Component } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga';

class AppAuthList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      filtered: [],
      disabled: false,
    };
  }

  //we get the data from the system on this call
  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.loadData(this);
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  setError(error, disabled, loading) {
    this.setState({
      error,
      disabled,
      loading,
    });
  }

  loadData(myThis) {
    // GET request using fetch with error handling
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
    };

    fetch(window.configuration.AppAuthURL, requestOptions)
      .then(async (response) => {
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          return null;
        }
        //did we get an error?
        //no
        else {
          var auths = await response.json();

          //create our rows
          var authObjects = auths.map((auth) => {
            return {
              id: auth.id,
              emailAddress: auth.emailAddress,
              patientName: auth.patientName,
              phoneNumber: auth.phoneNumber
                ? '(' +
                  auth.phoneNumber.slice(0, 3) +
                  ') ' +
                  auth.phoneNumber.slice(3, 6) +
                  '-' +
                  auth.phoneNumber.slice(6, 10)
                : '',
              secret: auth.secret.substring(0, 10) + 'XXXXXX',
              dateAdded: new Date(auth.dateAdded).toLocaleDateString(),
              dateExpires: auth.dateExpires
                ? new Date(auth.dateExpires).toLocaleDateString()
                : 'Never',
              enabled: auth.enabled,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  <ReactTooltip />
                  <Button
                    data-tip={auth.enabled === true ? 'disable' : 'enable'}
                    className="btn-icon btn-round"
                    color={auth.enabled === true ? 'success' : 'danger'}
                    size="sm"
                    onClick={() => {
                      //enable or disable it
                      var enabled = auth.enabled ? false : true;

                      //get the group from the server
                      const requestOptions = {
                        method: 'PATCH',
                        headers: {
                          'Content-Type': 'application/json',
                          SECRET: localStorage.getItem('SECRET'),
                          PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                        },
                        body: JSON.stringify({
                          secret: auth.secret,
                          passPhrase: auth.passPhrase,
                          enabled: enabled,
                        }),
                      };

                      fetch(window.configuration.AppAuthURL, requestOptions)
                        .then(async (response) => {
                          // check for error response
                          //did we get an error?
                          //yes
                          if (!response.ok) {
                            // get error message from body or default to response status
                            const error = response.status;
                            return Promise.reject(error);
                          }
                          //did we get an error?
                          //no
                          else {
                            myThis.loadData(myThis);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                  >
                    <i
                      className={
                        auth.enabled === true
                          ? 'fa fa-toggle-on'
                          : 'fa fa-toggle-off'
                      }
                    />
                  </Button>
                  {/* spacer between buttons */ ' '}
                  <Button
                    data-tip="remove"
                    onClick={() => {
                      //confirm that they want to remove it
                      confirmAlert({
                        closeOnEscape: true,
                        closeOnClickOutside: true,
                        message:
                          'Are you sure you want to remove the auth ' +
                          auth.emailAddress +
                          '?',
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                              // DELETE request using fetch with error handling
                              var requestOptionsDelete = {
                                method: 'DELETE',
                                headers: {
                                  'Content-Type': 'application/json',
                                  SECRET: localStorage.getItem('SECRET'),
                                  PASSPHRASE: localStorage.getItem(
                                    'PASSPHRASE',
                                  ),
                                },
                              };

                              fetch(
                                window.configuration.AppAuthURL +
                                  '?id=' +
                                  auth.id,
                                requestOptionsDelete,
                              )
                                .then(async (response) => {
                                  // check for error response
                                  //did we get an error?
                                  //no
                                  if (response.ok) {
                                    //refresh the page
                                    myThis.loadData(myThis);
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            },
                          },
                          {
                            label: 'No',
                          },
                        ],
                      });
                    }}
                    className="btn-icon btn-round"
                    color={'danger'}
                    size="sm"
                  >
                    <i className="fa fa-times" />
                  </Button>
                </div>
              ),
            };
          });

          //grab our response
          this.setState({
            data: authObjects,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          loading: false,
        });
        return null;
      });
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content" id="top">
          <Row>
            <Col xs={12} md={9}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">App Auths</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    loading={this.state.loading}
                    data={this.state.data}
                    filterable={true}
                    filtered={this.state.filtered}
                    onFilteredChange={(filtered, column, value) => {
                      this.onFilteredChangeCustom(
                        value,
                        column.id || column.accessor,
                      );
                    }}
                    defaultFilterMethod={(filter, row, column) => {
                      const id = filter.pivotId || filter.id;
                      if (typeof filter.value === 'object') {
                        return row[id] !== undefined
                          ? filter.value.indexOf(row[id]) > -1
                          : true;
                      } else {
                        return row[id] !== undefined
                          ? String(row[id].toLowerCase()).indexOf(
                              filter.value.toLowerCase(),
                            ) > -1
                          : true;
                      }
                    }}
                    columns={[
                      {
                        Header: 'Added',
                        accessor: 'dateAdded',
                        width: 100,
                        //sort the dates
                        sortMethod: (a, b) => {
                          a = new Date(a).getTime();
                          b = new Date(b).getTime();
                          return b > a ? 1 : -1;
                        },
                      },
                      {
                        Header: 'Secret',
                        accessor: 'secret',
                        width: 200,
                      },
                      {
                        Header: 'Patient Name',
                        accessor: 'patientName',
                        width: 260,
                      },
                      {
                        Header: 'Phone Number',
                        accessor: 'phoneNumber',
                        width: 130,
                      },
                      {
                        Header: 'Date Expires',
                        accessor: 'dateExpires',
                        width: 170,
                        //sort the dates
                        sortMethod: (a, b) => {
                          a = new Date(a).getTime();
                          b = new Date(b).getTime();
                          return b > a ? 1 : -1;
                        },
                      },
                      {
                        Header: '',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={15}
                    showPaginationBottom
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AppAuthList;
