/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { CardTitle } from 'reactstrap';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import axios from 'axios';
import config from 'config';
import ThreadItem from 'components/Messaging/ThreadItem';
import moment from 'moment';
import MessageArea from 'components/Messaging/MessageArea';
import { useFormik } from 'formik';
import UserAvatar from 'react-user-avatar';
import phoneFormat from 'functions/phoneFormat';
import SendIcon from 'components/Messaging/SendIcon';
import { useParams, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import MessageCloseDialog from 'components/Messaging/MessageCloseDialog';
import TextareaAutosize from 'react-textarea-autosize';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '85%',
  },
  subContainer: {
    width: '95%',
    height: '90%',
    backgroundColor: '#2e3956',
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.50)',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
  },
  noThreadsContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noThreadsText: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: 'Montserrat',
    color: '#e8e8e8',
    marginBottom: 0,
  },
  leftPane: {
    width: '30%',
    overflowY: 'auto',
  },
  patientName: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: 'Montserrat',
    color: '#e8e8e8',
    marginBottom: 0,
  },
  patientBeginDate: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    color: '#8c8c8c',
  },
  hr: {
    border: 0,
    width: '100%',
    height: 0,
    margin: '8px 0 0 0',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderBottom: '1px solid #8c8c8c',
  },
  sendText: {
    color: '#8c8c8c',
    position: 'absolute',
    top: 0,
    right: 18,
    fontSize: 12,
  },
  rightPane: {
    width: '25%',
    padding: 24,
    overflowY: 'auto',
  },
  middleContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  middleHeader: {
    width: '100%',
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #8c8c8c',
    marginBottom: 8,
  },
  inputContainer: {
    width: '100%',
    padding: 18,
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  input: {
    width: '100%',
    padding: 8,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    outline: 'none',
    border: 'none',
    fontSize: 16,
    resize: 'none',
  },
  rightPaneAvatar: {
    fontSize: 32,
    fontFamily: 'Montserrat',
    marginBottom: 16,
  },
  quickActionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  qaButton: {
    margin: '0 8px',
  },
  avatarContainer: {
    minHeight: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dataTitle: {
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#787878',
    margin: '24px 0 16px 0',
  },
  data: {
    fontSize: 16,
    color: '#fff',
    marginBottom: 24,
  },
  smallBold: {
    fontWeight: 700,
  },
};

const Messaging = () => {
  const [threads, setThreads] = useState([]);
  const [activeThread, setActiveThread] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [sendHover, setSendHover] = useState(false);
  const [sendTextActive, setSendTextActive] = useState(false);
  const [dialog, setDialog] = useState(false);

  const { id } = useParams();

  const headers = {
    SECRET: localStorage.getItem('SECRET'),
    PASSPHRASE: localStorage.getItem('PASSPHRASE'),
  };

  const user = {
    firstName: localStorage.getItem('firstName'),
    lastName: localStorage.getItem('lastName'),
    userId: localStorage.getItem('userId'),
  };

  useEffect(() => {
    getMessages();
    const refreshThread = setInterval(getMessages, 5000);

    return () => clearInterval(refreshThread);
  }, []);

  useEffect(() => {
    // Will have to look for a routing fix for this param always being passed in
    if (headers && id !== ':id') {
      getPatient(id);
    }
  }, [headers]);

  const formik = useFormik({
    initialValues: {
      message: '',
    },
  });

  const activateThread = async (index) => {
    setActiveThread(threads[index]);
    getPatient(threads[index].patientId);
    const me = `${user?.firstName} ${user?.lastName}`;
    const patientId = threads[index]?.patientId;
    const recentMessage =
      threads[index]?.messages[threads[index]?.messages?.length - 1];

    if (recentMessage?.read === false && recentMessage?.sendingName !== me) {
      try {
        axios
          .patch(
            config.MessageURL,
            {},
            {
              params: {
                patientId: patientId,
                messageId: recentMessage?._id,
              },
              headers,
            },
          )
          .then(async ({ status }) => {
            if (status === 200) {
              await getMessages();
            }
          });
      } catch (err) {
        throw new Error(`activateThread(): ${err}`);
      }
    }
  };

  const getPatient = async (patientId) => {
    setPatientData(null);
    try {
      await axios
        .get(config.PatientURL, {
          params: {
            id: patientId,
            slim: true,
          },
          headers,
        })
        .then(({ data }) => {
          setPatientData(data);
        });
    } catch (err) {
      throw new Error(`getPatient(): ${err}`);
    }
  };

  const getMessages = async () => {
    try {
      await axios
        .get(config.MessageURL, {
          headers,
        })
        .then(({ data }) => {
          setThreads(data);
        });
    } catch (err) {
      throw new Error(`getMessages(): ${err}`);
    }
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    const trimmedMessage = formik.values.message.trim();
    if (trimmedMessage.length > 0) {
      setSendTextActive(true);
    } else {
      setSendTextActive(false);
    }
    if (event.which === 13 && !event.shiftKey) {
      try {
        await axios
          .post(
            config.MessageURL,
            {
              user: {
                _id: user?.userId,
                avatar: 0,
                name: `${user?.firstName} ${user?.lastName}`,
              },
              sendingId: user?.userId,
              text: trimmedMessage,
              receivingId: patientData?.id,
            },
            {
              headers,
            },
          )
          .then(async ({ data }) => {
            formik.values.message = '';
            setSendTextActive(false);
            setActiveThread(data);
            await getMessages();
          });
      } catch (err) {
        throw new Error(`sendMessage(): ${err}`);
      }
    }
  };

  const sendMessageViaArrow = async (event) => {
    event.preventDefault();
    const trimmedMessage = formik.values.message.trim();
    if (trimmedMessage.length > 0) {
      setSendTextActive(true);
    } else {
      setSendTextActive(false);
    }
    try {
      await axios
        .post(
          config.MessageURL,
          {
            user: {
              _id: user?.userId,
              avatar: 0,
              name: `${user?.firstName} ${user?.lastName}`,
            },
            sendingId: user?.userId,
            text: trimmedMessage,
            receivingId: patientData?.id,
          },
          {
            headers,
          },
        )
        .then(async ({ data }) => {
          formik.values.message = '';
          setSendTextActive(false);
          setActiveThread(data);
          await getMessages();
        });
    } catch (err) {
      throw new Error(`sendMessage(): ${err}`);
    }
  };

  const closeConversation = async () => {
    try {
      await axios
        .patch(
          config.MessageURL,
          {},
          {
            params: {
              patientId: patientData?.id,
            },
            headers,
          },
        )
        .then(async ({ status }) => {
          console.log(status);
          if (status === 200) {
            setActiveThread(null);
            setPatientData(null);
            await getMessages();
            setDialog(false);
          }
        });
    } catch (err) {
      throw new Error(`closeConversation(): ${err}`);
    }
  };

  return (
    <>
      <PanelHeader
        size={'sm'}
        content={
          <>
            <CardTitle style={{ color: '#FFFFFF', textAlign: 'center' }}>
              <h4>Patient Messaging</h4>
            </CardTitle>
          </>
        }
      />
      <div style={styles.container}>
        <div style={styles.subContainer}>
          {!threads.length ? (
            <div style={styles.noThreadsContainer}>
              <p style={styles.noThreadsText}>
                There are currently no active conversations
              </p>
            </div>
          ) : (
            <>
              {/* Left pane */}
              <div style={styles.leftPane}>
                {threads.map((item, index) => (
                  <ThreadItem
                    key={index}
                    thread={item}
                    admin={user}
                    onClick={() => activateThread(index)}
                  />
                ))}
              </div>
              {/* Left pane */}
              {/* ------ */}
              {/* Center chat pane */}
              <div
                style={{
                  width: '45%',
                  backgroundColor: '#000a3e',
                }}
              >
                <div style={styles.middleContainer}>
                  {patientData ? (
                    <>
                      <div style={styles.middleHeader}>
                        <div>
                          <p
                            style={styles.patientName}
                          >{`${patientData?.firstName} ${patientData?.lastName}`}</p>
                          <p style={styles.patientBeginDate}>
                            Patient since{' '}
                            {moment(patientData?.dateAdded).format('L')}
                          </p>
                        </div>
                        <Button
                          data-tip="Close Conversation"
                          className="btn-icon btn-round"
                          color={'danger'}
                          size="md"
                          onClick={() => setDialog(true)}
                        >
                          <i className="fa fa-window-close" />
                        </Button>
                      </div>
                      <MessageArea activeThread={activeThread} user={user} />
                      <form style={styles.inputContainer} onKeyUp={sendMessage}>
                        {sendTextActive ? (
                          <p style={styles.sendText}>
                            <span style={styles.smallBold}>Return</span> to send
                            - <span style={styles.smallBold}>Shift</span> +{' '}
                            <span style={styles.smallBold}>Return</span> to add
                            a new line
                          </p>
                        ) : null}
                        <TextareaAutosize
                          maxRows={10}
                          style={styles.input}
                          id="message"
                          name="message"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.message}
                          placeholder="Type a Message"
                          autoFocus
                          rows="1"
                          required
                          spellCheck
                        />
                        <div
                          style={{
                            backgroundColor: sendHover ? '#EAECF0' : '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0 8px',
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            cursor: 'pointer',
                          }}
                          onMouseEnter={() => setSendHover(true)}
                          onMouseLeave={() => setSendHover(false)}
                          onClick={sendMessageViaArrow}
                        >
                          <SendIcon />
                        </div>
                      </form>
                    </>
                  ) : null}
                </div>
              </div>
              {/* Center chat pane */}
              {/* ------ */}
              {/* Right pane */}
              <div style={styles.rightPane}>
                {patientData ? (
                  <>
                    <div style={styles.avatarContainer}>
                      <UserAvatar
                        color="#54e4fc"
                        size={120}
                        name={`${patientData?.firstName} ${patientData?.lastName}`}
                        style={styles.rightPaneAvatar}
                      />
                      <p
                        style={styles.patientName}
                      >{`${patientData?.firstName} ${patientData?.lastName}`}</p>
                      <div style={styles.quickActionButtons}>
                        <ReactTooltip />
                        <Link
                          target="_blank"
                          to={
                            '/in/f/glucosereadings?patientId=' +
                            patientData.id +
                            '#top'
                          }
                          style={styles.qaButton}
                        >
                          <Button
                            data-tip="review glucose readings"
                            className="btn-icon btn-round"
                            color={'success'}
                            size="md"
                          >
                            <i className="fa fa-table" />
                          </Button>
                        </Link>
                        <Link
                          target="_blank"
                          to={
                            '/in/l/insulinprescriptions?patientId=' +
                            patientData.id +
                            '&patientName=' +
                            patientData.firstName +
                            ' ' +
                            patientData.lastName +
                            '#top'
                          }
                          style={styles.qaButton}
                        >
                          <Button
                            data-tip="manage patient insulin regimen"
                            className="btn-icon btn-round"
                            color={'success'}
                            size="md"
                          >
                            <i className="fa fa-prescription-bottle-alt" />
                          </Button>
                        </Link>
                        <Link
                          target="_blank"
                          to={'/in/f/patient?id=' + patientData.id}
                          style={styles.qaButton}
                        >
                          <Button
                            data-tip="edit"
                            className="btn-icon btn-round"
                            color={'success'}
                            size="md"
                          >
                            <i className="fa fa-edit" />
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <hr style={styles.hr} />
                    <p style={styles.dataTitle}>Last Glucose Reading</p>
                    <p style={styles.data}>
                      {moment(patientData?.dateLastGlucose).format(
                        'MMMM Do h:mm a',
                      )}
                    </p>
                    <hr style={styles.hr} />
                    <p style={styles.dataTitle}>Date of Birth</p>
                    <p style={styles.data}>
                      {moment(patientData?.dateOfBirth).format('LL')}
                    </p>
                    <hr style={styles.hr} />
                    <p style={styles.dataTitle}>Email</p>
                    <p style={styles.data}>{patientData?.emailAddress}</p>
                    <hr style={styles.hr} />
                    <p style={styles.dataTitle}>Phone</p>
                    <p style={styles.data}>
                      {phoneFormat(patientData?.phoneMobile)}
                    </p>
                    <hr style={styles.hr} />
                  </>
                ) : null}
              </div>
              {/* Right pane */}
            </>
          )}
        </div>
      </div>
      <MessageCloseDialog
        open={dialog}
        closeDialog={() => setDialog(false)}
        closeConversation={closeConversation}
      />
    </>
  );
};

export default Messaging;
