const config = {
  GoogleAnalytics: 'UA-170155982-1',

  //for the various endpoints we will be using
  /* */
  AchievementURL: 'https://api.dosehelper.com/api/achievement',
  AppAuthURL: 'https://api.dosehelper.com/api/appauth',
  AppErrorURL: 'https://api.dosehelper.com/api/apperror',
  AuthURL: 'https://api.dosehelper.com/api/auth',
  BillingCodeURL: 'https://api.dosehelper.com/api/billingcode',
  EducationContentURL: 'https://api.dosehelper.com/api/educationcontent',
  GlucoseReadingURL: 'https://api.dosehelper.com/api/glucosereading',
  GoogleAuthURL: 'https://api.dosehelper.com/api/googleauth',
  InsulinURL: 'https://api.dosehelper.com/api/insulin',
  InsulinPrescriptionURL: 'https://api.dosehelper.com/api/insulinprescription',
  MedicationURL: 'https://api.dosehelper.com/api/medication',
  MemberURL: 'https://api.dosehelper.com/api/member',
  MessageURL: 'https://api.dosehelper.com/api/message',
  PatientURL: 'https://api.dosehelper.com/api/patient',
  PatientAlertURL: 'https://api.dosehelper.com/api/patientalert',
  PatientMessageURL: 'https://api.dosehelper.com/api/patientmessage',
  PatientPolicyURL: 'https://api.dosehelper.com/api/patientpolicy',
  PayorURL: 'https://api.dosehelper.com/api/payor',
  PayorPolicyURL: 'https://api.dosehelper.com/api/payorpolicy',
  PharmacyURL: 'https://api.dosehelper.com/api/pharmacy',
  PhysicianURL: 'https://api.dosehelper.com/api/physician',
  PCPSearchURL: 'https://api.dosehelper.com/api/v1/physician',
  PortalErrorURL: 'https://api.dosehelper.com/api/portalerror',
  RXNTCaseURL: 'https://api.dosehelper.com/api/rxntcase',
  RXNTPatientURL: 'https://api.dosehelper.com/api/rxntpatient',
  RXNTPayerURL: 'https://api.dosehelper.com/api/rxntpayer',
  RXNTProviderURL: 'https://api.dosehelper.com/api/rxntprovider',
  TimelogURL: 'https://api.dosehelper.com/api/timelog',
  TitrationPlanURL: 'https://api.dosehelper.com/api/titrationplan',
  /* */

  /* use these for local testing 
    AchievementURL: "http://localhost:7071/api/achievement",
    AppAuthURL: "http://localhost:7071/api/appauth",
    AppErrorURL: "http://localhost:7071/api/apperror",
    AuthURL: "http://localhost:7071/api/auth",
    BillingCodeURL: "http://localhost:7071/api/billingcode",
    EducationContentURL: "http://localhost:7071/api/educationcontent",
    GlucoseReadingURL: "http://localhost:7071/api/glucosereading",
    GoogleAuthURL: "http://localhost:7071/api/googleauth",
    InsulinURL: "http://localhost:7071/api/insulin",
    InsulinPrescriptionURL: "http://localhost:7071/api/insulinprescription",
    MedicationURL: "http://localhost:7071/api/medication",
    MemberURL: "http://localhost:7071/api/member",
    MessageURL: 'http://localhost:7071/api/message',
    PatientURL: "http://localhost:7071/api/patient",
    PatientAlertURL: "http://localhost:7071/api/patientalert",
    PatientMessageURL: "http://localhost:7071/api/patientmessage",
    PatientPolicyURL: "http://localhost:7071/api/patientpolicy",
    PayorURL: "http://localhost:7071/api/payor",
    PayorPolicyURL: "http://localhost:7071/api/payorpolicy",
    PharmacyURL: "http://localhost:7071/api/pharmacy",
    PhysicianURL: "http://localhost:7071/api/physician",
    PortalErrorURL: "https://localhost:7071/api/portalerror",
    RXNTCaseURL: "http://localhost:7071/api/rxntcase",
    RXNTPatientURL: "http://localhost:7071/api/rxntpatient",
    RXNTPayerURL: "http://localhost:7071/api/rxntpayer",
    RXNTProviderURL: "http://localhost:7071/api/rxntprovider",
    TimelogURL: "http://localhost:7071/api/timelog",
    TitrationPlanURL: "http://localhost:7071/api/titrationplan"
     */
};

export default config;
