/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, NavbarToggler, Container } from 'reactstrap';

class AuthNavbar extends React.Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? 'active' : '';
  };

  render() {
    return (
      <Navbar
        expand="lg"
        className={
          this.state.isOpen
            ? 'bg-white navbar-absolute'
            : 'navbar-transparent navbar-absolute'
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <NavbarToggler onClick={this.toggle}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <Link to="/" className="navbar-brand">
              DoseHelper
            </Link>
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default AuthNavbar;
