/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Input,
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import Select from 'react-select';

const queryString = require('query-string');

class BillingCodeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billingCodeId: '',
      payorData: [],
      payorIndex: -1,
      payorId: '',
      payorName: '',
      name: '',
      nameState: '',
      code: '',
      codeState: '',
      disabled: false,
      error: '',
      loading: true,
    };
  }

  Cancel() {
    this.props.history.push('/in/l/billingcodes#top');
  }

  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);

      //get the group from the server
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          SECRET: localStorage.getItem('SECRET'),
          PASSPHRASE: localStorage.getItem('PASSPHRASE'),
        },
      };

      var qs = queryString.parse(this.props.history.location.search);
      //do we have a group id?
      //yes
      if (qs['id']) {
        fetch(
          window.configuration.BillingCodeURL + '?id=' + qs['id'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to edit the billing code',
                  disabled: true,
                  loading: false,
                });
              }

              // get error message from body or default to response status
              const error = response.status;
              return Promise.reject(error);
            }
            //did we get an error?
            //no
            else {
              var billingCodeResponse = await response.json();

              //did we get a good one?
              //yes
              if (billingCodeResponse.id) {
                this.setState({
                  error: '',
                  loading: false,
                  disabled: false,
                  billingCodeId: billingCodeResponse.id,
                  payorId: billingCodeResponse.payorId,
                  payorName: billingCodeResponse.payorName,
                  name: billingCodeResponse.name,
                  nameState: billingCodeResponse.name
                    ? 'has-success'
                    : 'has-danger',
                  code: billingCodeResponse.code,
                  codeState: billingCodeResponse.code
                    ? 'has-success'
                    : 'has-danger',
                });
              }
            }
          })
          .catch((error) => {
            this.setState({
              error: error,
              disabled: false,
              loading: false,
            });
          });
      }

      fetch(window.configuration.PayorURL, requestOptions)
        .then(async (response) => {
          // check for error response
          //did we get an error?
          //yes
          if (!response.ok) {
            //did we get an unauthorized?
            //yes
            if (response.status === 401) {
              this.props.history.push('/a/logout');
            }

            // get error message from body or default to response status
            const error = response.status;
            return Promise.reject(error);
          }
          //did we get an error?
          //no
          else {
            var payorResponse = await response.json();

            var index = -1;

            //create our rows
            var payorObjects = payorResponse.map(function (payor) {
              index++;

              return {
                index: index,
                value: payor.id,
                label: payor.name ? payor.name : payor.name,
                id: payor.id,
              };
            });

            this.setState({
              payorData: payorObjects,
              payorIndex: this.state.payorId
                ? payorObjects.filter((o) => o.value === this.state.payorId)[0]
                    .index
                : -1,
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  _handleKeyDown = (e) => {
    try {
      //did they press enter?
      //yes
      if (e.key === 'Enter') {
        this.submit(null, null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //this submits the form
  submit(e, navigation) {
    //did we disable the button?
    //yes
    if (this.state.disabled) {
      return;
    }

    //do we have a name?
    //no
    if (this.state.payorId.length === 0) {
      this.setState({
        error: 'Please select the payor for the billing code',
      });
      return;
    }

    //do we have a name?
    //no
    if (this.state.name.length === 0) {
      this.setState({
        error: 'Please enter the name of the billing code',
      });
      return;
    }

    //do we have a code?
    //no
    if (this.state.code.length === 0) {
      this.setState({
        error: 'Please enter the billing code',
      });
      return;
    }

    this.setState({
      disabled: true,
      error: '',
      loading: true,
    });

    // POST request using fetch with error handling
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
      body: JSON.stringify({
        //we set id because this is the billingCode record
        id: this.state.billingCodeId,
        name: this.state.name,
        code: this.state.code,
        payorId: this.state.payorId,
        payorName: this.state.payorName,
      }),
    };

    fetch(window.configuration.BillingCodeURL, requestOptions)
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          //did we get an unauthorized?
          //no
          else {
            //set the error
            this.setState({
              error: 'We were unable to add the billing code',
              disabled: false,
              loading: false,
            });
          }
          // get error message from body or default to response status
          const error = response.status;
          return Promise.reject(error);
        }
        //did we get an error?
        //no
        else {
          this.props.history.push('/in/l/billingcodes#top');
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          disabled: false,
          loading: false,
        });
      });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader
            height="0"
            width="0"
            type="ThreeDots"
            color="#00BFFF"
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              background: '#99CCFF',
              opacity: '0.6',
              zIndex: '998',
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          ''
        )}
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={7}>
              <Form>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      {this.state.billingCodeId
                        ? 'Update Billing Code'
                        : 'Add Billing Code'}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={12} md={4}>
                        <FormGroup className={'has-label'}>
                          <Label>Payor</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            options={this.state.payorData}
                            onChange={(item) => {
                              this.setState({
                                payorId: item.value,
                                payorName: item.label,
                                payorIndex: item.index,
                                error: '',
                              });
                            }}
                            //we do >= 0 because without a "Select" the first is 0
                            value={
                              this.state.payorIndex >= 0
                                ? this.state.payorData[this.state.payorIndex]
                                : ''
                            }
                            placeholder="Select a payor"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={5}>
                        <FormGroup
                          className={'has-label ' + this.state.nameState}
                        >
                          <Label>Code Name *</Label>
                          <Input
                            type="text"
                            maxLength="150"
                            ref={(input) => {
                              this.nameInput = input;
                            }}
                            onChange={(e) =>
                              this.setState({
                                name: e.target.value,
                                nameState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                                error: '',
                              })
                            }
                            onKeyDown={this._handleKeyDown}
                            autoFocus
                            autoCorrect="true"
                            value={this.state.name}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup
                          className={'has-label ' + this.state.codeState}
                        >
                          <Label>Billing Code</Label>
                          <Input
                            type="text"
                            maxLength="25"
                            ref={(input) => {
                              this.codeInput = input;
                            }}
                            onChange={(e) =>
                              this.setState({
                                code: e.target.value,
                                codeState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                                error: '',
                              })
                            }
                            onKeyDown={this._handleKeyDown}
                            value={this.state.code}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <FormGroup
                          style={{
                            textAlign: 'end',
                            display: this.state.error ? '' : '',
                          }}
                        >
                          <Label
                            style={{
                              fontSize: 14,
                              color: 'tomato',
                              paddingRight: 15,
                              display: this.state.error ? '' : 'none',
                            }}
                          >
                            {this.state.error}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="success" onClick={(e) => this.submit(e)}>
                      {this.state.disabled
                        ? 'One moment...'
                        : this.state.billingCodeId
                        ? 'Update Billing Code'
                        : 'Add Billing Code'}
                    </Button>
                    {/* spacer between buttons */ ' '}
                    <Button
                      style={{ marginLeft: 10 }}
                      color="secondary"
                      onClick={() => this.Cancel()}
                    >
                      Cancel
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default BillingCodeForm;
