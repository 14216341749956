/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Input,
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import { Multiselect } from 'multiselect-react-dropdown';

const queryString = require('query-string');

//set up our state options
const optionsState = [
  { index: 0, value: '', label: 'Select one' },
  { index: 1, value: 'AL', label: 'Alabama' },
  { index: 2, value: 'AK', label: 'Alaska' },
  { index: 3, value: 'AZ', label: 'Arizona' },
  { index: 4, value: 'AR', label: 'Arkansas' },
  { index: 5, value: 'CA', label: 'California' },
  { index: 6, value: 'CO', label: 'Colorado' },
  { index: 7, value: 'CT', label: 'Connecticut' },
  { index: 8, value: 'DE', label: 'Delaware' },
  { index: 9, value: 'FL', label: 'Florida' },
  { index: 10, value: 'GA', label: 'Georgia' },
  { index: 11, value: 'HI', label: 'Hawaii' },
  { index: 12, value: 'ID', label: 'Idaho' },
  { index: 13, value: 'IL', label: 'Illinois' },
  { index: 14, value: 'IN', label: 'Indiana' },
  { index: 15, value: 'IA', label: 'Iowa' },
  { index: 16, value: 'KS', label: 'Kansas' },
  { index: 17, value: 'KY', label: 'Kentucky' },
  { index: 18, value: 'LA', label: 'Louisiana' },
  { index: 19, value: 'ME', label: 'Maine' },
  { index: 20, value: 'MD', label: 'Maryland' },
  { index: 21, value: 'MA', label: 'Massachusetts' },
  { index: 22, value: 'MI', label: 'Michigan' },
  { index: 23, value: 'MN', label: 'Minnesota' },
  { index: 24, value: 'MS', label: 'Mississippi' },
  { index: 25, value: 'MO', label: 'Missouri' },
  { index: 26, value: 'MT', label: 'Montana' },
  { index: 27, value: 'NE', label: 'Nebraska' },
  { index: 28, value: 'NV', label: 'Nevada' },
  { index: 29, value: 'NH', label: 'New Hampshire' },
  { index: 30, value: 'NJ', label: 'New Jersey' },
  { index: 31, value: 'NM', label: 'New Mexico' },
  { index: 32, value: 'NY', label: 'New York' },
  { index: 33, value: 'NC', label: 'North Carolina' },
  { index: 34, value: 'ND', label: 'North Dakota' },
  { index: 35, value: 'OH', label: 'Ohio' },
  { index: 36, value: 'OK', label: 'Oklahoma' },
  { index: 37, value: 'OR', label: 'Oregon' },
  { index: 38, value: 'PA', label: 'Pennsylvania' },
  { index: 39, value: 'RI', label: 'Rhode Island' },
  { index: 40, value: 'SC', label: 'South Carolina' },
  { index: 41, value: 'SD', label: 'South Dakota' },
  { index: 42, value: 'TN', label: 'Tennessee' },
  { index: 43, value: 'TX', label: 'Texas' },
  { index: 44, value: 'UT', label: 'Utah' },
  { index: 45, value: 'VT', label: 'Vermont' },
  { index: 46, value: 'VA', label: 'Virginia' },
  { index: 47, value: 'WA', label: 'Washington' },
  { index: 48, value: 'WV', label: 'West Virginia' },
  { index: 49, value: 'WI', label: 'Wisconsin' },
  { index: 50, value: 'WY', label: 'Wyoming' },
];

//set up our gender options
const optionsGender = [
  { index: 0, value: 'unknown', label: 'Unknown' },
  { index: 1, value: 'female', label: 'Female' },
  { index: 2, value: 'male', label: 'Male' },
  { index: 3, value: 'trans', label: 'Transexual' },
  { index: 4, value: 'nonbinary', label: 'Non-binary' },
  { index: 5, value: 'other', label: 'Other' },
];

const optionsRoles = [
  { index: 1, value: 'Admin', label: 'Admin' },
  { index: 2, value: 'Medical Assistant', label: 'Medical Assistant' },
  { index: 3, value: 'Provider', label: 'Provider' },
];

class MemberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      prefix: '',
      prefixState: '',
      firstName: '',
      firstNameState: '',
      middleName: '',
      middleNameState: '',
      lastName: '',
      lastNameState: '',
      suffix: '',
      suffixState: '',
      phoneMobile: '',
      phoneMobileState: '',
      emailAddressHome: '',
      emailAddressHomeState: '',
      emailAddressWork: '',
      emailAddressWorkState: '',
      ssn: '',
      ssnState: '',
      addressId: '',
      line1: '',
      line1State: '',
      line2: '',
      line2State: '',
      city: '',
      cityState: '',
      state: optionsState[0].value,
      stateIndex: '0',
      postalCode: '',
      postalCodeState: '',
      disabled: false,
      error: '',
      gender: optionsGender[0].value,
      genderIndex: '0',
      dateHired: '',
      dateOfBirth: '',
      npi: '',
      roles: [],
      notes: '',
      loading: true,
    };
  }

  Cancel() {
    this.props.history.push('/in/l/members#top');
  }

  formatSSN(text) {
    //do we have anything?
    //no
    if (!text) {
      return '';
    }

    var ssn = text.toString();

    ssn = ssn.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, '$1-$2-$3');

    return ssn;
  }

  phoneUpdated(phone) {
    this.setState({
      phoneMobile: phone,
      phoneMobileState: 'has-success',
    });
  }

  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);

      //this is our base to get the data
      var requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          SECRET: localStorage.getItem('SECRET'),
          PASSPHRASE: localStorage.getItem('PASSPHRASE'),
        },
      };

      var qs = queryString.parse(this.props.location.search);
      //do we have a group id?
      //yes
      if (qs['id']) {
        fetch(
          window.configuration.MemberURL + '?id=' + qs['id'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to edit the member',
                  disabled: true,
                  loading: false,
                });
              }

              // get error message from body or default to response status
              const error = response.status;
              return Promise.reject(error);
            }
            //did we get an error?
            //no
            else {
              var memberResponse = await response.json();

              //did we get a good one?
              //yes
              if (memberResponse.id) {
                let reg = /.+@.+\..+/;

                var tempRoles = !memberResponse.roles
                  ? []
                  : memberResponse.roles.map(function (role) {
                      return optionsRoles.filter((r) => r.value === role)[0];
                    });

                this.setState({
                  error: '',
                  loading: false,
                  disabled: false,
                  id: memberResponse.id,
                  dateOfBirth: memberResponse.dateOfBirth
                    ? moment(memberResponse.dateOfBirth).format('MM/DD/yyyy')
                    : '',
                  dateHired: memberResponse.dateHired
                    ? moment(memberResponse.dateHired).format('MM/DD/yyyy')
                    : '',
                  prefix: memberResponse.prefix,
                  prefixState: 'has-success',
                  firstName: memberResponse.firstName,
                  firstNameState: 'has-success',
                  middleName: memberResponse.middleName,
                  middleNameState: 'has-success',
                  lastName: memberResponse.lastName,
                  lastNameState: 'has-success',
                  suffix: memberResponse.suffix,
                  suffixState: 'has-success',
                  ssn: memberResponse.ssn,
                  npi: memberResponse.npi,
                  phoneMobile: memberResponse.phoneMobile,
                  phoneMobileState: 'has-success',
                  gender: memberResponse.gender,
                  genderIndex:
                    optionsGender.filter(
                      (o) => o.value === memberResponse.gender,
                    ).length > 0
                      ? optionsGender.filter(
                          (o) => o.value === memberResponse.gender,
                        )[0].index
                      : 0,
                  emailAddressHome: memberResponse.emailAddressHome,
                  emailAddressHomeState:
                    reg.test(memberResponse.emailAddressHome) === true
                      ? 'has-success'
                      : 'has-danger',
                  emailAddressWork: memberResponse.emailAddressWork,
                  emailAddressWorkState:
                    reg.test(memberResponse.emailAddressWork) === true
                      ? 'has-success'
                      : 'has-danger',
                  addressId: memberResponse.addressHome
                    ? memberResponse.addressHome.id
                    : '',
                  line1: memberResponse.addressHome
                    ? memberResponse.addressHome.line1
                    : '',
                  line2: memberResponse.addressHome
                    ? memberResponse.addressHome.line2
                    : '',
                  city: memberResponse.addressHome
                    ? memberResponse.addressHome.city
                    : '',
                  state: memberResponse.addressHome
                    ? memberResponse.addressHome.state
                    : '',
                  stateIndex: memberResponse.addressHome
                    ? optionsState.filter(
                        (o) => o.value === memberResponse.addressHome.state,
                      )[0].index
                    : 0,
                  postalCode: memberResponse.addressHome
                    ? memberResponse.addressHome.postalCode
                    : '',
                  roles: tempRoles,
                  notes: memberResponse.notes,
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: error,
              disabled: false,
              loading: false,
            });
          });
      }
      //do we have a group id?
      //no
      else {
        this.setState({ loading: false });
      }
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  //this submits the form
  submit(e, navigation) {
    //did we disable the button?
    //yes
    if (this.state.disabled) {
      return;
    }

    //do we have a first name?
    //no
    if (this.state.firstName.length === 0) {
      this.setState({
        error: 'Please enter their first name',
      });
      return;
    }
    //do we have a last name?
    //no
    else if (this.state.lastName.length === 0) {
      this.setState({
        error: 'Please enter their last name',
      });
      return;
    }
    //do we have a phoneMobile?
    //no
    else if (this.state.phoneMobile.length < 10) {
      this.setState({
        error: 'Please enter their phone number',
      });
      return;
    }
    //do we have an email address?
    //no
    else if (this.state.emailAddressWork) {
      let reg = /.+@.+\..+/;
      if (reg.test(this.state.emailAddressWork) === false) {
        this.setState({
          error: 'Please enter a valid work email address',
        });
        return;
      }
    }

    this.setState({
      disabled: true,
      error: '',
      loading: true,
    });

    // POST request using fetch with error handling
    var requestOptions = {
      method: this.state.id === '' ? 'POST' : 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
      body: JSON.stringify({
        //we set id because this is the member record
        id: this.state.id,
        prefix: this.state.prefix,
        firstName: this.state.firstName,
        middleName: this.state.firstName,
        lastName: this.state.lastName,
        suffix: this.state.suffix,
        dateOfBirth: this.state.dateOfBirth,
        ssn: this.state.ssn,
        npi: this.state.npi,
        gender: this.state.gender,
        dateHired: this.state.dateHired,
        addressHome: {
          id: this.state.addressId,
          line1: this.state.line1,
          line2: this.state.line2,
          city: this.state.city,
          state: this.state.state,
          postalCode: this.state.postalCode,
        },
        phoneMobile: this.state.phoneMobile,
        emailAddressHome: this.state.emailAddressHome,
        emailAddressWork: this.state.emailAddressWork,
        roles: this.state.roles.map(function (role) {
          return role.value;
        }),
        notes: this.state.notes,
      }),
    };

    fetch(window.configuration.MemberURL, requestOptions)
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          //did we get an unauthorized?
          //no
          else {
            //set the error
            this.setState({
              error: 'We were unable to add the member',
              disabled: false,
              loading: false,
            });
          }
          // get error message from body or default to response status
          const error = response.status;
          return Promise.reject(error);
        }
        //did we get an error?
        //no
        else {
          this.Cancel();
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          disabled: false,
          loading: false,
        });
      });
  }

  render() {
    //are we loading?
    //yes
    if (this.state.loading) {
      return (
        <Loader
          height="0"
          width="0"
          type="ThreeDots"
          color="#00BFFF"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            background: '#99CCFF',
            opacity: '0.6',
            zIndex: '998',
            height: '100%',
            width: '100%',
          }}
        />
      );
    }
    //are we loading?
    //no
    else {
      return (
        <>
          {this.state.loading ? (
            <Loader
              height="0"
              width="0"
              type="ThreeDots"
              color="#00BFFF"
              style={{
                position: 'fixed',
                top: '0',
                left: '0',
                background: '#99CCFF',
                opacity: '0.6',
                zIndex: '998',
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            ''
          )}
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col xs={12} md={7}>
                <Form>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        {this.state.id
                          ? 'Update a Staff Member'
                          : 'Add a Staff Member'}
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={'has-label ' + this.state.prefixState}
                          >
                            <Label>Prefix</Label>
                            <Input
                              type="text"
                              maxLength="10"
                              onChange={(e) =>
                                this.setState({
                                  prefix: e.target.value,
                                  prefixState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoFocus
                              autoCorrect="true"
                              value={this.state.prefix}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.firstNameState}
                          >
                            <Label>First Name *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="50"
                              onChange={(e) =>
                                this.setState({
                                  firstName: e.target.value,
                                  firstNameState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.firstName}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.middleNameState
                            }
                          >
                            <Label>Middle Name</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="100"
                              onChange={(e) =>
                                this.setState({
                                  middleName: e.target.value,
                                  middleNameState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-success',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.middleName}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.lastNameState}
                          >
                            <Label>Last Name *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="100"
                              onChange={(e) =>
                                this.setState({
                                  lastName: e.target.value,
                                  lastNameState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.lastName}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={'has-label ' + this.state.suffixState}
                          >
                            <Label>Suffix</Label>
                            <Input
                              type="text"
                              maxLength="20"
                              onChange={(e) =>
                                this.setState({
                                  suffix: e.target.value,
                                  suffixState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.suffix}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.dateOfBirthState
                            }
                          >
                            <Label>Date of Birth</Label>
                            <Input
                              type="text"
                              maxLength="10"
                              value={this.state.dateOfBirth}
                              onChange={(e) => {
                                this.setState({
                                  dateOfBirth: e.target.value,
                                  error: '',
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup className={'has-label'}>
                            <Label>Gender *</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsGender}
                              onChange={(item) => {
                                this.setState({
                                  genderIndex: item.index,
                                  gender: item.value,
                                });
                              }}
                              value={optionsGender[this.state.genderIndex]}
                              placeholder="Select a gender for this member"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label'}>
                            <Label>SSN *</Label>
                            <Input
                              type="text"
                              maxLength="11"
                              onChange={(e) => {
                                this.setState({
                                  ssn: e.target.value,
                                  ssnState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                });
                              }}
                              value={
                                this.state.ssn
                                  ? this.formatSSN(this.state.ssn)
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.ssnState}
                          >
                            <Label>NPI (Providers Only)</Label>
                            <Input
                              type="text"
                              maxLength="10"
                              onChange={(e) => {
                                this.setState({
                                  npi: e.target.value,
                                  error: '',
                                });
                              }}
                              value={this.state.npi ? this.state.npi : ''}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={'has-label ' + this.state.dateHiredState}
                          >
                            <Label>Date Hired</Label>
                            <Input
                              type="text"
                              maxLength="10"
                              value={this.state.dateHired}
                              onChange={(e) => {
                                this.setState({
                                  dateHired: e.target.value,
                                  error: '',
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}>
                          <FormGroup className={'has-label'}>
                            <h6>Contact Information</h6>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            className={'has-label ' + this.state.line1State}
                          >
                            <Label>Address *</Label>
                            <Input
                              type="text"
                              maxLength="300"
                              onChange={(e) =>
                                this.setState({
                                  line1: e.target.value,
                                  line1State:
                                    e.target.value.length > 4
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.line1}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            className={'has-label ' + this.state.line2State}
                          >
                            <Label>Suite, apartment, etc.</Label>
                            <Input
                              type="text"
                              maxLength="300"
                              onChange={(e) =>
                                this.setState({
                                  line2: e.target.value,
                                  line2State:
                                    e.target.value.length > 4
                                      ? 'has-success'
                                      : 'has-success',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.line2}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.cityState}
                          >
                            <Label>City *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="300"
                              onChange={(e) =>
                                this.setState({
                                  city: e.target.value,
                                  cityState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.city}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label'}>
                            <Label>State *</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsState}
                              onChange={(item) => {
                                this.setState({
                                  stateIndex: item.index,
                                  state: item.value,
                                });
                              }}
                              value={optionsState[this.state.stateIndex]}
                              placeholder="Select a state for this member"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.postalCodeState
                            }
                          >
                            <Label>Zip Code *</Label>
                            <Input
                              type="text"
                              maxLength="10"
                              onChange={(e) =>
                                this.setState({
                                  postalCode: e.target.value,
                                  postalCodeState:
                                    e.target.value.length > 4
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.postalCode}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.phoneMobileState
                            }
                          >
                            <Label>Phone Number *</Label>
                            <PhoneInput
                              inputProps={{
                                name: 'phone',
                                required: true,
                              }}
                              country={'us'}
                              regions={'north-america'}
                              placeholder="(512)-555-1212"
                              disableDropdown={true}
                              disableCountryCode={true}
                              countryCodeEditable={true}
                              inputStyle={{
                                fontSize: 16,
                                height: 40,
                                paddingLeft: 50,
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                width: '100%',
                              }}
                              value={this.state.phoneMobile}
                              onChange={(text) => this.phoneUpdated(text)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.emailAddressHomeState
                            }
                          >
                            <Label>Home Email Address</Label>
                            <Input
                              type="text"
                              maxLength="100"
                              onChange={(e) =>
                                this.setState({
                                  emailAddressHome: e.target.value,
                                  emailAddressHomeState:
                                    e.target.value.length >= 5
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.emailAddressHome}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.emailAddressWorkState
                            }
                          >
                            <Label>Work Email Address</Label>
                            <Input
                              type="text"
                              maxLength="100"
                              onChange={(e) =>
                                this.setState({
                                  emailAddressWork: e.target.value,
                                  emailAddressWorkState:
                                    e.target.value.length >= 5
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.emailAddressWork}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup className={'has-label '}>
                            <Label>Select the member's role(s)</Label>
                            <Multiselect
                              className="react-select"
                              classNamePrefix="react-select"
                              displayValue="label"
                              options={optionsRoles}
                              selectedValues={this.state.roles}
                              onSelect={(selectedList) => {
                                //set the data
                                this.setState({ roles: selectedList });
                              }}
                              onRemove={(selectedList) => {
                                //set the data
                                this.setState({ roles: selectedList });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            className={'has-label ' + this.state.notesState}
                          >
                            <Label>Notes</Label>
                            <Input
                              type="textarea"
                              maxLength="5000"
                              style={{
                                border: '1px solid #dadada',
                                minHeight: 200,
                              }}
                              onChange={(e) =>
                                this.setState({
                                  notes: e.target.value,
                                  notesState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.notes}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            style={{
                              textAlign: 'end',
                              display: this.state.error ? '' : 'none',
                            }}
                          >
                            <Label
                              style={{
                                fontSize: 14,
                                color: 'tomato',
                                paddingRight: 15,
                                display: this.state.error ? '' : 'none',
                              }}
                            >
                              {this.state.error}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                      <Button color="success" onClick={(e) => this.submit(e)}>
                        {this.state.disabled
                          ? 'One moment...'
                          : this.state.id
                          ? 'Update member'
                          : 'Add member'}
                      </Button>
                      {/* spacer between buttons */ ' '}
                      <Button
                        style={{ marginLeft: 10 }}
                        color="secondary"
                        onClick={() => this.Cancel()}
                      >
                        Cancel
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default MemberForm;
