/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React, { Component } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import { Redirect, Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import ReactGA from 'react-ga';

class RXNTPayerList extends Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.state = {
      data: [],
      loading: true,
      filtered: [],
      disabled: false,
    };
  }

  //we get the data from the system on this call
  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.loadData();
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  setError(error, disabled, loading) {
    this.setState({
      error,
      disabled,
      loading,
    });
  }

  loadData() {
    // GET request using fetch with error handling
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
    };

    fetch(window.configuration.RXNTPayerURL, requestOptions)
      .then(async (response) => {
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          return null;
        }
        //did we get an error?
        //no
        else {
          var rxnts = await response.json();

          //create our rows
          var rxntObjects = rxnts.map(function (rxnt) {
            return {
              id: rxnt.id,
              name: rxnt.payer.name,
              city: rxnt.payer.zipCodeCity.city,
              state: rxnt.payer.state.code,
              success:
                rxnt.responses &&
                rxnt.responses.length > 0 &&
                rxnt.responses[0].externalReferencePayerId
                  ? 'Yes'
                  : 'No',
              addedByName: rxnt.addedByName ? rxnt.addedByName : 'unknown',
              dateAdded: new Date(rxnt.dateAdded).toLocaleDateString(),
              updatedByName: rxnt.updatedByName
                ? rxnt.updatedByName
                : 'unknown',
              dateUpdated: new Date(rxnt.dateUpdated).toLocaleDateString(),
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  <ReactTooltip />
                  {/* spacer between buttons */ ' '}
                  <Link to={'/in/f/rxntpayer?id=' + rxnt.id}>
                    <Button
                      data-tip="view"
                      className="btn-icon btn-round"
                      color={'success'}
                      size="sm"
                    >
                      <i className="fa fa-eye" />
                    </Button>
                  </Link>
                </div>
              ),
            };
          });

          //grab our response
          this.setState({
            data: rxntObjects,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          loading: false,
        });
        return null;
      });
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  render() {
    //are we redirecting?
    //yes
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    //are we redirecting?
    //no
    else {
      //are we loading?
      //yes
      if (this.state.loading === true) {
        return (
          <>
            <Loader
              height="0"
              width="0"
              type="ThreeDots"
              color="#00BFFF"
              style={{
                position: 'fixed',
                top: '0',
                left: '0',
                background: '#99CCFF',
                opacity: '0.6',
                zIndex: '998',
                height: '100%',
                width: '100%',
              }}
            />
          </>
        );
      }
      return (
        <>
          {this.state.loading ? (
            <Loader
              height="0"
              width="0"
              type="ThreeDots"
              color="#00BFFF"
              style={{
                position: 'fixed',
                top: '0',
                left: '0',
                background: '#99CCFF',
                opacity: '0.6',
                zIndex: '998',
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            ''
          )}
          <PanelHeader size="sm" />
          <div className="content" id="top">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">RXNT Payer Request List</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      data={this.state.data}
                      filterable={true}
                      filtered={this.state.filtered}
                      onFilteredChange={(filtered, column, value) => {
                        this.onFilteredChangeCustom(
                          value,
                          column.id || column.accessor,
                        );
                      }}
                      defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        if (typeof filter.value === 'object') {
                          return row[id] !== undefined
                            ? filter.value.indexOf(row[id]) > -1
                            : true;
                        } else {
                          return row[id] !== undefined
                            ? String(row[id].toLowerCase()).indexOf(
                                filter.value.toLowerCase(),
                              ) > -1
                            : true;
                        }
                      }}
                      columns={[
                        {
                          Header: 'Name',
                          accessor: 'name',
                          width: 350,
                        },
                        {
                          Header: 'City',
                          accessor: 'city',
                          width: 230,
                        },
                        {
                          Header: 'State',
                          accessor: 'state',
                          width: 100,
                        },
                        {
                          Header: 'Added',
                          accessor: 'dateAdded',
                          width: 100,
                          //sort the dates
                          sortMethod: (a, b) => {
                            a = new Date(a).getTime();
                            b = new Date(b).getTime();
                            return b > a ? 1 : -1;
                          },
                        },
                        {
                          Header: 'Added By',
                          accessor: 'addedByName',
                          width: 200,
                        },
                        {
                          Header: 'Success',
                          accessor: 'success',
                          width: 100,
                        },
                        {
                          Header: '',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={this.state.data.length}
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default RXNTPayerList;
