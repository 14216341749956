/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Input,
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import Switch from '@material-ui/core/Switch';
import Select from 'react-select';
import moment from 'moment';

const queryString = require('query-string');

//set up our insulin options
var optionsInsulin = [
  { index: 0, value: '0', label: 'None' },
  { index: 1, value: 'basal', label: 'Basal' },
  { index: 2, value: 'bolus', label: 'Bolus' },
  { index: 3, value: 'mixed', label: 'Mixed' },
];

//set up our add units options
var optionsAddUnits = [
  { index: 0, value: '1', label: '1 unit bolus' },
  { index: 1, value: '2', label: '2 units bolus' },
  { index: 2, value: '3', label: '3 units bolus' },
];

var wentOver = '';

class InsulinPrescriptionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recalculateNumbers: false,
      id: '',

      //the insulins we may need
      basalInsulinId: '',
      basalInsulinName: '',
      bolusInsulinId: '',
      bolusInsulinName: '',
      mixedInsulinId: '',
      mixedInsulinName: '',

      insulinData: [],
      basalInsulinIndex: -1,
      bolusInsulinIndex: -1,
      mixedInsulinIndex: -1,

      glucoseLevelHypo: null,
      glucoseLevelHypoState: '',
      glucoseLevelGoal: null,
      glucoseLevelGoalState: '',
      glucoseLevelHigh: null,
      glucoseLevelHighState: '',
      glucoseLevelDanger: null,
      glucoseLevelDangerState: '',
      glucoseLevelImpossible: null,
      glucoseLevelImpossibleState: '',

      //we force it to start tomorrow
      dateStart: moment(new Date()).format('MM/DD/yyyy'),
      patientName: '',
      testPatient: false,
      correctionMGEvery: '50',
      correctionMGBaseline: '100',
      correctionAddUnits: '1',
      correctionAddUnitsIndex: 0,

      morningTrackGlucose: false,
      morningActive: false,
      morningCorrection: false,
      morningError: '',
      morningInsulinId: '',
      morningInsulinName: '',
      morningInsulinType: '',
      morningDose: '',
      morning_0: 'hypo',
      morning_0Level: '',
      morning_1: '',
      morning_1Level: '',
      morning_2: '',
      morning_2Level: '',
      morning_3: '',
      morning_3Level: '',
      morning_4: '',
      morning_4Level: '',
      morning_5: '',
      morning_5Level: '',
      morning_6: '',
      morning_6Level: '',
      morningTimeOfDayStart: '8:00 AM',
      morningTimeOfDayEnd: '9:00 AM',

      breakfastTrackGlucose: false,
      breakfastActive: false,
      breakfastCorrection: false,
      breakfastError: '',
      breakfastInsulinId: '',
      breakfastInsulinName: '',
      breakfastInsulinType: '',
      breakfastDose: '',
      breakfast_0: 'hypo',
      breakfast_0Level: '',
      breakfast_1: '',
      breakfast_1Level: '',
      breakfast_2: '',
      breakfast_2Level: '',
      breakfast_3: '',
      breakfast_3Level: '',
      breakfast_4: '',
      breakfast_4Level: '',
      breakfast_5: '',
      breakfast_5Level: '',
      breakfast_6: '',
      breakfast_6Level: '',
      breakfastTimeOfDayStart: '9:00 AM',
      breakfastTimeOfDayEnd: '10:00 AM',

      lunchTrackGlucose: false,
      lunchActive: false,
      lunchCorrection: false,
      lunchError: '',
      lunchInsulinId: '',
      lunchInsulinName: '',
      lunchInsulinType: '',
      lunchDose: '',
      lunch_0: 'hypo',
      lunch_0Level: '',
      lunch_1: '',
      lunch_1Level: '',
      lunch_2: '',
      lunch_2Level: '',
      lunch_3: '',
      lunch_3Level: '',
      lunch_4: '',
      lunch_4Level: '',
      lunch_5: '',
      lunch_5Level: '',
      lunch_6: '',
      lunch_6Level: '',
      lunchTimeOfDayStart: '12:00 PM',
      lunchTimeOfDayEnd: '1:00 PM',

      dinnerTrackGlucose: false,
      dinnerActive: false,
      dinnerCorrection: false,
      dinnerError: '',
      dinnerInsulinId: '',
      dinnerInsulinName: '',
      dinnerInsulinType: '',
      dinnerDose: '',
      dinner_0: 'hypo',
      dinner_0Level: '',
      dinner_1: '',
      dinner_1Level: '',
      dinner_2: '',
      dinner_2Level: '',
      dinner_3: '',
      dinner_3Level: '',
      dinner_4: '',
      dinner_4Level: '',
      dinner_5: '',
      dinner_5Level: '',
      dinner_6: '',
      dinner_6Level: '',
      dinnerTimeOfDayStart: '5:30 PM',
      dinnerTimeOfDayEnd: '6:30 PM',

      nighttimeTrackGlucose: false,
      nighttimeActive: false,
      nighttimeCorrection: false,
      nighttimeError: '',
      nighttimeInsulinId: '',
      nighttimeInsulinName: '',
      nighttimeInsulinType: '',
      nighttimeDose: '',
      nighttime_0: 'hypo',
      nighttime_0Level: '',
      nighttime_1: '',
      nighttime_1Level: '',
      nighttime_2: '',
      nighttime_2Level: '',
      nighttime_3: '',
      nighttime_3Level: '',
      nighttime_4: '',
      nighttime_4Level: '',
      nighttime_5: '',
      nighttime_5Level: '',
      nighttime_6: '',
      nighttime_6Level: '',
      nighttimeTimeOfDayStart: '9:00 PM',
      nighttimeTimeOfDayEnd: '10:00 PM',

      nameState: '',
      notes: '',
      notesState: '',
      disabled: false,
      error: '',
      bgerror: '',
      loading: true,
    };
  }

  Cancel() {
    this.props.history.push('/in/l/patients#top');
  }

  componentDidUpdate() {
    //are we supposed to recaculate?
    //yes
    if (this.state.recalculateNumbers === true) {
      this.recalculateNumbers();
    }
  }

  recalculateNumbers() {
    //do we have all that we need?
    //yes
    if (
      this.state.correctionMGBaseline &&
      this.state.correctionAddUnits &&
      this.state.correctionMGEvery
    ) {
      var morningWindowStart = moment(
        '1/1/2000 ' + this.state.morningTimeOfDayStart,
      );
      var morningWindowEnd = moment(
        '1/1/2000 ' + this.state.morningTimeOfDayEnd,
      );
      var morningError =
        morningWindowEnd < morningWindowStart ? 'Window is backwards' : '';
      var breakfastWindowStart = moment(
        '1/1/2000 ' + this.state.breakfastTimeOfDayStart,
      );
      var breakfastWindowEnd = moment(
        '1/1/2000 ' + this.state.breakfastTimeOfDayEnd,
      );
      var breakfastError =
        breakfastWindowEnd < breakfastWindowStart ? 'Window is backwards' : '';
      var lunchWindowStart = moment(
        '1/1/2000 ' + this.state.lunchTimeOfDayStart,
      );
      var lunchWindowEnd = moment('1/1/2000 ' + this.state.lunchTimeOfDayEnd);
      var lunchError =
        lunchWindowEnd < lunchWindowStart ? 'Window is backwards' : '';
      var dinnerWindowStart = moment(
        '1/1/2000 ' + this.state.dinnerTimeOfDayStart,
      );
      var dinnerWindowEnd = moment('1/1/2000 ' + this.state.dinnerTimeOfDayEnd);
      var dinnerError =
        dinnerWindowEnd < dinnerWindowStart ? 'Window is backwards' : '';
      var nighttimeWindowStart = moment(
        '1/1/2000 ' + this.state.nighttimeTimeOfDayStart,
      );
      var nighttimeWindowEnd = moment(
        '1/1/2000 ' + this.state.nighttimeTimeOfDayEnd,
      );
      var nighttimeError =
        nighttimeWindowEnd < nighttimeWindowStart ? 'Window is backwards' : '';

      var baseline = parseInt(this.state.correctionMGBaseline);
      var every = parseInt(this.state.correctionMGEvery);
      var addUnits = parseInt(this.state.correctionAddUnits);
      var stop = false;

      var units = this.state.morningDose ? parseInt(this.state.morningDose) : 0;
      var morning_0 = 'hypo';
      var morning_0Level = this.state.glucoseLevelHypo;
      var morning_1 = units;
      var morning_1Level =
        every + baseline >= this.state.glucoseLevelImpossible
          ? this.state.glucoseLevelImpossible
          : every + baseline;
      wentOver =
        morning_1Level === this.state.glucoseLevelImpossible ? '1' : '';

      //did we go over on 1?
      //yes
      if (wentOver && wentOver === '1') {
        morning_1Level = parseInt(morning_0Level) + 1;
        stop = true;
      }

      var morning_2 = units;
      var morning_2Level = stop
        ? this.state.glucoseLevelImpossible
        : morning_1Level + every >= this.state.glucoseLevelImpossible
        ? wentOver && wentOver === '1'
          ? morning_1Level + 1
          : this.state.glucoseLevelImpossible
        : morning_1Level + every;
      wentOver = wentOver
        ? wentOver
        : morning_2Level === this.state.glucoseLevelImpossible
        ? '2'
        : '';

      //did we go over on 2?
      //yes
      if (wentOver && wentOver === '2') {
        morning_2Level = parseInt(morning_1Level) + 1;
        stop = true;
      }

      var morning_3 = units;
      var morning_3Level = stop
        ? this.state.glucoseLevelImpossible
        : morning_2Level + every >= this.state.glucoseLevelImpossible
        ? wentOver && wentOver === '2'
          ? morning_2Level + 1
          : this.state.glucoseLevelImpossible
        : morning_2Level + every;
      wentOver = wentOver
        ? wentOver
        : morning_3Level === this.state.glucoseLevelImpossible
        ? '3'
        : '';

      //did we go over on 3?
      //yes
      if (wentOver && wentOver === '3') {
        morning_3Level = parseInt(morning_2Level) + 1;
        stop = true;
      }

      var morning_4 = units;
      var morning_4Level = stop
        ? this.state.glucoseLevelImpossible
        : morning_3Level + every >= this.state.glucoseLevelImpossible
        ? wentOver && wentOver === '3'
          ? morning_3Level + 1
          : this.state.glucoseLevelImpossible
        : morning_3Level + every;
      wentOver = wentOver
        ? wentOver
        : morning_4Level === this.state.glucoseLevelImpossible
        ? '4'
        : '';

      //did we go over on 4?
      //yes
      if (wentOver && wentOver === '4') {
        morning_4Level = parseInt(morning_3Level) + 1;
        stop = true;
      }

      var morning_5 = units;
      var morning_5Level = stop
        ? this.state.glucoseLevelImpossible
        : morning_4Level + every >= this.state.glucoseLevelImpossible
        ? wentOver && wentOver === '4'
          ? morning_4Level + 1
          : this.state.glucoseLevelImpossible
        : morning_4Level + every;
      wentOver = wentOver
        ? wentOver
        : morning_5Level === this.state.glucoseLevelImpossible
        ? '5'
        : '';

      //did we go over on 5?
      //yes
      if (wentOver && wentOver === '5') {
        morning_5Level = parseInt(morning_4Level) + 1;
        stop = true;
      }

      var morning_6 = units;
      var morning_6Level = stop
        ? this.state.glucoseLevelImpossible
        : morning_5Level + 1 >= this.state.glucoseLevelImpossible
        ? this.state.glucoseLevelImpossible
        : morning_5Level + 1;

      switch (wentOver) {
        case 0:
          morning_1Level = morning_0Level + 1;
          morning_2Level = this.state.glucoseLevelImpossible;
          morning_3Level = this.state.glucoseLevelImpossible;
          morning_4Level = this.state.glucoseLevelImpossible;
          morning_5Level = this.state.glucoseLevelImpossible;
          morning_6Level = this.state.glucoseLevelImpossible;
          break;
        case 1:
          morning_2Level = morning_1Level + 1;
          morning_3Level = this.state.glucoseLevelImpossible;
          morning_4Level = this.state.glucoseLevelImpossible;
          morning_5Level = this.state.glucoseLevelImpossible;
          morning_6Level = this.state.glucoseLevelImpossible;
          break;
        case 2:
          morning_3Level = morning_2Level + 1;
          morning_4Level = this.state.glucoseLevelImpossible;
          morning_5Level = this.state.glucoseLevelImpossible;
          morning_6Level = this.state.glucoseLevelImpossible;
          break;
        case 3:
          morning_4Level = morning_3Level + 1;
          morning_5Level = this.state.glucoseLevelImpossible;
          morning_6Level = this.state.glucoseLevelImpossible;
          break;
        case 4:
          morning_5Level = morning_4Level + 1;
          morning_6Level = this.state.glucoseLevelImpossible;
          break;
        case 5:
          morning_6Level = morning_5Level + 1;
          break;
        default:
          break;
      }

      //are we active for morning?
      //no
      if (
        this.state.morningActive === false ||
        this.state.morningInsulinId === '0'
      ) {
        morning_0 = '';
        morning_1 = '';
        morning_2 = '';
        morning_3 = '';
        morning_4 = '';
        morning_5 = '';
        morning_6 = '';
      }
      //are we active for morning?
      //yes
      else if (
        this.state.morningCorrection === true &&
        this.state.morningTrackGlucose === true
      ) {
        morning_0 = 'hypo';
        //this is our base
        morning_1 = units;
        morning_2 = morning_1 + addUnits;
        morning_3 = morning_2 + addUnits;
        morning_4 = morning_3 + addUnits;
        morning_5 = morning_4 + addUnits;
        morning_6 = morning_5 + addUnits;
      }

      units = this.state.breakfastDose ? parseInt(this.state.breakfastDose) : 0;
      var breakfast_0 = 'hypo';
      var breakfast_0Level = morning_0Level;
      var breakfast_1 = units;
      var breakfast_1Level = morning_1Level;
      var breakfast_2 = units;
      var breakfast_2Level = morning_2Level;
      var breakfast_3 = units;
      var breakfast_3Level = morning_3Level;
      var breakfast_4 = units;
      var breakfast_4Level = morning_4Level;
      var breakfast_5 = units;
      var breakfast_5Level = morning_5Level;
      var breakfast_6 = units;
      var breakfast_6Level = morning_6Level;

      //are we active for breakfast?
      //no
      if (
        this.state.breakfastActive === false ||
        this.state.breakfastInsulinId === ''
      ) {
        breakfast_0 = '';
        breakfast_1 = '';
        breakfast_2 = '';
        breakfast_3 = '';
        breakfast_4 = '';
        breakfast_5 = '';
        breakfast_6 = '';
      }
      //are we active for breakfast?
      //yes
      else if (
        this.state.breakfastCorrection === true &&
        this.state.breakfastTrackGlucose === true
      ) {
        breakfast_0 = 'hypo';
        //this is our base
        breakfast_1 = units;
        breakfast_2 = breakfast_1 + addUnits;
        breakfast_3 = breakfast_2 + addUnits;
        breakfast_4 = breakfast_3 + addUnits;
        breakfast_5 = breakfast_4 + addUnits;
        breakfast_6 = breakfast_5 + addUnits;
      }

      units = this.state.lunchDose ? parseInt(this.state.lunchDose) : 0;
      var lunch_0 = 'hypo';
      var lunch_0Level = morning_0Level;
      var lunch_1 = units;
      var lunch_1Level = morning_1Level;
      var lunch_2 = units;
      var lunch_2Level = morning_2Level;
      var lunch_3 = units;
      var lunch_3Level = morning_3Level;
      var lunch_4 = units;
      var lunch_4Level = morning_4Level;
      var lunch_5 = units;
      var lunch_5Level = morning_5Level;
      var lunch_6 = units;
      var lunch_6Level = morning_6Level;

      //are we active for lunch?
      //no
      if (
        this.state.lunchActive === false ||
        this.state.lunchInsulinId === ''
      ) {
        lunch_0 = '';
        lunch_1 = '';
        lunch_2 = '';
        lunch_3 = '';
        lunch_4 = '';
        lunch_5 = '';
        lunch_6 = '';
      }
      //are we active for lunch?
      //yes
      else if (
        this.state.lunchCorrection === true &&
        this.state.lunchTrackGlucose === true
      ) {
        lunch_0 = 'hypo';
        //this is our base
        lunch_1 = units;
        lunch_2 = lunch_1 + addUnits;
        lunch_3 = lunch_2 + addUnits;
        lunch_4 = lunch_3 + addUnits;
        lunch_5 = lunch_4 + addUnits;
        lunch_6 = lunch_5 + addUnits;
      }

      units = this.state.dinnerDose ? parseInt(this.state.dinnerDose) : 0;
      var dinner_0 = 'hypo';
      var dinner_0Level = morning_0Level;
      var dinner_1 = units;
      var dinner_1Level = morning_1Level;
      var dinner_2 = units;
      var dinner_2Level = morning_2Level;
      var dinner_3 = units;
      var dinner_3Level = morning_3Level;
      var dinner_4 = units;
      var dinner_4Level = morning_4Level;
      var dinner_5 = units;
      var dinner_5Level = morning_5Level;
      var dinner_6 = units;
      var dinner_6Level = morning_6Level;

      //are we active for dinner?
      //no
      if (
        this.state.dinnerActive === false ||
        this.state.dinnerInsulinId === ''
      ) {
        dinner_0 = '';
        dinner_1 = '';
        dinner_2 = '';
        dinner_3 = '';
        dinner_4 = '';
        dinner_5 = '';
        dinner_6 = '';
      }
      //are we active for dinner?
      //yes
      else if (
        this.state.dinnerCorrection === true &&
        this.state.dinnerTrackGlucose === true
      ) {
        dinner_0 = 'hypo';
        //this is our base
        dinner_1 = units;
        dinner_2 = dinner_1 + addUnits;
        dinner_3 = dinner_2 + addUnits;
        dinner_4 = dinner_3 + addUnits;
        dinner_5 = dinner_4 + addUnits;
        dinner_6 = dinner_5 + addUnits;
      }

      units = this.state.nighttimeDose ? parseInt(this.state.nighttimeDose) : 0;
      var nighttime_0 = 'hypo';
      var nighttime_0Level = morning_0Level;
      var nighttime_1 = units;
      var nighttime_1Level = morning_1Level;
      var nighttime_2 = units;
      var nighttime_2Level = morning_2Level;
      var nighttime_3 = units;
      var nighttime_3Level = morning_3Level;
      var nighttime_4 = units;
      var nighttime_4Level = morning_4Level;
      var nighttime_5 = units;
      var nighttime_5Level = morning_5Level;
      var nighttime_6 = units;
      var nighttime_6Level = morning_6Level;

      //are we active for nighttime?
      //no
      if (
        this.state.nighttimeActive === false ||
        this.state.nighttimeInsulinId === ''
      ) {
        nighttime_0 = '';
        nighttime_1 = '';
        nighttime_2 = '';
        nighttime_3 = '';
        nighttime_4 = '';
        nighttime_5 = '';
        nighttime_6 = '';
      }
      //are we active for nighttime?
      //yes
      else if (
        this.state.nighttimeCorrection === true &&
        this.state.nighttimeTrackGlucose === true
      ) {
        nighttime_0 = 'hypo';
        //this is our base
        nighttime_1 = units;
        nighttime_2 = nighttime_1 + addUnits;
        nighttime_3 = nighttime_2 + addUnits;
        nighttime_4 = nighttime_3 + addUnits;
        nighttime_5 = nighttime_4 + addUnits;
        nighttime_6 = nighttime_5 + addUnits;
      }

      this.setState({
        recalculateNumbers: false,
        morning_0,
        morning_0Level,
        morning_1,
        morning_1Level,
        morning_2,
        morning_2Level,
        morning_3,
        morning_3Level,
        morning_4,
        morning_4Level,
        morning_5,
        morning_5Level,
        morning_6,
        morning_6Level,
        morningError,
        breakfast_0,
        breakfast_0Level,
        breakfast_1,
        breakfast_1Level,
        breakfast_2,
        breakfast_2Level,
        breakfast_3,
        breakfast_3Level,
        breakfast_4,
        breakfast_4Level,
        breakfast_5,
        breakfast_5Level,
        breakfast_6,
        breakfast_6Level,
        breakfastError,
        lunch_0,
        lunch_0Level,
        lunch_1,
        lunch_1Level,
        lunch_2,
        lunch_2Level,
        lunch_3,
        lunch_3Level,
        lunch_4,
        lunch_4Level,
        lunch_5,
        lunch_5Level,
        lunch_6,
        lunch_6Level,
        lunchError,
        dinner_0,
        dinner_0Level,
        dinner_1,
        dinner_1Level,
        dinner_2,
        dinner_2Level,
        dinner_3,
        dinner_3Level,
        dinner_4,
        dinner_4Level,
        dinner_5,
        dinner_5Level,
        dinner_6,
        dinner_6Level,
        dinnerError,
        nighttime_0,
        nighttime_0Level,
        nighttime_1,
        nighttime_1Level,
        nighttime_2,
        nighttime_2Level,
        nighttime_3,
        nighttime_3Level,
        nighttime_4,
        nighttime_4Level,
        nighttime_5,
        nighttime_5Level,
        nighttime_6,
        nighttime_6Level,
        nighttimeError,
      });
    }
  }

  checkValues(h, g, hl, d, i) {
    h = parseInt(h);
    g = parseInt(g);
    hl = parseInt(hl);
    d = parseInt(d);
    i = parseInt(i);

    return isNaN(h) === true
      ? 'Enter a Hypo level'
      : isNaN(g) === true
      ? 'Enter a Goal level'
      : isNaN(hl) === true
      ? 'Enter a High level'
      : isNaN(d) === true
      ? 'Enter a Danger level'
      : isNaN(i) === true
      ? 'Enter an Impossible level'
      : //we have a base level of 40 - hence why it is hardcoded
      isNaN(h) === false && h < 40
      ? 'Hypo must be at least 40'
      : isNaN(h) === false && isNaN(g) === false && h >= g
      ? 'Hypo must be lower than Goal'
      : isNaN(g) === false && isNaN(hl) === false && g >= hl
      ? 'Goal must be lower than High Level'
      : isNaN(hl) === false && isNaN(d) === false && hl >= d
      ? 'High level must be lower than Danger Level'
      : isNaN(d) === false && isNaN(i) === false && d >= i
      ? 'Danger level must be lower than Impossible Level'
      : '';
  }

  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      var qs = queryString.parse(this.props.location.search);

      //this is our base to get the data
      var requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          SECRET: localStorage.getItem('SECRET'),
          PASSPHRASE: localStorage.getItem('PASSPHRASE'),
        },
      };

      fetch(window.configuration.InsulinURL, requestOptions)
        .then(async (response) => {
          // check for error response
          //did we get an error?
          //yes
          if (!response.ok) {
            //did we get an unauthorized?
            //yes
            if (response.status === 401) {
              this.props.history.push('/a/logout');
            }

            return;
          }
          //did we get an error?
          //no
          else {
            var insulinsResponse = await response.json();

            var index = 2;

            //create our rows
            var insulinObjects = insulinsResponse.map(function (insulin) {
              index++;

              return {
                index: index,
                value: insulin.id,
                label: insulin.tradeName
                  ? insulin.tradeName
                  : insulin.genericName,
                id: insulin.id,
                tradeName: insulin.tradeName,
                genericName: insulin.genericName,
                insulinType: insulin.insulinType,
              };
            });

            //add our none's to it
            insulinObjects.splice(0, 0, {
              index: 2,
              value: '0',
              label: 'None',
              id: '',
              tradeName: 'Basal',
              genericName: '',
              insulinType: 'Basal',
            });

            insulinObjects.splice(0, 0, {
              index: 1,
              value: '0',
              label: 'None',
              id: '',
              tradeName: 'Bolus',
              genericName: '',
              insulinType: 'Bolus',
            });

            insulinObjects.splice(0, 0, {
              index: 0,
              value: '0',
              label: 'None',
              id: '',
              tradeName: 'Mixed',
              genericName: '',
              insulinType: 'Mixed',
            });

            this.setState({
              insulinData: insulinObjects,
              basalInsulinIndex: this.state.basalInsulinId
                ? insulinObjects.filter(
                    (o) => o.value === this.state.basalInsulinId,
                  )[0].index
                : -1,
              bolusInsulinIndex: this.state.bolusInsulinId
                ? insulinObjects.filter(
                    (o) => o.value === this.state.bolusInsulinId,
                  )[0].index
                : -1,
              mixedInsulinIndex: this.state.mixedInsulinId
                ? insulinObjects.filter(
                    (o) => o.value === this.state.mixedInsulinId,
                  )[0].index
                : -1,
              testPatient: qs['testPatient'] === 'true',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //do we have a group id?
      //yes
      if (qs['id']) {
        //get the group from the server
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            SECRET: localStorage.getItem('SECRET'),
            PASSPHRASE: localStorage.getItem('PASSPHRASE'),
          },
        };

        fetch(
          window.configuration.InsulinPrescriptionURL + '?id=' + qs['id'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to edit the prescription',
                  disabled: true,
                  loading: false,
                });
              }
              return;
            }
            //did we get an error?
            //no
            else {
              var prescriptionResponse = await response.json();

              //did we get a good one?
              //yes
              if (prescriptionResponse.id) {
                this.setState({
                  error: '',
                  disabled: false,
                  id: prescriptionResponse.id,

                  morningTrackGlucose: prescriptionResponse.morningTrackGlucose,
                  morningActive: prescriptionResponse.morningActive,
                  //we can only enable bolus
                  morningCorrection:
                    prescriptionResponse.morningInsulinType === 'bolus'
                      ? prescriptionResponse.morningCorrection
                      : false,
                  morningInsulinId: prescriptionResponse.morningInsulinId,
                  morningInsulinName: prescriptionResponse.morningInsulinName,
                  morningInsulinType: prescriptionResponse.morningInsulinType,
                  morningDose: prescriptionResponse.morningDose,
                  morning_0: prescriptionResponse.morningCorrectionPlan
                    .glucoseLevel0
                    ? prescriptionResponse.morningCorrectionPlan.glucoseLevel0
                    : 'hypo',
                  morning_0Level:
                    prescriptionResponse.morningCorrectionPlan.glucose0,
                  morning_1:
                    prescriptionResponse.morningCorrectionPlan.glucoseLevel1,
                  morning_1Level:
                    prescriptionResponse.morningCorrectionPlan.glucose1,
                  morning_2:
                    prescriptionResponse.morningCorrectionPlan.glucoseLevel2,
                  morning_2Level:
                    prescriptionResponse.morningCorrectionPlan.glucose2,
                  morning_3:
                    prescriptionResponse.morningCorrectionPlan.glucoseLevel3,
                  morning_3Level:
                    prescriptionResponse.morningCorrectionPlan.glucose3,
                  morning_4:
                    prescriptionResponse.morningCorrectionPlan.glucoseLevel4,
                  morning_4Level:
                    prescriptionResponse.morningCorrectionPlan.glucose4,
                  morning_5:
                    prescriptionResponse.morningCorrectionPlan.glucoseLevel5,
                  morning_5Level:
                    prescriptionResponse.morningCorrectionPlan.glucose5,
                  morning_6:
                    prescriptionResponse.morningCorrectionPlan.glucoseLevel6,
                  morning_6Level:
                    prescriptionResponse.morningCorrectionPlan.glucose6,
                  morningTimeOfDayStart: prescriptionResponse
                    .morningCorrectionPlan.timeOfDayStart
                    ? moment(
                        prescriptionResponse.morningCorrectionPlan
                          .timeOfDayStart,
                      ).format('h:mm A')
                    : this.state.morningTimeOfDayStart,
                  morningTimeOfDayEnd: prescriptionResponse
                    .morningCorrectionPlan.timeOfDayEnd
                    ? moment(
                        prescriptionResponse.morningCorrectionPlan.timeOfDayEnd,
                      ).format('h:mm A')
                    : this.state.morningTimeOfDayEnd,

                  breakfastTrackGlucose:
                    prescriptionResponse.breakfastTrackGlucose,
                  breakfastActive: prescriptionResponse.breakfastActive,
                  //we can only enable bolus
                  breakfastCorrection:
                    prescriptionResponse.breakfastInsulinType === 'bolus'
                      ? prescriptionResponse.breakfastCorrection
                      : false,
                  breakfastInsulinId: prescriptionResponse.breakfastInsulinId,
                  breakfastInsulinName:
                    prescriptionResponse.breakfastInsulinName,
                  breakfastInsulinType:
                    prescriptionResponse.breakfastInsulinType,
                  breakfastDose: prescriptionResponse.breakfastDose,
                  breakfast_0: prescriptionResponse.breakfastCorrectionPlan
                    .glucoseLevel0
                    ? prescriptionResponse.breakfastCorrectionPlan.glucoseLevel0
                    : 'hypo',
                  breakfast_0Level:
                    prescriptionResponse.breakfastCorrectionPlan.glucose0,
                  breakfast_1:
                    prescriptionResponse.breakfastCorrectionPlan.glucoseLevel1,
                  breakfast_1Level:
                    prescriptionResponse.breakfastCorrectionPlan.glucose1,
                  breakfast_2:
                    prescriptionResponse.breakfastCorrectionPlan.glucoseLevel2,
                  breakfast_2Level:
                    prescriptionResponse.breakfastCorrectionPlan.glucose2,
                  breakfast_3:
                    prescriptionResponse.breakfastCorrectionPlan.glucoseLevel3,
                  breakfast_3Level:
                    prescriptionResponse.breakfastCorrectionPlan.glucose3,
                  breakfast_4:
                    prescriptionResponse.breakfastCorrectionPlan.glucoseLevel4,
                  breakfast_4Level:
                    prescriptionResponse.breakfastCorrectionPlan.glucose4,
                  breakfast_5:
                    prescriptionResponse.breakfastCorrectionPlan.glucoseLevel5,
                  breakfast_5Level:
                    prescriptionResponse.breakfastCorrectionPlan.glucose5,
                  breakfast_6:
                    prescriptionResponse.breakfastCorrectionPlan.glucoseLevel6,
                  breakfast_6Level:
                    prescriptionResponse.breakfastCorrectionPlan.glucose6,
                  breakfastTimeOfDayStart: prescriptionResponse
                    .breakfastCorrectionPlan.timeOfDayStart
                    ? moment(
                        prescriptionResponse.breakfastCorrectionPlan
                          .timeOfDayStart,
                      ).format('h:mm A')
                    : this.state.breakfastTimeOfDayStart,
                  breakfastTimeOfDayEnd: prescriptionResponse
                    .breakfastCorrectionPlan.timeOfDayEnd
                    ? moment(
                        prescriptionResponse.breakfastCorrectionPlan
                          .timeOfDayEnd,
                      ).format('h:mm A')
                    : this.state.breakfastTimeOfDayEnd,

                  lunchTrackGlucose: prescriptionResponse.lunchTrackGlucose,
                  lunchActive: prescriptionResponse.lunchActive,
                  //we can only enable bolus
                  lunchCorrection:
                    prescriptionResponse.lunchInsulinType === 'bolus'
                      ? prescriptionResponse.lunchCorrection
                      : false,
                  lunchInsulinId: prescriptionResponse.lunchInsulinId,
                  lunchInsulinName: prescriptionResponse.lunchInsulinName,
                  lunchInsulinType: prescriptionResponse.lunchInsulinType,
                  lunchDose: prescriptionResponse.lunchDose,
                  lunch_0: prescriptionResponse.lunchCorrectionPlan
                    .glucoseLevel0
                    ? prescriptionResponse.lunchCorrectionPlan.glucoseLevel0
                    : 'hypo',
                  lunch_0Level:
                    prescriptionResponse.lunchCorrectionPlan.glucose0,
                  lunch_1:
                    prescriptionResponse.lunchCorrectionPlan.glucoseLevel1,
                  lunch_1Level:
                    prescriptionResponse.lunchCorrectionPlan.glucose1,
                  lunch_2:
                    prescriptionResponse.lunchCorrectionPlan.glucoseLevel2,
                  lunch_2Level:
                    prescriptionResponse.lunchCorrectionPlan.glucose2,
                  lunch_3:
                    prescriptionResponse.lunchCorrectionPlan.glucoseLevel3,
                  lunch_3Level:
                    prescriptionResponse.lunchCorrectionPlan.glucose3,
                  lunch_4:
                    prescriptionResponse.lunchCorrectionPlan.glucoseLevel4,
                  lunch_4Level:
                    prescriptionResponse.lunchCorrectionPlan.glucose4,
                  lunch_5:
                    prescriptionResponse.lunchCorrectionPlan.glucoseLevel5,
                  lunch_5Level:
                    prescriptionResponse.lunchCorrectionPlan.glucose5,
                  lunch_6:
                    prescriptionResponse.lunchCorrectionPlan.glucoseLevel6,
                  lunch_6Level:
                    prescriptionResponse.lunchCorrectionPlan.glucose6,
                  lunchTimeOfDayStart: prescriptionResponse.lunchCorrectionPlan
                    .timeOfDayStart
                    ? moment(
                        prescriptionResponse.lunchCorrectionPlan.timeOfDayStart,
                      ).format('h:mm A')
                    : this.state.lunchTimeOfDayStart,
                  lunchTimeOfDayEnd: prescriptionResponse.lunchCorrectionPlan
                    .timeOfDayEnd
                    ? moment(
                        prescriptionResponse.lunchCorrectionPlan.timeOfDayEnd,
                      ).format('h:mm A')
                    : this.state.lunchTimeOfDayEnd,

                  dinnerTrackGlucose: prescriptionResponse.dinnerTrackGlucose,
                  dinnerActive: prescriptionResponse.dinnerActive,
                  //we can only enable bolus
                  dinnerCorrection:
                    prescriptionResponse.dinnerInsulinType === 'bolus'
                      ? prescriptionResponse.dinnerCorrection
                      : false,
                  dinnerInsulinId: prescriptionResponse.dinnerInsulinId,
                  dinnerInsulinName: prescriptionResponse.dinnerInsulinName,
                  dinnerInsulinType: prescriptionResponse.dinnerInsulinType,
                  dinnerDose: prescriptionResponse.dinnerDose,
                  dinner_0: prescriptionResponse.dinnerCorrectionPlan
                    .glucoseLevel0
                    ? prescriptionResponse.dinnerCorrectionPlan.glucoseLevel0
                    : 'hypo',
                  dinner_0Level:
                    prescriptionResponse.dinnerCorrectionPlan.glucose0,
                  dinner_1:
                    prescriptionResponse.dinnerCorrectionPlan.glucoseLevel1,
                  dinner_1Level:
                    prescriptionResponse.dinnerCorrectionPlan.glucose1,
                  dinner_2:
                    prescriptionResponse.dinnerCorrectionPlan.glucoseLevel2,
                  dinner_2Level:
                    prescriptionResponse.dinnerCorrectionPlan.glucose2,
                  dinner_3:
                    prescriptionResponse.dinnerCorrectionPlan.glucoseLevel3,
                  dinner_3Level:
                    prescriptionResponse.dinnerCorrectionPlan.glucose3,
                  dinner_4:
                    prescriptionResponse.dinnerCorrectionPlan.glucoseLevel4,
                  dinner_4Level:
                    prescriptionResponse.dinnerCorrectionPlan.glucose4,
                  dinner_5:
                    prescriptionResponse.dinnerCorrectionPlan.glucoseLevel5,
                  dinner_5Level:
                    prescriptionResponse.dinnerCorrectionPlan.glucose5,
                  dinner_6:
                    prescriptionResponse.dinnerCorrectionPlan.glucoseLevel6,
                  dinner_6Level:
                    prescriptionResponse.dinnerCorrectionPlan.glucose6,
                  dinnerTimeOfDayStart: prescriptionResponse
                    .dinnerCorrectionPlan.timeOfDayStart
                    ? moment(
                        prescriptionResponse.dinnerCorrectionPlan
                          .timeOfDayStart,
                      ).format('h:mm A')
                    : this.state.dinnerTimeOfDayStart,
                  dinnerTimeOfDayEnd: prescriptionResponse.dinnerCorrectionPlan
                    .timeOfDayEnd
                    ? moment(
                        prescriptionResponse.dinnerCorrectionPlan.timeOfDayEnd,
                      ).format('h:mm A')
                    : this.state.dinnerTimeOfDayEnd,

                  nighttimeTrackGlucose:
                    prescriptionResponse.nighttimeTrackGlucose,
                  nighttimeActive: prescriptionResponse.nighttimeActive,
                  //we can only enable bolus
                  nighttimeCorrection:
                    prescriptionResponse.nighttimeInsulinType === 'bolus'
                      ? prescriptionResponse.nighttimeCorrection
                      : false,
                  nighttimeInsulinId: prescriptionResponse.nighttimeInsulinId,
                  nighttimeInsulinName:
                    prescriptionResponse.nighttimeInsulinName,
                  nighttimeInsulinType:
                    prescriptionResponse.nighttimeInsulinType,
                  nighttimeDose: prescriptionResponse.nighttimeDose,
                  nighttime_0: prescriptionResponse.nighttimeCorrectionPlan
                    .glucoseLevel0
                    ? prescriptionResponse.nighttimeCorrectionPlan.glucoseLevel0
                    : 'hypo',
                  nighttime_0Level:
                    prescriptionResponse.nighttimeCorrectionPlan.glucose0,
                  nighttime_1:
                    prescriptionResponse.nighttimeCorrectionPlan.glucoseLevel1,
                  nighttime_1Level:
                    prescriptionResponse.nighttimeCorrectionPlan.glucose1,
                  nighttime_2:
                    prescriptionResponse.nighttimeCorrectionPlan.glucoseLevel2,
                  nighttime_2Level:
                    prescriptionResponse.nighttimeCorrectionPlan.glucose2,
                  nighttime_3:
                    prescriptionResponse.nighttimeCorrectionPlan.glucoseLevel3,
                  nighttime_3Level:
                    prescriptionResponse.nighttimeCorrectionPlan.glucose3,
                  nighttime_4:
                    prescriptionResponse.nighttimeCorrectionPlan.glucoseLevel4,
                  nighttime_4Level:
                    prescriptionResponse.nighttimeCorrectionPlan.glucose4,
                  nighttime_5:
                    prescriptionResponse.nighttimeCorrectionPlan.glucoseLevel5,
                  nighttime_5Level:
                    prescriptionResponse.nighttimeCorrectionPlan.glucose5,
                  nighttime_6:
                    prescriptionResponse.nighttimeCorrectionPlan.glucoseLevel6,
                  nighttime_6Level:
                    prescriptionResponse.nighttimeCorrectionPlan.glucose6,
                  nighttimeTimeOfDayStart: prescriptionResponse
                    .nighttimeCorrectionPlan.timeOfDayStart
                    ? moment(
                        prescriptionResponse.nighttimeCorrectionPlan
                          .timeOfDayStart,
                      ).format('h:mm A')
                    : this.state.nighttimeTimeOfDayStart,
                  nighttimeTimeOfDayEnd: prescriptionResponse
                    .nighttimeCorrectionPlan.timeOfDayEnd
                    ? moment(
                        prescriptionResponse.nighttimeCorrectionPlan
                          .timeOfDayEnd,
                      ).format('h:mm A')
                    : this.state.nighttimeTimeOfDayEnd,

                  notes: prescriptionResponse.notes,
                  notesState: prescriptionResponse.notes ? 'has-success' : '',
                });
              }
            }
          })
          .catch((error) => {
            this.setState({
              error: error,
              disabled: false,
            });
          });
      }

      //do we have a patient id?
      //yes
      if (qs['patientId']) {
        fetch(
          window.configuration.PatientURL + '?id=' + qs['patientId'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              return;
            }
            //did we get an error?
            //no
            else {
              var patientResponse = await response.json();

              //did we get a good one?
              //yes
              if (patientResponse.id) {
                //set the name in the top ones
                optionsInsulin[1].label = patientResponse.basalInsulinName
                  ? patientResponse.basalInsulinName
                  : 'Basal';
                optionsInsulin[2].label = patientResponse.bolusInsulinName
                  ? patientResponse.bolusInsulinName
                  : 'Bolus';
                optionsInsulin[3].label = patientResponse.mixedInsulinName
                  ? patientResponse.mixedInsulinName
                  : 'Mixed';

                optionsAddUnits[0].label =
                  '1 unit of ' +
                  (patientResponse.bolusInsulinName
                    ? patientResponse.bolusInsulinName
                    : 'Bolus');
                optionsAddUnits[1].label =
                  '2 units of ' +
                  (patientResponse.bolusInsulinName
                    ? patientResponse.bolusInsulinName
                    : 'Bolus');
                optionsAddUnits[2].label =
                  '3 units of ' +
                  (patientResponse.bolusInsulinName
                    ? patientResponse.bolusInsulinName
                    : 'Bolus');

                this.setState({
                  loading: false,
                  patientName:
                    patientResponse.firstName + ' ' + patientResponse.lastName,
                  correctionMGEvery:
                    patientResponse.correctionMGEvery > 0
                      ? patientResponse.correctionMGEvery
                      : this.state.correctionMGEvery,
                  correctionMGBaseline:
                    patientResponse.correctionMGBaseline > 0
                      ? patientResponse.correctionMGBaseline
                      : this.state.correctionMGBaseline,
                  correctionAddUnits:
                    patientResponse.correctionAddUnits > 0
                      ? patientResponse.correctionAddUnits
                      : this.state.correctionAddUnits,
                  correctionAddUnitsIndex:
                    patientResponse.correctionAddUnits !== null
                      ? optionsAddUnits.filter(
                          (o) =>
                            o.value ===
                            patientResponse.correctionAddUnits.toString(),
                        ).length > 0
                        ? optionsAddUnits.filter(
                            (o) =>
                              o.value ===
                              patientResponse.correctionAddUnits.toString(),
                          )[0].index
                        : 0
                      : 0,

                  glucoseLevelHypo: patientResponse.glucoseLevelHypo,
                  glucoseLevelHypoState: patientResponse.glucoseLevelHypo
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelGoal: patientResponse.glucoseLevelGoal,
                  glucoseLevelGoalState: patientResponse.glucoseLevelGoal
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelHigh: patientResponse.glucoseLevelHigh,
                  glucoseLevelHighState: patientResponse.glucoseLevelHigh
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelDanger: patientResponse.glucoseLevelDanger,
                  glucoseLevelDangerState: patientResponse.glucoseLevelDanger
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelImpossible:
                    patientResponse.glucoseLevelImpossible,
                  glucoseLevelImpossibleState: patientResponse.glucoseLevelImpossible
                    ? 'has-success'
                    : 'has-danger',

                  morning_0Level: patientResponse.glucoseLevelHypo
                    ? patientResponse.glucoseLevelHypo
                    : this.state.morning_0Level,
                  breakfast_0Level: patientResponse.glucoseLevelHypo
                    ? patientResponse.glucoseLevelHypo
                    : this.state.breakfast_0Level,
                  lunch_0Level: patientResponse.glucoseLevelHypo
                    ? patientResponse.glucoseLevelHypo
                    : this.state.lunch_0Level,
                  dinner_0Level: patientResponse.glucoseLevelHypo
                    ? patientResponse.glucoseLevelHypo
                    : this.state.dinner_0Level,
                  nighttime_0Level: patientResponse.glucoseLevelHypo
                    ? patientResponse.glucoseLevelHypo
                    : this.state.nighttime_0Level,

                  basalInsulinId: patientResponse.basalInsulinId,
                  basalInsulinName: patientResponse.basalInsulinName,
                  bolusInsulinId: patientResponse.bolusInsulinId,
                  bolusInsulinName: patientResponse.bolusInsulinName,
                  mixedInsulinId: patientResponse.mixedInsulinId,
                  mixedInsulinName: patientResponse.mixedInsulinName,
                  basalInsulinIndex: patientResponse.basalInsulinId
                    ? this.state.insulinData.length > 0
                      ? this.state.insulinData.filter(
                          (o) => o.value === patientResponse.basalInsulinId,
                        )[0].index
                      : -1
                    : -1,
                  bolusInsulinIndex: patientResponse.bolusInsulinId
                    ? this.state.insulinData.length > 0
                      ? this.state.insulinData.filter(
                          (o) => o.value === patientResponse.bolusInsulinId,
                        )[0].index
                      : -1
                    : -1,
                  mixedInsulinIndex: patientResponse.mixedInsulinId
                    ? this.state.insulinData.length > 0
                      ? this.state.insulinData.filter(
                          (o) => o.value === patientResponse.mixedInsulinId,
                        )[0].index
                      : -1
                    : -1,

                  recalculateNumbers: true,
                });
              } else {
                //set the name in the top ones
                optionsInsulin[1].label = patientResponse.basalInsulinName
                  ? patientResponse.basalInsulinName
                  : 'Basal';
                optionsInsulin[2].label = patientResponse.bolusInsulinName
                  ? patientResponse.bolusInsulinName
                  : 'Bolus';
                optionsInsulin[3].label = patientResponse.mixedInsulinName
                  ? patientResponse.mixedInsulinName
                  : 'Mixed';

                optionsAddUnits[0].label =
                  '1 unit of ' +
                  (patientResponse.bolusInsulinName
                    ? patientResponse.bolusInsulinName
                    : 'Bolus');
                optionsAddUnits[1].label =
                  '2 units of ' +
                  (patientResponse.bolusInsulinName
                    ? patientResponse.bolusInsulinName
                    : 'Bolus');
                optionsAddUnits[2].label =
                  '3 units of ' +
                  (patientResponse.bolusInsulinName
                    ? patientResponse.bolusInsulinName
                    : 'Bolus');

                this.setState({
                  loading: false,
                  patientName:
                    patientResponse.firstName + ' ' + patientResponse.lastName,
                  correctionMGEvery:
                    patientResponse.correctionMGEvery > 0
                      ? patientResponse.correctionMGEvery
                      : this.state.correctionMGEvery,
                  correctionMGBaseline:
                    patientResponse.correctionMGBaseline > 0
                      ? patientResponse.correctionMGBaseline
                      : this.state.correctionMGBaseline,
                  correctionAddUnits:
                    patientResponse.correctionAddUnits > 0
                      ? patientResponse.correctionAddUnits
                      : this.state.correctionAddUnits,

                  glucoseLevelHypo: patientResponse.glucoseLevelHypo
                    ? parseInt(patientResponse.glucoseLevelHypo)
                    : null,
                  glucoseLevelHypoState: patientResponse.glucoseLevelHypo
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelGoal: patientResponse.glucoseLevelGoal
                    ? parseInt(patientResponse.glucoseLevelGoal)
                    : null,
                  glucoseLevelGoalState: patientResponse.glucoseLevelGoal
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelHigh: patientResponse.glucoseLevelHigh
                    ? parseInt(patientResponse.glucoseLevelHigh)
                    : null,
                  glucoseLevelHighState: patientResponse.glucoseLevelHigh
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelDanger: patientResponse.glucoseLevelDanger
                    ? parseInt(patientResponse.glucoseLevelDanger)
                    : null,
                  glucoseLevelDangerState: patientResponse.glucoseLevelDanger
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelImpossible: patientResponse.glucoseLevelImpossible
                    ? parseInt(patientResponse.glucoseLevelImpossible)
                    : null,
                  glucoseLevelImpossibleState: patientResponse.glucoseLevelImpossible
                    ? 'has-success'
                    : 'has-danger',

                  morning_0Level: patientResponse.glucoseLevelHypo
                    ? patientResponse.glucoseLevelHypo
                    : this.state.morning_0Level,
                  breakfast_0Level: patientResponse.glucoseLevelHypo
                    ? patientResponse.glucoseLevelHypo
                    : this.state.breakfast_0Level,
                  lunch_0Level: patientResponse.glucoseLevelHypo
                    ? patientResponse.glucoseLevelHypo
                    : this.state.lunch_0Level,
                  dinner_0Level: patientResponse.glucoseLevelHypo
                    ? patientResponse.glucoseLevelHypo
                    : this.state.dinner_0Level,
                  nighttime_0Level: patientResponse.glucoseLevelHypo
                    ? patientResponse.glucoseLevelHypo
                    : this.state.nighttime_0Level,

                  basalInsulinId: patientResponse.basalInsulinId,
                  basalInsulinName: patientResponse.basalInsulinName,
                  bolusInsulinId: patientResponse.bolusInsulinId,
                  bolusInsulinName: patientResponse.bolusInsulinName,
                  mixedInsulinId: patientResponse.mixedInsulinId,
                  mixedInsulinName: patientResponse.mixedInsulinName,
                  basalInsulinIndex: patientResponse.basalInsulinId
                    ? this.state.insulinData.length > 0
                      ? this.state.insulinData.filter(
                          (o) => o.value === patientResponse.basalInsulinId,
                        )[0].index
                      : -1
                    : -1,
                  bolusInsulinIndex: patientResponse.bolusInsulinId
                    ? this.state.insulinData.length > 0
                      ? this.state.insulinData.filter(
                          (o) => o.value === patientResponse.bolusInsulinId,
                        )[0].index
                      : -1
                    : -1,
                  mixedInsulinIndex: patientResponse.mixedInsulinId
                    ? this.state.insulinData.length > 0
                      ? this.state.insulinData.filter(
                          (o) => o.value === patientResponse.mixedInsulinId,
                        )[0].index
                      : -1
                    : -1,

                  recalculateNumbers: true,
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: error,
              disabled: false,
            });
          });
      }
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  getIndex(value, arr, prop) {
    //do we have an array?
    //yes
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i;
        }
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  //this submits the form
  async submit(e, navigation) {
    //did we disable the button?
    //yes
    if (this.state.disabled) {
      return;
    }

    var morningStart = moment('1/1/2000 ' + this.state.morningTimeOfDayStart);
    var morningEnd = moment('1/1/2000 ' + this.state.morningTimeOfDayEnd);
    var breakfastStart = moment(
      '1/1/2000 ' + this.state.breakfastTimeOfDayStart,
    );
    var breakfastEnd = moment('1/1/2000 ' + this.state.breakfastTimeOfDayEnd);
    var lunchStart = moment('1/1/2000 ' + this.state.lunchTimeOfDayStart);
    var lunchEnd = moment('1/1/2000 ' + this.state.lunchTimeOfDayEnd);
    var dinnerStart = moment('1/1/2000 ' + this.state.dinnerTimeOfDayStart);
    var dinnerEnd = moment('1/1/2000 ' + this.state.dinnerTimeOfDayEnd);
    var nighttimeStart = moment(
      '1/1/2000 ' + this.state.nighttimeTimeOfDayStart,
    );
    var nighttimeEnd = moment('1/1/2000 ' + this.state.nighttimeTimeOfDayEnd);

    if (morningStart.isValid() === false || morningEnd.isValid() === false) {
      this.setState({
        error: 'Please fix the morning window times.',
      });
      return;
    } else if (
      breakfastStart.isValid() === false ||
      breakfastEnd.isValid() === false
    ) {
      this.setState({
        error: 'Please fix the breakfast window times.',
      });
      return;
    } else if (lunchStart.isValid() === false || lunchEnd.isValid() === false) {
      this.setState({
        error: 'Please fix the lunch window times.',
      });
      return;
    } else if (
      dinnerStart.isValid() === false ||
      dinnerEnd.isValid() === false
    ) {
      this.setState({
        error: 'Please fix the dinner window times.',
      });
      return;
    } else if (
      nighttimeStart.isValid() === false ||
      nighttimeEnd.isValid() === false
    ) {
      this.setState({
        error: 'Please fix the nighttime window times.',
      });
      return;
    }

    //do we have a window issue?
    //yes
    if (
      this.state.morningError !== '' ||
      this.state.breakfastError !== '' ||
      this.state.lunchError !== '' ||
      this.state.dinnerError !== '' ||
      this.state.nighttimeError !== ''
    ) {
      this.setState({
        error: 'Please fix the window times.',
      });
      return;
    }

    //do we have a morning without value?
    //yes
    if (
      this.state.morningActive === true &&
      this.state.morningInsulinType !== '' &&
      this.state.morningInsulinType !== '0' &&
      this.state.morningDose === ''
    ) {
      this.setState({
        error: 'Please enter a morning dose.',
      });
      return;
    }
    //do we have a breakfast without value?
    //yes
    else if (
      this.state.breakfastActive === true &&
      this.state.breakfastInsulinType !== '' &&
      this.state.breakfastInsulinType !== '0' &&
      this.state.breakfastDose === ''
    ) {
      this.setState({
        error: 'Please enter a breakfast dose.',
      });
      return;
    }
    //do we have a lunch without value?
    //yes
    else if (
      this.state.lunchActive === true &&
      this.state.lunchInsulinType !== '' &&
      this.state.lunchInsulinType !== '0' &&
      this.state.lunchDose === ''
    ) {
      this.setState({
        error: 'Please enter a lunch dose.',
      });
      return;
    }
    //do we have a dinner without value?
    //yes
    else if (
      this.state.dinnerActive === true &&
      this.state.dinnerInsulinType !== '' &&
      this.state.dinnerInsulinType !== '0' &&
      this.state.dinnerDose === ''
    ) {
      this.setState({
        error: 'Please enter a dinner dose.',
      });
      return;
    }
    //do we have a nighttime without value?
    //yes
    else if (
      this.state.nighttimeActive === true &&
      this.state.nighttimeInsulinType !== '' &&
      this.state.nighttimeInsulinType !== '0' &&
      this.state.nighttimeDose === ''
    ) {
      this.setState({
        error: 'Please enter a nighttime dose.',
      });
      return;
    }

    //do we have a bad baseline?
    //no
    if (this.state.correctionMGBaseline < this.state.glucoseLevelHypo) {
      this.setState({
        error: 'The above number cannot be below the hypo level',
      });
      return;
    }

    //do we have a basal for wake up and a bolus for breakfast?
    //no
    if (
      this.state.morningInsulinType !== 'basal' &&
      this.state.morningInsulinType !== '0' &&
      this.state.morningInsulinType !== '' &&
      this.state.morningActive === true &&
      this.state.breakfastInsulinType !== 'bolus' &&
      this.state.breakfastInsulinType !== '0' &&
      this.state.breakfastInsulinType !== '' &&
      this.state.breakfastActive === true
    ) {
      var dateDiff1 = breakfastStart.diff(morningEnd, 'hours');

      //are they within two hours of each other?
      //yes
      if (dateDiff1 < 2) {
        //tell them they cannot do it
        this.setState({
          error:
            'You cannot have a wake up to morning window less than 2 hours apart with these insulins. Contact the Chief Medical Officer if required.',
        });
        return;
      }
    }

    //do we have a bolus for dinner and a basal for sleep?
    //no
    if (
      this.state.dinnerInsulinType !== 'bolus' &&
      this.state.dinnerInsulinType !== '0' &&
      this.state.dinnerInsulinType !== '' &&
      this.state.dinnerActive === true &&
      this.state.nighttimeInsulinType !== 'basal' &&
      this.state.nighttimeInsulinType !== '0' &&
      this.state.nighttimeInsulinType !== '' &&
      this.state.nighttimeActive === true
    ) {
      var dateDiff2 = nighttimeStart.diff(dinnerEnd, 'hours');

      //are they within two hours of each other?
      //yes
      if (dateDiff2 < 2) {
        //tell them they cannot do it
        this.setState({
          error:
            'You cannot have a dinner to nighttime window less than 2 hours apart with these insulins. Contact the Chief Medical Officer if required.',
        });
        return;
      }
    }

    //do we have an active morning with no bg and no insulin?
    //no
    if (
      this.state.morningActive === true &&
      this.state.morningTrackGlucose === false &&
      this.state.morningInsulinType === ''
    ) {
      //tell them they cannot do it
      this.setState({
        error:
          'You cannot have a morning active with no blood glucose and no insulin selected.',
      });
      return;
    }

    //do we have an active breakfast with no bg and no insulin?
    //no
    if (
      this.state.breakfastActive === true &&
      this.state.breakfastTrackGlucose === false &&
      this.state.breakfastInsulinType === ''
    ) {
      //tell them they cannot do it
      this.setState({
        error:
          'You cannot have a breakfast active with no blood glucose and no insulin selected.',
      });
      return;
    }

    //do we have an active lunch with no bg and no insulin?
    //no
    if (
      this.state.lunchActive === true &&
      this.state.lunchTrackGlucose === false &&
      this.state.lunchInsulinType === ''
    ) {
      //tell them they cannot do it
      this.setState({
        error:
          'You cannot have a lunch active with no blood glucose and no insulin selected.',
      });
      return;
    }

    //do we have an active dinner with no bg and no insulin?
    //no
    if (
      this.state.dinnerActive === true &&
      this.state.dinnerTrackGlucose === false &&
      this.state.dinnerInsulinType === ''
    ) {
      //tell them they cannot do it
      this.setState({
        error:
          'You cannot have a dinner active with no blood glucose and no insulin selected.',
      });
      return;
    }

    //do we have an active nighttime with no bg and no insulin?
    //no
    if (
      this.state.nighttimeActive === true &&
      this.state.nighttimeTrackGlucose === false &&
      this.state.nighttimeInsulinType === ''
    ) {
      //tell them they cannot do it
      this.setState({
        error:
          'You cannot have a nighttime active with no blood glucose and no insulin selected.',
      });
      return;
    }

    //do we have breakfast and lunch windows?
    //yes
    if (
      this.state.testPatient === false &&
      this.state.breakfastActive === true &&
      this.state.lunchActive === true &&
      this.state.breakfastInsulinId !== '' &&
      this.state.breakfastInsulinId !== '0' &&
      this.state.lunchInsulinId !== '' &&
      this.state.lunchInsulinId !== '0'
    ) {
      var dateDiffWindow1 = lunchStart.diff(breakfastEnd, 'hours');

      //are they within two hours of each other?
      //yes
      if (dateDiffWindow1 < 2) {
        //tell them they cannot do it
        this.setState({
          error:
            'You cannot have breakfast and lunch within two hours of each other',
        });
        return;
      }
    }

    //do we have lunch and dinner windows?
    //yes
    if (
      this.state.testPatient === false &&
      this.state.lunchActive === true &&
      this.state.dinnerActive === true &&
      this.state.lunchInsulinId !== '' &&
      this.state.lunchInsulinId !== '0' &&
      this.state.dinnerInsulinId !== '' &&
      this.state.dinnerInsulinId !== '0'
    ) {
      var dateDiffWindow2 = dinnerStart.diff(lunchEnd, 'hours');

      //are they within two hours of each other?
      //yes
      if (dateDiffWindow2 < 2) {
        //tell them they cannot do it
        this.setState({
          error:
            'You cannot have lunch and dinner within two hours of each other',
        });
        return;
      }
    }

    //do we have a basal prescription that isn't set yet?
    //yes
    if (
      (this.state.morningInsulinType.toLowerCase() === 'basal' ||
        this.state.breakfastInsulinType.toLowerCase() === 'basal' ||
        this.state.lunchInsulinType.toLowerCase() === 'basal' ||
        this.state.dinnerInsulinType.toLowerCase() === 'basal' ||
        this.state.nighttimeInsulinType.toLowerCase() === 'basal') &&
      this.state.basalInsulinId === ''
    ) {
      //tell them they need to set it
      this.setState({
        error: 'Please select a Basal insulin option on the left',
      });
      return;
    }

    //do we have a bolus prescription that isn't set yet?
    //yes
    if (
      (this.state.morningInsulinType.toLowerCase() === 'bolus' ||
        this.state.breakfastInsulinType.toLowerCase() === 'bolus' ||
        this.state.lunchInsulinType.toLowerCase() === 'bolus' ||
        this.state.dinnerInsulinType.toLowerCase() === 'bolus' ||
        this.state.nighttimeInsulinType.toLowerCase() === 'bolus') &&
      this.state.bolusInsulinId === ''
    ) {
      //tell them they need to set it
      this.setState({
        error: 'Please select a Bolus insulin option on the left',
      });
      return;
    }

    //do we have a mixed prescription that isn't set yet?
    //yes
    if (
      (this.state.morningInsulinType.toLowerCase() === 'mixed' ||
        this.state.breakfastInsulinType.toLowerCase() === 'mixed' ||
        this.state.lunchInsulinType.toLowerCase() === 'mixed' ||
        this.state.dinnerInsulinType.toLowerCase() === 'mixed' ||
        this.state.nighttimeInsulinType.toLowerCase() === 'mixed') &&
      this.state.mixedInsulinId === ''
    ) {
      //tell them they need to set it
      this.setState({
        error: 'Please select a Mixed insulin option on the left',
      });
      return;
    }

    //do we have a hypo?
    //no
    if (isNaN(parseInt(this.state.glucoseLevelHypo)) === true) {
      //tell them they cannot do it
      this.setState({
        error: 'Please enter a Hypo blood glucose level',
        bgerror: 'Please enter a Hypo level',
      });
      return;
    }
    //do we have a goal?
    //no
    if (isNaN(parseInt(this.state.glucoseLevelGoal)) === true) {
      //tell them they cannot do it
      this.setState({
        error: 'Please enter a Goal blood glucose level',
        bgerror: 'Please enter a Goal level',
      });
      return;
    }
    //do we have a high?
    //no
    if (isNaN(parseInt(this.state.glucoseLevelHigh)) === true) {
      //tell them they cannot do it
      this.setState({
        error: 'Please enter a High blood glucose level',
        bgerror: 'Please enter a High level',
      });
      return;
    }
    //do we have a danger?
    //no
    if (isNaN(parseInt(this.state.glucoseLevelDanger)) === true) {
      //tell them they cannot do it
      this.setState({
        error: 'Please enter a Danger blood glucose level',
        bgerror: 'Please enter a Danger level',
      });
      return;
    }
    //do we have a impossible?
    //no
    if (isNaN(parseInt(this.state.glucoseLevelImpossible)) === true) {
      //tell them they cannot do it
      this.setState({
        error: 'Please enter an Impossible blood glucose level',
        bgerror: 'Please enter an Impossible level',
      });
      return;
    }

    var confirmNumbers = this.checkValues(
      this.state.glucoseLevelHypo,
      this.state.glucoseLevelGoal,
      this.state.glucoseLevelHigh,
      this.state.glucoseLevelDanger,
      this.state.glucoseLevelImpossible,
    );
    //do we have a bad baseline?
    //no
    if (confirmNumbers !== '') {
      this.setState({
        bgerror: confirmNumbers,
      });
      return;
    }

    this.setState({
      disabled: true,
      error: '',
      bgerror: '',
      loading: true,
    });

    var qs = queryString.parse(this.props.location.search);

    // POST request using fetch with error handling
    var requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
      body: JSON.stringify({
        //we set id because this is the prescription record
        id: qs['patientId'],

        correctionMGEvery: this.state.correctionMGEvery,
        correctionMGBaseline: this.state.correctionMGBaseline,
        correctionAddUnits: this.state.correctionAddUnits,

        glucoseLevelHypo: this.state.glucoseLevelHypo,
        glucoseLevelGoal: this.state.glucoseLevelGoal,
        glucoseLevelHigh: this.state.glucoseLevelHigh,
        glucoseLevelDanger: this.state.glucoseLevelDanger,
        glucoseLevelImpossible: this.state.glucoseLevelImpossible,

        basalInsulinId: this.state.basalInsulinId,
        basalInsulinName: this.state.basalInsulinName,
        bolusInsulinId: this.state.bolusInsulinId,
        bolusInsulinName: this.state.bolusInsulinName,

        mixedInsulinId: this.state.mixedInsulinId,
        mixedInsulinName: this.state.mixedInsulinName,
      }),
    };

    const requestOptions2 = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
      body: JSON.stringify({
        //we set id because this is the prescription record
        id: this.state.id,
        patientId: qs['patientId'],
        dateStart: this.state.dateStart,

        correctionMGEvery: this.state.correctionMGEvery,
        correctionMGBaseline: this.state.correctionMGBaseline,
        correctionAddUnits: this.state.correctionAddUnits,

        glucoseLevelHypo: this.state.glucoseLevelHypo,
        glucoseLevelGoal: this.state.glucoseLevelGoal,
        glucoseLevelHigh: this.state.glucoseLevelHigh,
        glucoseLevelDanger: this.state.glucoseLevelDanger,
        glucoseLevelImpossible: this.state.glucoseLevelImpossible,

        basalInsulinId: this.state.basalInsulinId,
        basalInsulinName: this.state.basalInsulinName,

        bolusInsulinId: this.state.bolusInsulinId,
        bolusInsulinName: this.state.bolusInsulinName,

        mixedInsulinId: this.state.mixedInsulinId,
        mixedInsulinName: this.state.mixedInsulinName,

        morningTrackGlucose: this.state.morningTrackGlucose,
        morningActive: this.state.morningActive,
        morningCorrection: this.state.morningCorrection,
        morningInsulinId: this.state.morningInsulinId,
        morningInsulinName: this.state.morningInsulinName,
        morningInsulinType: this.state.morningInsulinType,
        morningDose: this.state.morningDose,
        morningCorrectionPlan: {
          patientId: qs['patientId'],
          trackGlucose: this.state.morningTrackGlucose,
          glucoseLevel0:
            this.state.morning_0 !== 'hypo' ? this.state.morning_0 : null,
          glucose0: this.state.morning_0Level,
          glucoseLevel1: this.state.morning_1,
          glucose1: this.state.morning_1Level,
          glucoseLevel2: this.state.morning_2,
          glucose2: this.state.morning_2Level,
          glucoseLevel3: this.state.morning_3,
          glucose3: this.state.morning_3Level,
          glucoseLevel4: this.state.morning_4,
          glucose4: this.state.morning_4Level,
          glucoseLevel5: this.state.morning_5,
          glucose5: this.state.morning_5Level,
          glucoseLevel6: this.state.morning_6,
          glucose6: this.state.morning_6Level,
          insulinId: this.state.morningInsulinId,
          insulinName: this.state.morningInsulinName,
          insulinType: this.state.morningInsulinType,
          correction: this.state.morningCorrection,
          timeOfDayStart: this.state.morningTimeOfDayStart,
          timeOfDayEnd: this.state.morningTimeOfDayEnd,
          dateStart: this.state.dateStart,
          enabled: this.state.morningActive,
        },

        breakfastTrackGlucose: this.state.breakfastTrackGlucose,
        breakfastActive: this.state.breakfastActive,
        breakfastCorrection: this.state.breakfastCorrection,
        breakfastInsulinId: this.state.breakfastInsulinId,
        breakfastInsulinName: this.state.breakfastInsulinName,
        breakfastInsulinType: this.state.breakfastInsulinType,
        breakfastDose: this.state.breakfastDose,
        breakfastCorrectionPlan: {
          patientId: qs['patientId'],
          trackGlucose: this.state.breakfastTrackGlucose,
          glucoseLevel0:
            this.state.breakfast_0 !== 'hypo' ? this.state.breakfast_0 : null,
          glucose0: this.state.breakfast_0Level,
          glucoseLevel1: this.state.breakfast_1,
          glucose1: this.state.breakfast_1Level,
          glucoseLevel2: this.state.breakfast_2,
          glucose2: this.state.breakfast_2Level,
          glucoseLevel3: this.state.breakfast_3,
          glucose3: this.state.breakfast_3Level,
          glucoseLevel4: this.state.breakfast_4,
          glucose4: this.state.breakfast_4Level,
          glucoseLevel5: this.state.breakfast_5,
          glucose5: this.state.breakfast_5Level,
          glucoseLevel6: this.state.breakfast_6,
          glucose6: this.state.breakfast_6Level,
          insulinId: this.state.breakfastInsulinId,
          insulinName: this.state.breakfastInsulinName,
          insulinType: this.state.breakfastInsulinType,
          correction: this.state.breakfastCorrection,
          timeOfDayStart: this.state.breakfastTimeOfDayStart,
          timeOfDayEnd: this.state.breakfastTimeOfDayEnd,
          dateStart: this.state.dateStart,
          enabled: this.state.breakfastActive,
        },

        lunchTrackGlucose: this.state.lunchTrackGlucose,
        lunchActive: this.state.lunchActive,
        lunchCorrection: this.state.lunchCorrection,
        lunchInsulinId: this.state.lunchInsulinId,
        lunchInsulinName: this.state.lunchInsulinName,
        lunchInsulinType: this.state.lunchInsulinType,
        lunchDose: this.state.lunchDose,
        lunchCorrectionPlan: {
          patientId: qs['patientId'],
          trackGlucose: this.state.lunchTrackGlucose,
          glucoseLevel0:
            this.state.lunch_0 !== 'hypo' ? this.state.lunch_0 : null,
          glucose0: this.state.lunch_0Level,
          glucoseLevel1: this.state.lunch_1,
          glucose1: this.state.lunch_1Level,
          glucoseLevel2: this.state.lunch_2,
          glucose2: this.state.lunch_2Level,
          glucoseLevel3: this.state.lunch_3,
          glucose3: this.state.lunch_3Level,
          glucoseLevel4: this.state.lunch_4,
          glucose4: this.state.lunch_4Level,
          glucoseLevel5: this.state.lunch_5,
          glucose5: this.state.lunch_5Level,
          glucoseLevel6: this.state.lunch_6,
          glucose6: this.state.lunch_6Level,
          insulinId: this.state.lunchInsulinId,
          insulinName: this.state.lunchInsulinName,
          insulinType: this.state.lunchInsulinType,
          correction: this.state.lunchCorrection,
          timeOfDayStart: this.state.lunchTimeOfDayStart,
          timeOfDayEnd: this.state.lunchTimeOfDayEnd,
          dateStart: this.state.dateStart,
          enabled: this.state.lunchActive,
        },

        dinnerTrackGlucose: this.state.dinnerTrackGlucose,
        dinnerActive: this.state.dinnerActive,
        dinnerCorrection: this.state.dinnerCorrection,
        dinnerInsulinId: this.state.dinnerInsulinId,
        dinnerInsulinName: this.state.dinnerInsulinName,
        dinnerInsulinType: this.state.dinnerInsulinType,
        dinnerDose: this.state.dinnerDose,
        dinnerCorrectionPlan: {
          patientId: qs['patientId'],
          trackGlucose: this.state.dinnerTrackGlucose,
          glucoseLevel0:
            this.state.dinner_0 !== 'hypo' ? this.state.dinner_0 : null,
          glucose0: this.state.dinner_0Level,
          glucoseLevel1: this.state.dinner_1,
          glucose1: this.state.dinner_1Level,
          glucoseLevel2: this.state.dinner_2,
          glucose2: this.state.dinner_2Level,
          glucoseLevel3: this.state.dinner_3,
          glucose3: this.state.dinner_3Level,
          glucoseLevel4: this.state.dinner_4,
          glucose4: this.state.dinner_4Level,
          glucoseLevel5: this.state.dinner_5,
          glucose5: this.state.dinner_5Level,
          glucoseLevel6: this.state.dinner_6,
          glucose6: this.state.dinner_6Level,
          insulinId: this.state.dinnerInsulinId,
          insulinName: this.state.dinnerInsulinName,
          insulinType: this.state.dinnerInsulinType,
          correction: this.state.dinnerCorrection,
          timeOfDayStart: this.state.dinnerTimeOfDayStart,
          timeOfDayEnd: this.state.dinnerTimeOfDayEnd,
          dateStart: this.state.dateStart,
          enabled: this.state.dinnerActive,
        },

        nighttimeTrackGlucose: this.state.nighttimeTrackGlucose,
        nighttimeActive: this.state.nighttimeActive,
        nighttimeCorrection: this.state.nighttimeCorrection,
        nighttimeInsulinId: this.state.nighttimeInsulinId,
        nighttimeInsulinName: this.state.nighttimeInsulinName,
        nighttimeInsulinType: this.state.nighttimeInsulinType,
        nighttimeDose: this.state.nighttimeDose,
        nighttimeCorrectionPlan: {
          patientId: qs['patientId'],
          trackGlucose: this.state.nighttimeTrackGlucose,
          glucoseLevel0:
            this.state.nighttime_0 !== 'hypo' ? this.state.nighttime_0 : null,
          glucose0: this.state.nighttime_0Level,
          glucoseLevel1: this.state.nighttime_1,
          glucose1: this.state.nighttime_1Level,
          glucoseLevel2: this.state.nighttime_2,
          glucose2: this.state.nighttime_2Level,
          glucoseLevel3: this.state.nighttime_3,
          glucose3: this.state.nighttime_3Level,
          glucoseLevel4: this.state.nighttime_4,
          glucose4: this.state.nighttime_4Level,
          glucoseLevel5: this.state.nighttime_5,
          glucose5: this.state.nighttime_5Level,
          glucoseLevel6: this.state.nighttime_6,
          glucose6: this.state.nighttime_6Level,
          insulinId: this.state.nighttimeInsulinId,
          insulinName: this.state.nighttimeInsulinName,
          insulinType: this.state.nighttimeInsulinType,
          correction: this.state.nighttimeCorrection,
          timeOfDayStart: this.state.nighttimeTimeOfDayStart,
          timeOfDayEnd: this.state.nighttimeTimeOfDayEnd,
          dateStart: this.state.dateStart,
          enabled: this.state.nighttimeActive,
        },

        notes: this.state.notes,
      }),
    };

    await fetch(window.configuration.PatientURL, requestOptions)
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          //did we get an unauthorized?
          //no
          else {
            //set the error
            this.setState({
              error: 'We were unable to update the prescription',
              disabled: false,
              loading: false,
            });
          }
        }
      })
      .then(async () => {
        await fetch(
          window.configuration.InsulinPrescriptionURL,
          requestOptions2,
        ).then(async (response) => {
          // check for error response
          //did we get an error?
          //yes
          if (!response.ok) {
            //did we get an unauthorized?
            //yes
            if (response.status === 401) {
              this.props.history.push('/a/logout');
            }
            //did we get an unauthorized?
            //no
            else {
              //set the error
              this.setState({
                error: 'We were unable to add the prescription',
                disabled: false,
                loading: false,
              });
            }
            return;
          }
          //did we get an error?
          //no
          else {
            this.Cancel();
          }
        });
      })
      .catch((error) => {
        console.log(error);

        this.setState({
          error: error,
        });
      });

    // POST request using fetch with error handling
  }

  render() {
    //are we still loading?
    //yes
    if (this.state.loading) {
      return (
        <Loader
          height="0"
          width="0"
          type="ThreeDots"
          color="#00BFFF"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            background: '#99CCFF',
            opacity: '0.6',
            zIndex: '998',
            height: '100%',
            width: '100%',
          }}
        />
      );
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={7}>
              <Form>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Patient:{' '}
                      {this.state.patientName +
                        (this.state.testPatient === true
                          ? ' (TEST PATIENT)'
                          : '')}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          paddingBottom: 10,
                          borderRight: '1px solid #D3D3D3',
                        }}
                      ></Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          paddingBottom: 10,
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        Morning
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          paddingBottom: 10,
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        Breakfast
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          paddingBottom: 10,
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        Lunch
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          paddingBottom: 10,
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        Dinner
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{ paddingBottom: 10, textAlign: 'center' }}
                      >
                        Nighttime
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          paddingTop: 10,
                        }}
                      >
                        Prescription
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Select
                            isDisabled={!this.state.morningActive}
                            className="react-select"
                            classNamePrefix="react-select"
                            options={optionsInsulin}
                            onChange={(item) => {
                              var id = '';
                              var name = '';
                              var units = this.state.morningDose;
                              var turnOff = !this.state.morningCorrection;

                              //figure out which one
                              switch (item.value) {
                                case 'basal': {
                                  id = this.state.basalInsulinId
                                    ? this.state.basalInsulinId
                                    : 'basal';
                                  name = this.state.basalInsulinName
                                    ? this.state.basalInsulinName
                                    : 'Basal';
                                  turnOff = true;
                                  break;
                                }
                                case 'bolus': {
                                  id = this.state.bolusInsulinId
                                    ? this.state.bolusInsulinId
                                    : 'bolus';
                                  name = this.state.bolusInsulinName
                                    ? this.state.bolusInsulinName
                                    : 'Bolus';
                                  break;
                                }
                                case 'mixed': {
                                  id = this.state.mixedInsulinId
                                    ? this.state.mixedInsulinId
                                    : 'mixed';
                                  name = this.state.mixedInsulinName
                                    ? this.state.mixedInsulinName
                                    : 'Mixed';
                                  turnOff = true;
                                  break;
                                }
                                default: //0
                                {
                                  id = '';
                                  name = '';
                                  units = '';
                                  turnOff = true;
                                  break;
                                }
                              }

                              this.setState({
                                morningInsulinId: id,
                                morningInsulinName: name,
                                morningInsulinType: item.value,
                                morningCorrection: !turnOff,
                                morningDose: units,
                                recalculateNumbers: true,
                              });
                            }}
                            value={
                              this.state.morningInsulinType
                                ? optionsInsulin[
                                    this.getIndex(
                                      this.state.morningInsulinType,
                                      optionsInsulin,
                                      'value',
                                    )
                                  ]
                                : ''
                            }
                            placeholder={
                              this.state.basalInsulinId === '' ||
                              this.state.bolusInsulinId === '' ||
                              this.state.mixedInsulinId === ''
                                ? 'No insulin'
                                : 'Select'
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Select
                            isDisabled={!this.state.breakfastActive}
                            className="react-select"
                            classNamePrefix="react-select"
                            options={optionsInsulin}
                            onChange={(item) => {
                              var id = '';
                              var name = '';
                              var units = this.state.breakfastDose;
                              var turnOff = !this.state.breakfastCorrection;

                              //figure out which one
                              switch (item.value) {
                                case 'basal': {
                                  id = this.state.basalInsulinId
                                    ? this.state.basalInsulinId
                                    : 'basal';
                                  name = this.state.basalInsulinName
                                    ? this.state.basalInsulinName
                                    : 'Basal';
                                  turnOff = true;
                                  break;
                                }
                                case 'bolus': {
                                  id = this.state.bolusInsulinId
                                    ? this.state.bolusInsulinId
                                    : 'bolus';
                                  name = this.state.bolusInsulinName
                                    ? this.state.bolusInsulinName
                                    : 'Bolus';
                                  break;
                                }
                                case 'mixed': {
                                  id = this.state.mixedInsulinId
                                    ? this.state.mixedInsulinId
                                    : 'mixed';
                                  name = this.state.mixedInsulinName
                                    ? this.state.mixedInsulinName
                                    : 'Mixed';
                                  turnOff = true;
                                  break;
                                }
                                default: //0
                                {
                                  id = '';
                                  name = '';
                                  units = '';
                                  turnOff = true;
                                  break;
                                }
                              }

                              this.setState({
                                breakfastInsulinId: id,
                                breakfastInsulinName: name,
                                breakfastInsulinType: item.value,
                                breakfastCorrection: !turnOff,
                                breakfastDose: units,
                                recalculateNumbers: true,
                              });
                            }}
                            value={
                              this.state.breakfastInsulinType
                                ? optionsInsulin[
                                    this.getIndex(
                                      this.state.breakfastInsulinType,
                                      optionsInsulin,
                                      'value',
                                    )
                                  ]
                                : ''
                            }
                            placeholder={
                              this.state.basalInsulinId === '' ||
                              this.state.bolusInsulinId === '' ||
                              this.state.mixedInsulinId === ''
                                ? 'No insulin'
                                : 'Select'
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Select
                            isDisabled={!this.state.lunchActive}
                            className="react-select"
                            classNamePrefix="react-select"
                            options={optionsInsulin}
                            onChange={(item) => {
                              var id = '';
                              var name = '';
                              var units = this.state.lunchDose;
                              var turnOff = !this.state.lunchCorrection;

                              //figure out which one
                              switch (item.value) {
                                case 'basal': {
                                  id = this.state.basalInsulinId
                                    ? this.state.basalInsulinId
                                    : 'basal';
                                  name = this.state.basalInsulinName
                                    ? this.state.basalInsulinName
                                    : 'Basal';
                                  turnOff = true;
                                  break;
                                }
                                case 'bolus': {
                                  id = this.state.bolusInsulinId
                                    ? this.state.bolusInsulinId
                                    : 'bolus';
                                  name = this.state.bolusInsulinName
                                    ? this.state.bolusInsulinName
                                    : 'Bolus';
                                  break;
                                }
                                case 'mixed': {
                                  id = this.state.mixedInsulinId
                                    ? this.state.mixedInsulinId
                                    : 'mixed';
                                  name = this.state.mixedInsulinName
                                    ? this.state.mixedInsulinName
                                    : 'Mixed';
                                  turnOff = true;
                                  break;
                                }
                                default: //0
                                {
                                  id = '';
                                  name = '';
                                  units = '';
                                  turnOff = true;
                                  break;
                                }
                              }

                              this.setState({
                                lunchInsulinId: id,
                                lunchInsulinName: name,
                                lunchInsulinType: item.value,
                                lunchCorrection: !turnOff,
                                lunchDose: units,
                                recalculateNumbers: true,
                              });
                            }}
                            value={
                              this.state.lunchInsulinType
                                ? optionsInsulin[
                                    this.getIndex(
                                      this.state.lunchInsulinType,
                                      optionsInsulin,
                                      'value',
                                    )
                                  ]
                                : ''
                            }
                            placeholder={
                              this.state.basalInsulinId === '' ||
                              this.state.bolusInsulinId === '' ||
                              this.state.mixedInsulinId === ''
                                ? 'No insulin'
                                : 'Select'
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Select
                            isDisabled={!this.state.dinnerActive}
                            className="react-select"
                            classNamePrefix="react-select"
                            options={optionsInsulin}
                            onChange={(item) => {
                              var id = '';
                              var name = '';
                              var units = this.state.dinnerDose;
                              var turnOff = !this.state.dinnerCorrection;

                              //figure out which one
                              switch (item.value) {
                                case 'basal': {
                                  id = this.state.basalInsulinId
                                    ? this.state.basalInsulinId
                                    : 'basal';
                                  name = this.state.basalInsulinName
                                    ? this.state.basalInsulinName
                                    : 'Basal';
                                  turnOff = true;
                                  break;
                                }
                                case 'bolus': {
                                  id = this.state.bolusInsulinId
                                    ? this.state.bolusInsulinId
                                    : 'bolus';
                                  name = this.state.bolusInsulinName
                                    ? this.state.bolusInsulinName
                                    : 'Bolus';
                                  break;
                                }
                                case 'mixed': {
                                  id = this.state.mixedInsulinId
                                    ? this.state.mixedInsulinId
                                    : 'mixed';
                                  name = this.state.mixedInsulinName
                                    ? this.state.mixedInsulinName
                                    : 'Mixed';
                                  turnOff = true;
                                  break;
                                }
                                default: //0
                                {
                                  id = '';
                                  name = '';
                                  units = '';
                                  turnOff = true;
                                  break;
                                }
                              }

                              this.setState({
                                dinnerInsulinId: id,
                                dinnerInsulinName: name,
                                dinnerInsulinType: item.value,
                                dinnerCorrection: !turnOff,
                                dinnerDose: units,
                                recalculateNumbers: true,
                              });
                            }}
                            value={
                              this.state.dinnerInsulinType
                                ? optionsInsulin[
                                    this.getIndex(
                                      this.state.dinnerInsulinType,
                                      optionsInsulin,
                                      'value',
                                    )
                                  ]
                                : ''
                            }
                            placeholder={
                              this.state.basalInsulinId === '' ||
                              this.state.bolusInsulinId === '' ||
                              this.state.mixedInsulinId === ''
                                ? 'No insulin'
                                : 'Select'
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Select
                            isDisabled={!this.state.nighttimeActive}
                            className="react-select"
                            classNamePrefix="react-select"
                            options={optionsInsulin}
                            onChange={(item) => {
                              var id = '';
                              var name = '';
                              var units = this.state.nighttimeDose;
                              var turnOff = !this.state.nighttimeCorrection;

                              //figure out which one
                              switch (item.value) {
                                case 'basal': {
                                  id = this.state.basalInsulinId
                                    ? this.state.basalInsulinId
                                    : 'basal';
                                  name = this.state.basalInsulinName
                                    ? this.state.basalInsulinName
                                    : 'Basal';
                                  turnOff = true;
                                  break;
                                }
                                case 'bolus': {
                                  id = this.state.bolusInsulinId
                                    ? this.state.bolusInsulinId
                                    : 'bolus';
                                  name = this.state.bolusInsulinName
                                    ? this.state.bolusInsulinName
                                    : 'Bolus';
                                  break;
                                }
                                case 'mixed': {
                                  id = this.state.mixedInsulinId
                                    ? this.state.mixedInsulinId
                                    : 'mixed';
                                  name = this.state.mixedInsulinName
                                    ? this.state.mixedInsulinName
                                    : 'Mixed';
                                  turnOff = true;
                                  break;
                                }
                                default: //0
                                {
                                  id = '';
                                  name = '';
                                  units = '';
                                  turnOff = true;
                                  break;
                                }
                              }

                              this.setState({
                                nighttimeInsulinId: id,
                                nighttimeInsulinName: name,
                                nighttimeInsulinType: item.value,
                                nighttimeCorrection: !turnOff,
                                nighttimeDose: units,
                                recalculateNumbers: true,
                              });
                            }}
                            value={
                              this.state.nighttimeInsulinType
                                ? optionsInsulin[
                                    this.getIndex(
                                      this.state.nighttimeInsulinType,
                                      optionsInsulin,
                                      'value',
                                    )
                                  ]
                                : ''
                            }
                            placeholder={
                              this.state.basalInsulinId === '' ||
                              this.state.bolusInsulinId === '' ||
                              this.state.mixedInsulinId === ''
                                ? 'No insulin'
                                : 'Select'
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          paddingTop: 10,
                        }}
                      >
                        Units (Max 70)
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Input
                            pattern="[0-9]*"
                            disabled={
                              this.state.morningActive === false ||
                              this.state.morningInsulinId === '0' ||
                              this.state.morningInsulinId === ''
                            }
                            style={{ textAlign: 'center' }}
                            type="text"
                            maxLength="2"
                            onChange={(e) => {
                              //are we above 70?
                              //yes
                              if (
                                e.target.value.replace(/\D/, '').length > 1 &&
                                parseInt(e.target.value.replace(/\D/, '')) > 70
                              ) {
                                //max it at 70
                                this.setState({
                                  morningDose: '70',
                                  recalculateNumbers: true,
                                });
                              }
                              //are we above 70?
                              //no
                              else {
                                this.setState({
                                  morningDose: e.target.value.replace(/\D/, ''),
                                  recalculateNumbers: true,
                                });
                              }
                            }}
                            value={
                              this.state.morningDose !== null
                                ? this.state.morningDose
                                : ''
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Input
                            pattern="[0-9]*"
                            style={{ textAlign: 'center' }}
                            disabled={
                              this.state.breakfastActive === false ||
                              this.state.breakfastInsulinId === '0' ||
                              this.state.breakfastInsulinId === ''
                            }
                            type="text"
                            maxLength="2"
                            onChange={(e) => {
                              //are we above 70?
                              //yes
                              if (
                                e.target.value.replace(/\D/, '').length > 1 &&
                                parseInt(e.target.value.replace(/\D/, '')) > 70
                              ) {
                                //max it at 70
                                this.setState({
                                  breakfastDose: '70',
                                  recalculateNumbers: true,
                                });
                              }
                              //are we above 70?
                              //no
                              else {
                                this.setState({
                                  breakfastDose: e.target.value.replace(
                                    /\D/,
                                    '',
                                  ),
                                  recalculateNumbers: true,
                                });
                              }
                            }}
                            value={
                              this.state.breakfastDose !== null
                                ? this.state.breakfastDose
                                : ''
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Input
                            pattern="[0-9]*"
                            style={{ textAlign: 'center' }}
                            disabled={
                              this.state.lunchfastActive === false ||
                              this.state.lunchInsulinId === '0' ||
                              this.state.lunchInsulinId === ''
                            }
                            type="text"
                            maxLength="2"
                            onChange={(e) => {
                              //are we above 70?
                              //yes
                              if (
                                e.target.value.replace(/\D/, '').length > 1 &&
                                parseInt(e.target.value.replace(/\D/, '')) > 70
                              ) {
                                //max it at 70
                                this.setState({
                                  lunchDose: '70',
                                  recalculateNumbers: true,
                                });
                              }
                              //are we above 70?
                              //no
                              else {
                                this.setState({
                                  lunchDose: e.target.value.replace(/\D/, ''),
                                  recalculateNumbers: true,
                                });
                              }
                            }}
                            value={
                              this.state.lunchDose !== null
                                ? this.state.lunchDose
                                : ''
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Input
                            pattern="[0-9]*"
                            style={{ textAlign: 'center' }}
                            disabled={
                              this.state.dinnerActive === false ||
                              this.state.dinnerInsulinId === '0' ||
                              this.state.dinnerInsulinId === ''
                            }
                            type="text"
                            maxLength="2"
                            onChange={(e) => {
                              //are we above 70?
                              //yes
                              if (
                                e.target.value.replace(/\D/, '').length > 1 &&
                                parseInt(e.target.value.replace(/\D/, '')) > 70
                              ) {
                                //max it at 70
                                this.setState({
                                  dinnerDose: '70',
                                  recalculateNumbers: true,
                                });
                              }
                              //are we above 70?
                              //no
                              else {
                                this.setState({
                                  dinnerDose: e.target.value.replace(/\D/, ''),
                                  recalculateNumbers: true,
                                });
                              }
                            }}
                            value={
                              this.state.dinnerDose !== null
                                ? this.state.dinnerDose
                                : ''
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2}>
                        <FormGroup className={'has-label'}>
                          <Input
                            pattern="[0-9]*"
                            style={{ textAlign: 'center' }}
                            disabled={
                              this.state.nighttimeActive === false ||
                              this.state.nighttimeInsulinId === '0' ||
                              this.state.nighttimeInsulinId === ''
                            }
                            type="text"
                            maxLength="2"
                            onChange={(e) => {
                              //are we above 70?
                              //yes
                              if (
                                e.target.value.replace(/\D/, '').length > 1 &&
                                parseInt(e.target.value.replace(/\D/, '')) > 70
                              ) {
                                //max it at 70
                                this.setState({
                                  nighttimeDose: '70',
                                  recalculateNumbers: true,
                                });
                              }
                              //are we above 70?
                              //no
                              else {
                                this.setState({
                                  nighttimeDose: e.target.value.replace(
                                    /\D/,
                                    '',
                                  ),
                                  recalculateNumbers: true,
                                });
                              }
                            }}
                            value={
                              this.state.nighttimeDose !== null
                                ? this.state.nighttimeDose
                                : ''
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          paddingTop: 10,
                        }}
                      >
                        Correction
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={this.state.morningCorrection === true}
                            //we disable it when it is not bolus because we can only change bolus
                            disabled={
                              this.state.morningInsulinType !== 'bolus' ||
                              !this.state.morningActive
                            }
                            onChange={(e) => {
                              this.setState({
                                morningCorrection: e.target.checked,
                                morningTrackGlucose: e.target.checked
                                  ? true
                                  : this.state.morningTrackGlucose,
                                error: '',
                                recalculateNumbers: true,
                              });
                            }}
                            color="primary"
                            name="morningCorrection"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={this.state.breakfastCorrection === true}
                            //we disable it when it is not bolus because we can only change bolus
                            disabled={
                              this.state.breakfastInsulinType !== 'bolus' ||
                              !this.state.breakfastActive
                            }
                            onChange={(e) => {
                              this.setState({
                                breakfastCorrection: e.target.checked,
                                breakfastTrackGlucose: e.target.checked
                                  ? true
                                  : this.state.breakfastTrackGlucose,
                                error: '',
                                recalculateNumbers: true,
                              });
                            }}
                            color="primary"
                            name="breakfastCorrection"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={this.state.lunchCorrection === true}
                            //we disable it when it is not bolus because we can only change bolus
                            disabled={
                              this.state.lunchInsulinType !== 'bolus' ||
                              !this.state.lunchActive
                            }
                            onChange={(e) => {
                              this.setState({
                                lunchCorrection: e.target.checked,
                                lunchTrackGlucose: e.target.checked
                                  ? true
                                  : this.state.lunchTrackGlucose,
                                error: '',
                                recalculateNumbers: true,
                              });
                            }}
                            color="primary"
                            name="lunchCorrection"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={this.state.dinnerCorrection === true}
                            //we disable it when it is not bolus because we can only change bolus
                            disabled={
                              this.state.dinnerInsulinType !== 'bolus' ||
                              !this.state.dinnerActive
                            }
                            onChange={(e) => {
                              this.setState({
                                dinnerCorrection: e.target.checked,
                                dinnerTrackGlucose: e.target.checked
                                  ? true
                                  : this.state.dinnerTrackGlucose,
                                recalculateNumbers: true,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="dinnerCorrection"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={this.state.nighttimeCorrection === true}
                            //we disable it when it is not bolus because we can only change bolus
                            disabled={
                              this.state.nighttimeInsulinType !== 'bolus' ||
                              !this.state.nighttimeActive
                            }
                            onChange={(e) => {
                              this.setState({
                                nighttimeCorrection: e.target.checked,
                                nighttimeTrackGlucose: e.target.checked
                                  ? true
                                  : this.state.nighttimeTrackGlucose,
                                recalculateNumbers: true,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="nighttimeCorrection"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          paddingTop: 10,
                        }}
                      >
                        Blood Glucose
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={
                              this.state.morningTrackGlucose
                                ? this.state.morningTrackGlucose
                                : false
                            }
                            disabled={!this.state.morningActive}
                            onChange={(e) => {
                              this.setState({
                                morningTrackGlucose: e.target.checked,
                                morningCorrection: false,
                                recalculateNumbers: true,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="morningTrackGlucose"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={
                              this.state.breakfastTrackGlucose
                                ? this.state.breakfastTrackGlucose
                                : false
                            }
                            disabled={!this.state.breakfastActive}
                            onChange={(e) => {
                              this.setState({
                                breakfastTrackGlucose: e.target.checked,
                                breakfastCorrection: false,
                                recalculateNumbers: true,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="breakfastTrackGlucose"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={
                              this.state.lunchTrackGlucose
                                ? this.state.lunchTrackGlucose
                                : false
                            }
                            disabled={!this.state.lunchActive}
                            onChange={(e) => {
                              this.setState({
                                lunchTrackGlucose: e.target.checked,
                                lunchCorrection: false,
                                recalculateNumbers: true,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="lunchTrackGlucose"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={
                              this.state.dinnerTrackGlucose
                                ? this.state.dinnerTrackGlucose
                                : false
                            }
                            disabled={!this.state.dinnerActive}
                            onChange={(e) => {
                              this.setState({
                                dinnerTrackGlucose: e.target.checked,
                                dinnerCorrection: false,
                                recalculateNumbers: true,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="dinnerTrackGlucose"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Switch
                            disabled={!this.state.nighttimeActive}
                            checked={
                              this.state.nighttimeTrackGlucose
                                ? this.state.nighttimeTrackGlucose
                                : false
                            }
                            onChange={(e) => {
                              this.setState({
                                nighttimeTrackGlucose: e.target.checked,
                                nighttimeCorrection: false,
                                recalculateNumbers: true,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="nighttimeTrackGlucose"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          paddingTop: 10,
                        }}
                      >
                        Active
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={
                              this.state.morningActive
                                ? this.state.morningActive
                                : false
                            }
                            onChange={(e) => {
                              this.setState({
                                morningActive: e.target.checked,
                                morningDose: e.target.checked
                                  ? this.state.morningDose
                                  : '',
                                morningTrackGlucose: e.target.checked
                                  ? this.state.morningTrackGlucose
                                  : false,
                                recalculateNumbers: true,
                                morningInsulinId: e.target.checked
                                  ? this.state.morningInsulinId
                                  : '',
                                morningInsulinName: e.target.checked
                                  ? this.state.morningInsulinName
                                  : '',
                                morningInsulinType: e.target.checked
                                  ? this.state.morningInsulinType
                                  : '',
                                morningCorrection: e.target.checked
                                  ? this.state.morningCorrection
                                  : false,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="morningActive"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={
                              this.state.breakfastActive
                                ? this.state.breakfastActive
                                : false
                            }
                            onChange={(e) => {
                              this.setState({
                                breakfastActive: e.target.checked,
                                breakfastDose: e.target.checked
                                  ? this.state.breakfastDose
                                  : '',
                                breakfastTrackGlucose: e.target.checked
                                  ? this.state.breakfastTrackGlucose
                                  : false,
                                recalculateNumbers: true,
                                breakfastInsulinId: e.target.checked
                                  ? this.state.breakfastInsulinId
                                  : '',
                                breakfastInsulinName: e.target.checked
                                  ? this.state.breakfastInsulinName
                                  : '',
                                breakfastInsulinType: e.target.checked
                                  ? this.state.breakfastInsulinType
                                  : '',
                                breakfastCorrection: e.target.checked
                                  ? this.state.breakfastCorrection
                                  : false,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="breakfastActive"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={
                              this.state.lunchActive
                                ? this.state.lunchActive
                                : false
                            }
                            onChange={(e) => {
                              this.setState({
                                lunchActive: e.target.checked,
                                lunchDose: e.target.checked
                                  ? this.state.lunchDose
                                  : '',
                                lunchTrackGlucose: e.target.checked
                                  ? this.state.lunchTrackGlucose
                                  : false,
                                recalculateNumbers: true,
                                lunchInsulinId: e.target.checked
                                  ? this.state.lunchInsulinId
                                  : '',
                                lunchInsulinName: e.target.checked
                                  ? this.state.lunchInsulinName
                                  : '',
                                lunchInsulinType: e.target.checked
                                  ? this.state.lunchInsulinType
                                  : '',
                                lunchCorrection: e.target.checked
                                  ? this.state.lunchCorrection
                                  : false,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="lunchActive"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={
                              this.state.dinnerActive
                                ? this.state.dinnerActive
                                : false
                            }
                            onChange={(e) => {
                              this.setState({
                                dinnerActive: e.target.checked,
                                dinnerDose: e.target.checked
                                  ? this.state.dinnerDose
                                  : '',
                                dinnerTrackGlucose: e.target.checked
                                  ? this.state.dinnerTrackGlucose
                                  : false,
                                recalculateNumbers: true,
                                dinnerInsulinId: e.target.checked
                                  ? this.state.dinnerInsulinId
                                  : '',
                                dinnerInsulinName: e.target.checked
                                  ? this.state.dinnerInsulinName
                                  : '',
                                dinnerInsulinType: e.target.checked
                                  ? this.state.dinnerInsulinType
                                  : '',
                                dinnerCorrection: e.target.checked
                                  ? this.state.dinnerCorrection
                                  : false,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="dinnerActive"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Switch
                            checked={
                              this.state.nighttimeActive
                                ? this.state.nighttimeActive
                                : false
                            }
                            onChange={(e) => {
                              this.setState({
                                nighttimeActive: e.target.checked,
                                nighttimeDose: e.target.checked
                                  ? this.state.nighttimeDose
                                  : '',
                                nighttimeTrackGlucose: e.target.checked
                                  ? this.state.nighttimeTrackGlucose
                                  : false,
                                recalculateNumbers: true,
                                nighttimeInsulinId: e.target.checked
                                  ? this.state.nighttimeInsulinId
                                  : '',
                                nighttimeInsulinName: e.target.checked
                                  ? this.state.nighttimeInsulinName
                                  : '',
                                nighttimeInsulinType: e.target.checked
                                  ? this.state.nighttimeInsulinType
                                  : '',
                                nighttimeCorrection: e.target.checked
                                  ? this.state.nighttimeCorrection
                                  : false,
                                error: '',
                              });
                            }}
                            color="primary"
                            name="nighttimeActive"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          paddingTop: '15px',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        Window Times
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          paddingTop: '10px',
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            onChange={(e) =>
                              this.setState({
                                morningTimeOfDayStart: e.target.value
                                  .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                  .toUpperCase(),
                                error: '',
                                recalculateNumbers: true,
                              })
                            }
                            autoCorrect="true"
                            value={this.state.morningTimeOfDayStart.toUpperCase()}
                          />
                          <Label>to</Label>
                          <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            onChange={(e) =>
                              this.setState({
                                morningTimeOfDayEnd: e.target.value
                                  .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                  .toUpperCase(),
                                error: '',
                                recalculateNumbers: true,
                              })
                            }
                            autoCorrect="true"
                            value={this.state.morningTimeOfDayEnd.toUpperCase()}
                          />
                          <Label
                            style={{
                              color: 'red',
                              display:
                                this.state.morningError === '' ? 'none' : '',
                            }}
                          >
                            {this.state.morningError}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          paddingTop: '10px',
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            onChange={(e) =>
                              this.setState({
                                breakfastTimeOfDayStart: e.target.value
                                  .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                  .toUpperCase(),
                                error: '',
                                recalculateNumbers: true,
                              })
                            }
                            autoCorrect="true"
                            value={this.state.breakfastTimeOfDayStart.toUpperCase()}
                          />
                          <Label>to</Label>
                          <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            onChange={(e) =>
                              this.setState({
                                breakfastTimeOfDayEnd: e.target.value
                                  .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                  .toUpperCase(),
                                error: '',
                                recalculateNumbers: true,
                              })
                            }
                            autoCorrect="true"
                            value={this.state.breakfastTimeOfDayEnd.toUpperCase()}
                          />
                          <Label
                            style={{
                              color: 'red',
                              display:
                                this.state.breakfastError === '' ? 'none' : '',
                            }}
                          >
                            {this.state.breakfastError}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          paddingTop: '10px',
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            onChange={(e) =>
                              this.setState({
                                lunchTimeOfDayStart: e.target.value
                                  .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                  .toUpperCase(),
                                error: '',
                                recalculateNumbers: true,
                              })
                            }
                            autoCorrect="true"
                            value={this.state.lunchTimeOfDayStart.toUpperCase()}
                          />
                          <Label>to</Label>
                          <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            onChange={(e) =>
                              this.setState({
                                lunchTimeOfDayEnd: e.target.value
                                  .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                  .toUpperCase(),
                                error: '',
                                recalculateNumbers: true,
                              })
                            }
                            autoCorrect="true"
                            value={this.state.lunchTimeOfDayEnd.toUpperCase()}
                          />
                          <Label
                            style={{
                              color: 'red',
                              display:
                                this.state.lunchError === '' ? 'none' : '',
                            }}
                          >
                            {this.state.lunchError}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          paddingTop: '10px',
                          textAlign: 'center',
                          borderRight: '1px solid #D3D3D3',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            onChange={(e) =>
                              this.setState({
                                dinnerTimeOfDayStart: e.target.value
                                  .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                  .toUpperCase(),
                                error: '',
                                recalculateNumbers: true,
                              })
                            }
                            autoCorrect="true"
                            value={this.state.dinnerTimeOfDayStart.toUpperCase()}
                          />
                          <Label>to</Label>
                          <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            onChange={(e) =>
                              this.setState({
                                dinnerTimeOfDayEnd: e.target.value
                                  .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                  .toUpperCase(),
                                error: '',
                                recalculateNumbers: true,
                              })
                            }
                            autoCorrect="true"
                            value={this.state.dinnerTimeOfDayEnd.toUpperCase()}
                          />
                          <Label
                            style={{
                              color: 'red',
                              display:
                                this.state.dinnerError === '' ? 'none' : '',
                            }}
                          >
                            {this.state.dinnerError}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{ paddingTop: '10px', textAlign: 'center' }}
                      >
                        <FormGroup className={'has-label'}>
                          <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            onChange={(e) =>
                              this.setState({
                                //just times
                                nighttimeTimeOfDayStart: e.target.value
                                  .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                  .toUpperCase(),
                                error: '',
                                recalculateNumbers: true,
                              })
                            }
                            autoCorrect="true"
                            value={this.state.nighttimeTimeOfDayStart.toUpperCase()}
                          />
                          <Label>to</Label>
                          <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            onChange={(e) =>
                              this.setState({
                                //just times
                                nighttimeTimeOfDayEnd: e.target.value
                                  .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                  .toUpperCase(),
                                error: '',
                                recalculateNumbers: true,
                              })
                            }
                            autoCorrect="true"
                            value={this.state.nighttimeTimeOfDayEnd.toUpperCase()}
                          />
                          <Label
                            style={{
                              color: 'red',
                              display:
                                this.state.nighttimeError === '' ? 'none' : '',
                            }}
                          >
                            {this.state.nighttimeError}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} style={{ paddingTop: 20 }}>
                        <FormGroup className={'has-label'}>
                          <h6>Blood Glucose Levels</h6>
                          <Label>
                            Please enter the following blood glucose levels.
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={2}>
                        <FormGroup
                          className={
                            'has-label ' + this.state.glucoseLevelHypoState
                          }
                        >
                          <Label>Hypo</Label>
                          <Input
                            pattern="[0-9]*"
                            type="text"
                            maxLength={3}
                            onChange={(e) => {
                              this.setState({
                                error: '',
                                bgerror: this.checkValues(
                                  e.target.value.replace(/\D/, ''),
                                  this.state.glucoseLevelGoal,
                                  this.state.glucoseLevelHigh,
                                  this.state.glucoseLevelDanger,
                                  this.state.glucoseLevelImpossible,
                                ),
                                glucoseLevelHypo: e.target.value.replace(
                                  /\D/,
                                  '',
                                ),
                                glucoseLevelHypoState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                                recalculateNumbers: true,
                              });
                            }}
                            value={this.state.glucoseLevelHypo}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2}>
                        <FormGroup
                          className={
                            'has-label ' + this.state.glucoseLevelGoalState
                          }
                        >
                          <Label>Goal</Label>
                          <Input
                            pattern="[0-9]*"
                            type="text"
                            maxLength={3}
                            onChange={(e) => {
                              this.setState({
                                error: '',
                                bgerror: this.checkValues(
                                  this.state.glucoseLevelHypo,
                                  e.target.value.replace(/\D/, ''),
                                  this.state.glucoseLevelHigh,
                                  this.state.glucoseLevelDanger,
                                  this.state.glucoseLevelImpossible,
                                ),
                                glucoseLevelGoal: e.target.value.replace(
                                  /\D/,
                                  '',
                                ),
                                glucoseLevelGoalState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                              });
                            }}
                            value={this.state.glucoseLevelGoal}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2}>
                        <FormGroup
                          className={
                            'has-label ' + this.state.glucoseLevelHighState
                          }
                        >
                          <Label>High Level</Label>
                          <Input
                            pattern="[0-9]*"
                            type="text"
                            maxLength={3}
                            onChange={(e) => {
                              this.setState({
                                error: '',
                                bgerror: this.checkValues(
                                  this.state.glucoseLevelHypo,
                                  this.state.glucoseLevelGoal,
                                  e.target.value.replace(/\D/, ''),
                                  this.state.glucoseLevelDanger,
                                  this.state.glucoseLevelImpossible,
                                ),
                                glucoseLevelHigh: e.target.value.replace(
                                  /\D/,
                                  '',
                                ),
                                glucoseLevelHighState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                              });
                            }}
                            value={this.state.glucoseLevelHigh}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2}>
                        <FormGroup
                          className={
                            'has-label ' + this.state.glucoseLevelDangerState
                          }
                        >
                          <Label>Danger Level</Label>
                          <Input
                            pattern="[0-9]*"
                            type="text"
                            maxLength={3}
                            onChange={(e) => {
                              this.setState({
                                error: '',
                                bgerror: this.checkValues(
                                  this.state.glucoseLevelHypo,
                                  this.state.glucoseLevelGoal,
                                  this.state.glucoseLevelHigh,
                                  e.target.value.replace(/\D/, ''),
                                  this.state.glucoseLevelImpossible,
                                ),
                                glucoseLevelDanger: e.target.value.replace(
                                  /\D/,
                                  '',
                                ),
                                glucoseLevelDangerState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                              });
                            }}
                            value={this.state.glucoseLevelDanger}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2}>
                        <FormGroup
                          className={
                            'has-label ' +
                            this.state.glucoseLevelImpossibleState
                          }
                        >
                          <Label>Impossible Level</Label>
                          <Input
                            pattern="[0-9]*"
                            type="text"
                            maxLength={3}
                            onChange={(e) => {
                              this.setState({
                                error: '',
                                glucoseLevelImpossible: e.target.value.replace(
                                  /\D/,
                                  '',
                                ),
                                glucoseLevelImpossibleState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                                bgerror: this.checkValues(
                                  this.state.glucoseLevelHypo,
                                  this.state.glucoseLevelGoal,
                                  this.state.glucoseLevelHigh,
                                  this.state.glucoseLevelDanger,
                                  e.target.value.replace(/\D/, ''),
                                ),
                              });
                            }}
                            value={this.state.glucoseLevelImpossible}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ display: this.state.bgerror ? '' : '' }}>
                      <Col xs={12} md={12}>
                        <FormGroup
                          style={{ display: this.state.bgerror ? '' : '' }}
                        >
                          <Label
                            style={{
                              fontSize: 14,
                              color: 'tomato',
                              paddingRight: 15,
                              display: this.state.bgerror ? '' : 'none',
                            }}
                          >
                            {this.state.bgerror}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} style={{ paddingTop: 20 }}>
                        <FormGroup className={'has-label'}>
                          <h6>Insulin Options</h6>
                          <Label>
                            Please select the following insulin options.
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={3}>
                        <FormGroup className={'has-label'}>
                          <Label>Basal</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            options={this.state.insulinData.filter(
                              (o) => o.insulinType === 'Basal',
                            )}
                            onChange={(item) => {
                              //set the name in the top one
                              optionsInsulin[1].label = item.tradeName
                                ? item.tradeName
                                : item.genericName;

                              this.setState({
                                error: '',
                                basalInsulinId: item.value,
                                basalInsulinName: item.tradeName
                                  ? item.tradeName
                                  : item.genericName,
                                basalInsulinIndex: this.state.insulinData.filter(
                                  (o) => o.value === item.value,
                                )[0].index,

                                morningInsulinId:
                                  this.state.morningInsulinType === 'basal'
                                    ? item.value
                                    : this.state.morningInsulinId,
                                morningInsulinName:
                                  this.state.morningInsulinType === 'basal'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.morningInsulinName,
                                breakfastInsulinId:
                                  this.state.breakfastInsulinType === 'basal'
                                    ? item.value
                                    : this.state.breakfastInsulinId,
                                breakfastInsulinName:
                                  this.state.breakfastInsulinType === 'basal'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.breakfastInsulinName,
                                lunchInsulinId:
                                  this.state.lunchInsulinType === 'basal'
                                    ? item.value
                                    : this.state.lunchInsulinId,
                                lunchInsulinName:
                                  this.state.lunchInsulinType === 'basal'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.lunchInsulinName,
                                dinnerInsulinId:
                                  this.state.dinnerInsulinType === 'basal'
                                    ? item.value
                                    : this.state.dinnerInsulinId,
                                dinnerInsulinName:
                                  this.state.dinnerInsulinType === 'basal'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.dinnerInsulinName,
                                nighttimeInsulinId:
                                  this.state.nighttimeInsulinType === 'basal'
                                    ? item.value
                                    : this.state.nighttimeInsulinId,
                                nighttimeInsulinName:
                                  this.state.nighttimeInsulinType === 'basal'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.nighttimeInsulinName,
                              });
                            }}
                            value={
                              this.state.basalInsulinIndex >= 0
                                ? this.state.insulinData[
                                    this.state.basalInsulinIndex
                                  ]
                                : ''
                            }
                            placeholder="Select a basal"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup className={'has-label'}>
                          <Label>Bolus</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            options={this.state.insulinData.filter(
                              (o) => o.insulinType === 'Bolus',
                            )}
                            onChange={(item) => {
                              //set the name in the top one
                              optionsInsulin[2].label = item.tradeName
                                ? item.tradeName
                                : item.genericName;

                              optionsAddUnits[0].label =
                                '1 unit of ' +
                                (item.tradeName
                                  ? item.tradeName
                                  : item.genericName);
                              optionsAddUnits[1].label =
                                '2 units of ' +
                                (item.tradeName
                                  ? item.tradeName
                                  : item.genericName);
                              optionsAddUnits[2].label =
                                '3 units of ' +
                                (item.tradeName
                                  ? item.tradeName
                                  : item.genericName);

                              this.setState({
                                error: '',
                                bolusInsulinId: item.value,
                                bolusInsulinName: item.tradeName
                                  ? item.tradeName
                                  : item.genericName,
                                bolusInsulinIndex: this.state.insulinData.filter(
                                  (o) => o.value === item.value,
                                )[0].index,
                                morningInsulinId:
                                  this.state.morningInsulinType === 'bolus'
                                    ? item.value
                                    : this.state.morningInsulinId,
                                morningInsulinName:
                                  this.state.morningInsulinType === 'bolus'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.morningInsulinName,
                                breakfastInsulinId:
                                  this.state.breakfastInsulinType === 'bolus'
                                    ? item.value
                                    : this.state.breakfastInsulinId,
                                breakfastInsulinName:
                                  this.state.breakfastInsulinType === 'bolus'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.breakfastInsulinName,
                                lunchInsulinId:
                                  this.state.lunchInsulinType === 'bolus'
                                    ? item.value
                                    : this.state.lunchInsulinId,
                                lunchInsulinName:
                                  this.state.lunchInsulinType === 'bolus'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.lunchInsulinName,
                                dinnerInsulinId:
                                  this.state.dinnerInsulinType === 'bolus'
                                    ? item.value
                                    : this.state.dinnerInsulinId,
                                dinnerInsulinName:
                                  this.state.dinnerInsulinType === 'bolus'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.dinnerInsulinName,
                                nighttimeInsulinId:
                                  this.state.nighttimeInsulinType === 'bolus'
                                    ? item.value
                                    : this.state.nighttimeInsulinId,
                                nighttimeInsulinName:
                                  this.state.nighttimeInsulinType === 'bolus'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.nighttimeInsulinName,
                              });
                            }}
                            value={
                              this.state.bolusInsulinIndex >= 0
                                ? this.state.insulinData[
                                    this.state.bolusInsulinIndex
                                  ]
                                : ''
                            }
                            placeholder="Select a bolus"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup className={'has-label'}>
                          <Label>Mixed</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            options={this.state.insulinData.filter(
                              (o) => o.insulinType === 'Mixed',
                            )}
                            onChange={(item) => {
                              //set the name in the top one
                              optionsInsulin[3].label = item.tradeName
                                ? item.tradeName
                                : item.genericName;

                              this.setState({
                                error: '',
                                mixedInsulinId: item.value,
                                mixedInsulinName: item.tradeName
                                  ? item.tradeName
                                  : item.genericName,
                                mixedInsulinIndex: this.state.insulinData.filter(
                                  (o) => o.value === item.value,
                                )[0].index,
                                morningInsulinId:
                                  this.state.morningInsulinType === 'mixed'
                                    ? item.value
                                    : this.state.morningInsulinId,
                                morningInsulinName:
                                  this.state.morningInsulinType === 'mixed'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.morningInsulinName,
                                breakfastInsulinId:
                                  this.state.breakfastInsulinType === 'mixed'
                                    ? item.value
                                    : this.state.breakfastInsulinId,
                                breakfastInsulinName:
                                  this.state.breakfastInsulinType === 'mixed'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.breakfastInsulinName,
                                lunchInsulinId:
                                  this.state.lunchInsulinType === 'mixed'
                                    ? item.value
                                    : this.state.lunchInsulinId,
                                lunchInsulinName:
                                  this.state.lunchInsulinType === 'mixed'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.lunchInsulinName,
                                dinnerInsulinId:
                                  this.state.dinnerInsulinType === 'mixed'
                                    ? item.value
                                    : this.state.dinnerInsulinId,
                                dinnerInsulinName:
                                  this.state.dinnerInsulinType === 'mixed'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.dinnerInsulinName,
                                nighttimeInsulinId:
                                  this.state.nighttimeInsulinType === 'mixed'
                                    ? item.value
                                    : this.state.nighttimeInsulinId,
                                nighttimeInsulinName:
                                  this.state.nighttimeInsulinType === 'mixed'
                                    ? item.tradeName
                                      ? item.tradeName
                                      : item.genericName
                                    : this.state.nighttimeInsulinName,
                              });
                            }}
                            value={
                              this.state.mixedInsulinIndex >= 0
                                ? this.state.insulinData[
                                    this.state.mixedInsulinIndex
                                  ]
                                : ''
                            }
                            placeholder="Select a mixed"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 40 }}>
                      <Col xs={12} md={2}>
                        <FormGroup className={'has-label'}>
                          <Label>Starting now</Label>
                          <Input
                            disabled={true}
                            type="text"
                            maxLength="10"
                            ref={(input) => {
                              this.dateStartInput = input;
                            }}
                            value={this.state.dateStart}
                            onChange={(e) => {
                              this.setState({
                                dateStart: e.target.value,
                                error: '',
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2}>
                        <FormGroup className={'has-label'}>
                          <Label>For every __ mg/dL *</Label>
                          <Input
                            type="text"
                            maxLength="3"
                            value={this.state.correctionMGEvery}
                            onChange={(e) => {
                              this.setState({
                                correctionMGEvery: e.target.value.replace(
                                  /\D/,
                                  '',
                                ),
                                recalculateNumbers: true,
                                error: '',
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2}>
                        <FormGroup className={'has-label'}>
                          <Label>Above mg/dL *</Label>
                          <Input
                            type="text"
                            maxLength="3"
                            value={this.state.correctionMGBaseline}
                            onChange={(e) => {
                              this.setState({
                                correctionMGBaseline: e.target.value.replace(
                                  /\D/,
                                  '',
                                ),
                                recalculateNumbers: true,
                                error: '',
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup className={'has-label'}>
                          <Label>Add X Units *</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            options={optionsAddUnits}
                            onChange={(item) => {
                              this.setState({
                                correctionAddUnits: parseInt(item.value),
                                correctionAddUnitsIndex: item.index,
                                recalculateNumbers: true,
                              });
                            }}
                            value={
                              optionsAddUnits[
                                this.state.correctionAddUnitsIndex
                              ]
                            }
                            placeholder="Select"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} style={{ paddingTop: 20 }}>
                        <FormGroup className={'has-label'}>
                          <Label>Notes</Label>
                          <Input
                            type="textarea"
                            maxLength="5000"
                            style={{
                              border: '1px solid #dadada',
                              minHeight: 100,
                            }}
                            ref={(input) => {
                              this.notesInput = input;
                            }}
                            onChange={(e) => {
                              this.setState({
                                notes: e.target.value,
                              });
                            }}
                            autoCorrect="true"
                            value={this.state.notes}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            </Col>
            <Col xs={12} md={5}>
              <Form>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">&nbsp;</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>Dose Card</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>Morning</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>Breakfast</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>Lunch</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>Dinner</Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Label>Nighttime</Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>Window</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label style={{ width: '100%' }}>
                            {this.state.morningTimeOfDayStart.toUpperCase()}
                          </Label>
                          <Label style={{ width: '100%' }}>
                            {this.state.morningTimeOfDayEnd.toUpperCase()}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label style={{ width: '100%' }}>
                            {this.state.breakfastTimeOfDayStart.toUpperCase()}
                          </Label>
                          <Label style={{ width: '100%' }}>
                            {this.state.breakfastTimeOfDayEnd.toUpperCase()}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label style={{ width: '100%' }}>
                            {this.state.lunchTimeOfDayStart.toUpperCase()}
                          </Label>
                          <Label style={{ width: '100%' }}>
                            {this.state.lunchTimeOfDayEnd.toUpperCase()}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label style={{ width: '100%' }}>
                            {this.state.dinnerTimeOfDayStart.toUpperCase()}
                          </Label>
                          <Label style={{ width: '100%' }}>
                            {this.state.dinnerTimeOfDayEnd.toUpperCase()}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Label style={{ width: '100%' }}>
                            {this.state.nighttimeTimeOfDayStart.toUpperCase()}
                          </Label>
                          <Label style={{ width: '100%' }}>
                            {this.state.nighttimeTimeOfDayEnd.toUpperCase()}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>Prescription</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.morningInsulinId === '0' ||
                            this.state.morningInsulinId === ''
                              ? 'None'
                              : this.state.morningInsulinName}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.breakfastInsulinId === '0' ||
                            this.state.breakfastInsulinId === ''
                              ? 'None'
                              : this.state.breakfastInsulinName}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.lunchInsulinId === '0' ||
                            this.state.lunchInsulinId === ''
                              ? 'None'
                              : this.state.lunchInsulinName}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.dinnerInsulinId === '0' ||
                            this.state.dinnerInsulinId === ''
                              ? 'None'
                              : this.state.dinnerInsulinName}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.nighttimeInsulinId === '0' ||
                            this.state.nighttimeInsulinId === ''
                              ? 'None'
                              : this.state.nighttimeInsulinName}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>Correctional</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.morningCorrection
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.morningCorrection ? 'YES' : 'NO'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.breakfastCorrection
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.breakfastCorrection ? 'YES' : 'NO'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.lunchCorrection
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.lunchCorrection ? 'YES' : 'NO'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.dinnerCorrection
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.dinnerCorrection ? 'YES' : 'NO'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.nighttimeCorrection
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.nighttimeCorrection ? 'YES' : 'NO'}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>Track Glucose</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.morningTrackGlucose
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.morningTrackGlucose ? 'YES' : 'NO'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.breakfastTrackGlucose
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.breakfastTrackGlucose ? 'YES' : 'NO'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.lunchTrackGlucose
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.lunchTrackGlucose ? 'YES' : 'NO'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.dinnerTrackGlucose
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.dinnerTrackGlucose ? 'YES' : 'NO'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.nighttimeTrackGlucose
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.nighttimeTrackGlucose ? 'YES' : 'NO'}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display:
                          this.state.morning_0Level >=
                          this.state.glucoseLevelImpossible
                            ? 'none'
                            : '',
                      }}
                    >
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>{'<= ' + this.state.morning_0Level}</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.morningActive === true &&
                            this.state.morningInsulinId !== '0' &&
                            this.state.morningInsulinId !== ''
                              ? 'HYPO'
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.breakfastActive === true &&
                            this.state.breakfastInsulinId !== '0' &&
                            this.state.breakfastInsulinId !== ''
                              ? 'HYPO'
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.lunchActive === true &&
                            this.state.lunchInsulinId !== '0' &&
                            this.state.lunchInsulinId !== ''
                              ? 'HYPO'
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.dinnerActive === true &&
                            this.state.dinnerInsulinId !== '0' &&
                            this.state.dinnerInsulinId !== ''
                              ? 'HYPO'
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.nighttimeActive === true &&
                            this.state.nighttimeInsulinId !== '0' &&
                            this.state.nighttimeInsulinId !== ''
                              ? 'HYPO'
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display:
                          this.state.morning_1Level >=
                          this.state.glucoseLevelImpossible
                            ? 'none'
                            : '',
                      }}
                    >
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {wentOver === '1' ||
                            this.state.morning_0Level ===
                              this.state.morning_1Level - 1
                              ? this.state.morning_0Level + 1 + '+'
                              : this.state.morning_0Level +
                                1 +
                                ' - ' +
                                this.state.morning_1Level}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.morning_1 >= 0 &&
                            this.state.morningInsulinId !== '0' &&
                            this.state.morningInsulinId !== ''
                              ? this.state.morning_1
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.breakfast_1 >= 0 &&
                            this.state.breakfastInsulinId !== '0' &&
                            this.state.breakfastInsulinId !== ''
                              ? this.state.breakfast_1
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.lunch_1 >= 0 &&
                            this.state.lunchInsulinId !== '0' &&
                            this.state.lunchInsulinId !== ''
                              ? this.state.lunch_1
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.dinner_1 >= 0 &&
                            this.state.dinnerInsulinId !== '0' &&
                            this.state.dinnerInsulinId !== ''
                              ? this.state.dinner_1
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.nighttime_1 >= 0 &&
                            this.state.nighttimeInsulinId !== '0' &&
                            this.state.nighttimeInsulinId !== ''
                              ? this.state.nighttime_1
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display:
                          this.state.morning_2Level >=
                          this.state.glucoseLevelImpossible
                            ? 'none'
                            : '',
                      }}
                    >
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {wentOver === '2' ||
                            this.state.morning_1Level ===
                              this.state.morning_2Level - 1
                              ? this.state.morning_1Level + 1 + '+'
                              : this.state.morning_1Level +
                                1 +
                                ' - ' +
                                this.state.morning_2Level}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.morning_2 >= 0 &&
                            this.state.morningInsulinId !== '0' &&
                            this.state.morningInsulinId !== ''
                              ? this.state.morning_2
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.breakfast_2 >= 0 &&
                            this.state.breakfastInsulinId !== '0' &&
                            this.state.breakfastInsulinId !== ''
                              ? this.state.breakfast_2
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.lunch_2 >= 0 &&
                            this.state.lunchInsulinId !== '0' &&
                            this.state.lunchInsulinId !== ''
                              ? this.state.lunch_2
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.dinner_2 >= 0 &&
                            this.state.dinnerInsulinId !== '0' &&
                            this.state.dinnerInsulinId !== ''
                              ? this.state.dinner_2
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.nighttime_2 >= 0 &&
                            this.state.nighttimeInsulinId !== '0' &&
                            this.state.nighttimeInsulinId !== ''
                              ? this.state.nighttime_2
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display:
                          this.state.morning_3Level >=
                          this.state.glucoseLevelImpossible
                            ? 'none'
                            : '',
                      }}
                    >
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {wentOver === '3' ||
                            this.state.morning_2Level ===
                              this.state.morning_3Level - 1
                              ? this.state.morning_2Level + 1 + '+'
                              : this.state.morning_2Level +
                                1 +
                                ' - ' +
                                this.state.morning_3Level}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.morning_3 >= 0 &&
                            this.state.morningInsulinId !== '0' &&
                            this.state.morningInsulinId !== ''
                              ? this.state.morning_3
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.breakfast_3 >= 0 &&
                            this.state.breakfastInsulinId !== '0' &&
                            this.state.breakfastInsulinId !== ''
                              ? this.state.breakfast_3
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.lunch_3 >= 0 &&
                            this.state.lunchInsulinId !== '0' &&
                            this.state.lunchInsulinId !== ''
                              ? this.state.lunch_3
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.dinner_3 >= 0 &&
                            this.state.dinnerInsulinId !== '0' &&
                            this.state.dinnerInsulinId !== ''
                              ? this.state.dinner_3
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.nighttime_3 >= 0 &&
                            this.state.nighttimeInsulinId !== '0' &&
                            this.state.nighttimeInsulinId !== ''
                              ? this.state.nighttime_3
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display:
                          this.state.morning_4Level >=
                          this.state.glucoseLevelImpossible
                            ? 'none'
                            : '',
                      }}
                    >
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {wentOver === '4' ||
                            this.state.morning_3Level ===
                              this.state.morning_4Level - 1
                              ? this.state.morning_3Level + 1 + '+'
                              : this.state.morning_3Level +
                                1 +
                                ' - ' +
                                this.state.morning_4Level}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.morning_4 >= 0 &&
                            this.state.morningInsulinId !== '0' &&
                            this.state.morningInsulinId !== ''
                              ? this.state.morning_4
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.breakfast_4 >= 0 &&
                            this.state.breakfastInsulinId !== '0' &&
                            this.state.breakfastInsulinId !== ''
                              ? this.state.breakfast_4
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.lunch_4 >= 0 &&
                            this.state.lunchInsulinId !== '0' &&
                            this.state.lunchInsulinId !== ''
                              ? this.state.lunch_4
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.dinner_4 >= 0 &&
                            this.state.dinnerInsulinId !== '0' &&
                            this.state.dinnerInsulinId !== ''
                              ? this.state.dinner_4
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.nighttime_4 >= 0 &&
                            this.state.nighttimeInsulinId !== '0' &&
                            this.state.nighttimeInsulinId !== ''
                              ? this.state.nighttime_4
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display:
                          this.state.morning_5Level >=
                          this.state.glucoseLevelImpossible
                            ? 'none'
                            : '',
                      }}
                    >
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {wentOver === '5' ||
                            this.state.morning_4Level ===
                              this.state.morning_5Level - 1
                              ? this.state.morning_4Level + 1 + '+'
                              : this.state.morning_4Level +
                                1 +
                                ' - ' +
                                this.state.morning_5Level}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.morning_5 >= 0 &&
                            this.state.morningInsulinId !== '0' &&
                            this.state.morningInsulinId !== ''
                              ? this.state.morning_5
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.breakfast_5 >= 0 &&
                            this.state.breakfastInsulinId !== '0' &&
                            this.state.breakfastInsulinId !== ''
                              ? this.state.breakfast_5
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.lunch_5 >= 0 &&
                            this.state.lunchInsulinId !== '0' &&
                            this.state.lunchInsulinId !== ''
                              ? this.state.lunch_5
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.dinner_5 >= 0 &&
                            this.state.dinnerInsulinId !== '0' &&
                            this.state.dinnerInsulinId !== ''
                              ? this.state.dinner_5
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.nighttime_5 >= 0 &&
                            this.state.nighttimeInsulinId !== '0' &&
                            this.state.nighttimeInsulinId !== ''
                              ? this.state.nighttime_5
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display:
                          this.state.morning_6Level >=
                          this.state.glucoseLevelImpossible
                            ? 'none'
                            : '',
                      }}
                    >
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {wentOver === '6' ||
                            this.state.morning_5Level ===
                              this.state.morning_6Level - 1
                              ? this.state.morning_5Level + 1 + '+'
                              : this.state.morning_5Level +
                                1 +
                                ' - ' +
                                this.state.morning_6Level}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.morning_6 >= 0 &&
                            this.state.morningInsulinId !== '0' &&
                            this.state.morningInsulinId !== ''
                              ? this.state.morning_6
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.breakfast_6 >= 0 &&
                            this.state.breakfastInsulinId !== '0' &&
                            this.state.breakfastInsulinId !== ''
                              ? this.state.breakfast_6
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.lunch_6 >= 0 &&
                            this.state.lunchInsulinId !== '0' &&
                            this.state.lunchInsulinId !== ''
                              ? this.state.lunch_6
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.dinner_6 >= 0 &&
                            this.state.dinnerInsulinId !== '0' &&
                            this.state.dinnerInsulinId !== ''
                              ? this.state.dinner_6
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} style={{ textAlign: 'center' }}>
                        <FormGroup className={'has-label'}>
                          <Label>
                            {this.state.nighttime_6 >= 0 &&
                            this.state.nighttimeInsulinId !== '0' &&
                            this.state.nighttimeInsulinId !== ''
                              ? this.state.nighttime_6
                              : '---'}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label>{'Active'}</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.morningActive ? 'green' : 'red',
                            }}
                          >
                            {this.state.morningActive ? 'ON' : 'OFF'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.breakfastActive
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.breakfastActive ? 'ON' : 'OFF'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.lunchActive ? 'green' : 'red',
                            }}
                          >
                            {this.state.lunchActive ? 'ON' : 'OFF'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          borderRight: '1px solid #D3D3D3',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.dinnerActive ? 'green' : 'red',
                            }}
                          >
                            {this.state.dinnerActive ? 'ON' : 'OFF'}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                      >
                        <FormGroup className={'has-label'}>
                          <Label
                            style={{
                              color: this.state.nighttimeActive
                                ? 'green'
                                : 'red',
                            }}
                          >
                            {this.state.nighttimeActive ? 'ON' : 'OFF'}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <FormGroup
                          style={{
                            textAlign: 'center',
                            paddingTop: '20px',
                            display: this.state.error ? '' : '',
                          }}
                        >
                          <Label
                            style={{
                              fontSize: 14,
                              color: 'tomato',
                              paddingRight: 15,
                              display: this.state.error ? '' : 'none',
                            }}
                          >
                            {this.state.error}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="success" onClick={(e) => this.submit(e)}>
                      {this.state.disabled
                        ? 'One moment...'
                        : this.state.id
                        ? 'Update prescription'
                        : 'Add prescription'}
                    </Button>
                    {/* spacer between buttons */ ' '}
                    <Button
                      style={{ marginLeft: 10 }}
                      color="secondary"
                      onClick={() => this.Cancel()}
                    >
                      Cancel
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default InsulinPrescriptionForm;
