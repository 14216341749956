/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Input,
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import Select from 'react-select';

const queryString = require('query-string');

//set up our insulin type options
const optionsType = [
  { index: 0, value: 'Basal', label: 'Basal' },
  { index: 1, value: 'Bolus', label: 'Bolus' },
  { index: 2, value: 'Mixed', label: 'Mixed' },
];

class InsulinForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      tradeName: '',
      tradeNameState: '',
      insulinType: optionsType[0].value,
      insulinTypeIndex: '0',
      notes: '',
      notesState: '',
      disabled: false,
      error: '',
      loading: true,
    };
  }

  Cancel() {
    this.props.history.push('/in/l/insulins#top');
  }

  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);

      var qs = queryString.parse(this.props.location.search);
      //do we have a group id?
      //yes
      if (qs['id']) {
        //get the group from the server
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            SECRET: localStorage.getItem('SECRET'),
            PASSPHRASE: localStorage.getItem('PASSPHRASE'),
          },
        };

        fetch(
          window.configuration.InsulinURL + '?id=' + qs['id'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to edit the insulin',
                  disabled: true,
                  loading: false,
                });
              }

              // get error message from body or default to response status
              const error = response.status;
              return Promise.reject(error);
            }
            //did we get an error?
            //no
            else {
              var insulinResponse = await response.json();

              //did we get a good one?
              //yes
              if (insulinResponse.id) {
                this.setState({
                  error: '',
                  loading: false,
                  disabled: false,
                  id: insulinResponse.id,
                  tradeName: insulinResponse.tradeName,
                  tradeNameState: insulinResponse.tradeName
                    ? 'has-success'
                    : 'has-danger',
                  insulinType: insulinResponse.insulinType
                    ? insulinResponse.insulinType
                    : '',
                  insulinTypeIndex:
                    optionsType.filter(
                      (o) => o.value === insulinResponse.insulinType,
                    ).length > 0
                      ? optionsType.filter(
                          (o) => o.value === insulinResponse.insulinType,
                        )[0].index
                      : 0,
                  notes: insulinResponse.notes,
                  notesState: insulinResponse.notes ? 'has-success' : '',
                });
              }
            }
          })
          .catch((error) => {
            this.setState({
              error: error,
              disabled: false,
              loading: false,
            });
          });
      }
      //do we have a group id?
      //no
      else {
        this.setState({ loading: false });
      }
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  _handleKeyDown = (e) => {
    try {
      //did they press enter?
      //yes
      if (e.key === 'Enter') {
        this.submit(null, null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //this submits the form
  submit(e, navigation) {
    //did we disable the button?
    //yes
    if (this.state.disabled) {
      return;
    }

    //do we have a name?
    //no
    if (this.state.tradeName.length === 0) {
      this.setState({
        error: "Please enter the insulin's namename",
      });
      return;
    }

    this.setState({
      disabled: true,
      error: '',
      loading: true,
    });

    // POST request using fetch with error handling
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
      body: JSON.stringify({
        //we set id because this is the insulin record
        id: this.state.id,
        tradeName: this.state.tradeName,
        insulinType: this.state.insulinType,
        notes: this.state.notes,
      }),
    };

    fetch(window.configuration.InsulinURL, requestOptions)
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          //did we get an unauthorized?
          //no
          else {
            //set the error
            this.setState({
              error: 'We were unable to add the insulin',
              disabled: false,
              loading: false,
            });
          }
          // get error message from body or default to response status
          const error = response.status;
          return Promise.reject(error);
        }
        //did we get an error?
        //no
        else {
          this.Cancel();
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          disabled: false,
          loading: false,
        });
      });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader
            height="0"
            width="0"
            type="ThreeDots"
            color="#00BFFF"
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              background: '#99CCFF',
              opacity: '0.6',
              zIndex: '998',
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          ''
        )}
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={6}>
              <Form>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      {this.state.id ? 'Update an insulin' : 'Add an insulin'}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={12} md={4}>
                        <FormGroup className={'has-label'}>
                          <Label>Type</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            options={optionsType}
                            onChange={(item) => {
                              this.setState({
                                insulinTypeIndex: item.index,
                                insulinType: item.value,
                                error: '',
                              });
                            }}
                            value={optionsType[this.state.insulinTypeIndex]}
                            placeholder="Select a type"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={8}>
                        <FormGroup
                          className={'has-label ' + this.state.tradeNameState}
                        >
                          <Label>Name</Label>
                          <Input
                            type="text"
                            maxLength="150"
                            onChange={(e) => {
                              this.setState({
                                tradeName: e.target.value,
                                tradeNameState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                                error: '',
                              });
                            }}
                            autoFocus
                            autoCorrect="true"
                            value={this.state.tradeName}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 20 }}>
                      <Col xs={12} md={12}>
                        <FormGroup
                          className={'has-label ' + this.state.notesState}
                        >
                          <Label>Notes</Label>
                          <Input
                            type="textarea"
                            maxLength="5000"
                            style={{
                              border: '1px solid #dadada',
                              minHeight: 200,
                            }}
                            onChange={(e) => {
                              this.setState({
                                notes: e.target.value,
                                notesState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                                error: '',
                              });
                            }}
                            autoCorrect="true"
                            value={this.state.notes}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <FormGroup
                          style={{
                            textAlign: 'end',
                            display: this.state.error ? '' : '',
                          }}
                        >
                          <Label
                            style={{
                              fontSize: 14,
                              color: 'tomato',
                              paddingRight: 15,
                              display: this.state.error ? '' : 'none',
                            }}
                          >
                            {this.state.error}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="success" onClick={(e) => this.submit(e)}>
                      {this.state.disabled
                        ? 'One moment...'
                        : this.state.id
                        ? 'Update an insulin'
                        : 'Add an insulin'}
                    </Button>
                    {/* spacer between buttons */ ' '}
                    <Button
                      style={{ marginLeft: 10 }}
                      color="secondary"
                      onClick={() => this.Cancel()}
                    >
                      Cancel
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default InsulinForm;
