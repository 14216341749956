import React from 'react';
import ReactDOM from 'react-dom';
import configuration from './config';
import ReactGA from 'react-ga';
import App from './App.jsx';

// Style files
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/now-ui-dashboard.scss';
import 'assets/css/demo.css';

//set the configuration in the window
window.configuration = configuration;

//Google Analytics
ReactGA.initialize(configuration.GoogleAnalytics);

ReactDOM.render(<App />, document.getElementById('root'));
