/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Input,
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const queryString = require('query-string');

//set up our health insurance type options
const optionsFilingMethod = [
  { index: 0, value: '', label: 'Unknown' },
  { index: 1, value: 'ELTRC', label: 'Electronic' },
  { index: 2, value: 'PRINT', label: 'Print' },
];

//set up our health insurance type options
const optionsHealthInsuranceType = [
  { index: 0, value: '', label: 'Unknown' },
  { index: 1, value: 'MCARE', label: 'Medicare' },
  { index: 2, value: 'MCAID', label: 'Medicaid' },
  { index: 3, value: 'TRICR', label: 'Tricare' },
  { index: 4, value: 'CHPVA', label: 'Champ VA' },
  { index: 5, value: 'GRPHP', label: 'Group Health Plan' },
  { index: 6, value: 'FECBL', label: 'FECA Black Lung' },
  { index: 7, value: 'OTHER', label: 'Other' },
];

//set up our claim filing indicator options
const optionsClaimFiling = [
  { index: 0, value: '', label: 'Unknown' },
  { index: 1, value: '09', label: 'Self-pay' },
  { index: 2, value: '12', label: 'Preferred Provider Organization (PPO)' },
  { index: 3, value: '13', label: 'Point of Service (POS)' },
  { index: 4, value: 'AM', label: 'Automobile Medical' },
  { index: 5, value: 'BL', label: 'Blue Cross/Blue Shield' },
  { index: 6, value: 'CH', label: 'Champus' },
  { index: 7, value: 'CI', label: 'Commercial Insurance Co.' },
  { index: 8, value: 'HM', label: 'Health Maintenance Organization' },
  { index: 9, value: 'MB', label: 'Medicare Part B' },
  { index: 10, value: 'MC', label: 'Medicaid' },
  { index: 11, value: 'WC', label: 'Workers Compensation Health Claim' },
];

//set up our state options
const optionsState = [
  { index: 0, value: '', label: 'Unknown' },
  { index: 1, value: 'AL', label: 'Alabama' },
  { index: 2, value: 'AK', label: 'Alaska' },
  { index: 3, value: 'AZ', label: 'Arizona' },
  { index: 4, value: 'AR', label: 'Arkansas' },
  { index: 5, value: 'CA', label: 'California' },
  { index: 6, value: 'CO', label: 'Colorado' },
  { index: 7, value: 'CT', label: 'Connecticut' },
  { index: 8, value: 'DE', label: 'Delaware' },
  { index: 9, value: 'FL', label: 'Florida' },
  { index: 10, value: 'GA', label: 'Georgia' },
  { index: 11, value: 'HI', label: 'Hawaii' },
  { index: 12, value: 'ID', label: 'Idaho' },
  { index: 13, value: 'IL', label: 'Illinois' },
  { index: 14, value: 'IN', label: 'Indiana' },
  { index: 15, value: 'IA', label: 'Iowa' },
  { index: 16, value: 'KS', label: 'Kansas' },
  { index: 17, value: 'KY', label: 'Kentucky' },
  { index: 18, value: 'LA', label: 'Louisiana' },
  { index: 19, value: 'ME', label: 'Maine' },
  { index: 20, value: 'MD', label: 'Maryland' },
  { index: 21, value: 'MA', label: 'Massachusetts' },
  { index: 22, value: 'MI', label: 'Michigan' },
  { index: 23, value: 'MN', label: 'Minnesota' },
  { index: 24, value: 'MS', label: 'Mississippi' },
  { index: 25, value: 'MO', label: 'Missouri' },
  { index: 26, value: 'MT', label: 'Montana' },
  { index: 27, value: 'NE', label: 'Nebraska' },
  { index: 28, value: 'NV', label: 'Nevada' },
  { index: 29, value: 'NH', label: 'New Hampshire' },
  { index: 30, value: 'NJ', label: 'New Jersey' },
  { index: 31, value: 'NM', label: 'New Mexico' },
  { index: 32, value: 'NY', label: 'New York' },
  { index: 33, value: 'NC', label: 'North Carolina' },
  { index: 34, value: 'ND', label: 'North Dakota' },
  { index: 35, value: 'OH', label: 'Ohio' },
  { index: 36, value: 'OK', label: 'Oklahoma' },
  { index: 37, value: 'OR', label: 'Oregon' },
  { index: 38, value: 'PA', label: 'Pennsylvania' },
  { index: 39, value: 'RI', label: 'Rhode Island' },
  { index: 40, value: 'SC', label: 'South Carolina' },
  { index: 41, value: 'SD', label: 'South Dakota' },
  { index: 42, value: 'TN', label: 'Tennessee' },
  { index: 43, value: 'TX', label: 'Texas' },
  { index: 44, value: 'UT', label: 'Utah' },
  { index: 45, value: 'VT', label: 'Vermont' },
  { index: 46, value: 'VA', label: 'Virginia' },
  { index: 47, value: 'WA', label: 'Washington' },
  { index: 48, value: 'WV', label: 'West Virginia' },
  { index: 49, value: 'WI', label: 'Wisconsin' },
  { index: 50, value: 'WY', label: 'Wyoming' },
];

class PayorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      nameState: '',
      claimFilingIndex: -1,
      claimFiling: '',
      healthInsuranceTypeIndex: -1,
      healthInsuranceType: '',
      primaryFilingMethodIndex: -1,
      primaryFilingMethod: '',
      secondaryFilingMethodIndex: -1,
      secondaryFilingMethod: '',
      electronicId: '',
      electronicIdState: '',
      realtimeTranElectronicId: '',
      realtimeTranElectronicIdState: '',
      uBO4ERAElectronicId: '',
      uBO4ERAElectronicIdState: '',
      uBO4ClaimElectronicId: '',
      uBO4ClaimElectronicIdState: '',
      eligibilityElectronicId: '',
      eligibilityElectronicIdState: '',
      phoneNumber: '',
      phoneNumberState: '',
      faxNumber: '',
      faxNumberState: '',
      addressId: '',
      line1: '',
      line1State: '',
      line2: '',
      line2State: '',
      city: '',
      cityState: '',
      state: optionsState[0].value,
      stateIndex: '0',
      postalCode: '',
      postalCodeState: '',
      disabled: false,
      error: '',
      notes: '',
      loading: true,
      addedByName: '',
      dateAdded: '',
      updatedByName: '',
      dateUpdated: '',
    };
  }

  Cancel() {
    this.props.history.push('/in/l/payors#top');
  }

  phoneUpdated(phone) {
    this.setState({
      phoneNumber: phone,
      phoneNumberState: 'has-success',
    });
  }

  faxUpdated(fax) {
    this.setState({
      faxNumber: fax,
      faxNumberState: 'has-success',
    });
  }

  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      //this is our base to get the data
      var requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          SECRET: localStorage.getItem('SECRET'),
          PASSPHRASE: localStorage.getItem('PASSPHRASE'),
        },
      };

      ReactGA.pageview(window.location.pathname + window.location.search);

      var qs = queryString.parse(this.props.location.search);
      //do we have a group id?
      //yes
      if (qs['id']) {
        fetch(window.configuration.PayorURL + '?id=' + qs['id'], requestOptions)
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to edit the payor',
                  disabled: true,
                  loading: false,
                });
              }

              // get error message from body or default to response status
              const error = response.status;
              return Promise.reject(error);
            }
            //did we get an error?
            //no
            else {
              var payorResponse = await response.json();

              //did we get a good one?
              //yes
              if (payorResponse.id) {
                //let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

                this.setState({
                  error: '',
                  loading: false,
                  disabled: false,
                  id: payorResponse.id,
                  name: payorResponse.name,
                  nameState: 'has-success',
                  phoneNumber: payorResponse.phoneNumber,
                  phoneNumberState: 'has-success',
                  faxNumber: payorResponse.faxNumber,
                  faxNumberState: 'has-success',
                  addressId: payorResponse.address
                    ? payorResponse.address.id
                    : '',
                  line1: payorResponse.address
                    ? payorResponse.address.line1
                    : '',
                  line2: payorResponse.address
                    ? payorResponse.address.line2
                    : '',
                  city: payorResponse.address ? payorResponse.address.city : '',
                  state: payorResponse.address
                    ? payorResponse.address.state
                    : '',
                  stateIndex: payorResponse.address
                    ? optionsState.filter(
                        (o) => o.value === payorResponse.address.state,
                      )[0].index
                    : -1,
                  claimFilingIndex: payorResponse.claimFiling
                    ? optionsClaimFiling.filter(
                        (i) => i.value === payorResponse.claimFiling,
                      )[0].index
                    : -1,
                  healthInsuranceTypeIndex: payorResponse.healthInsuranceType
                    ? optionsHealthInsuranceType.filter(
                        (t) => t.value === payorResponse.healthInsuranceType,
                      )[0].index
                    : -1,
                  primaryFilingMethodIndex: payorResponse.primaryClaimFilingMethod
                    ? optionsClaimFiling.filter(
                        (m) =>
                          m.value === payorResponse.primaryClaimFilingMethod,
                      )[0].index
                    : -1,
                  secondaryFilingMethodIndex: payorResponse.secondaryClaimFilingMethod
                    ? optionsClaimFiling.filter(
                        (m) =>
                          m.value === payorResponse.secondaryClaimFilingMethod,
                      )[0].index
                    : -1,
                  electronicId: payorResponse.electronicId,
                  realtimeTranElectronicId:
                    payorResponse.realtimeTranElectronicId,
                  uBO4ERAElectronicId: payorResponse.uBO4ERAElectronicId,
                  uBO4ClaimElectronicId: payorResponse.uBO4ClaimElectronicId,
                  eligibilityElectronicId:
                    payorResponse.eligibilityElectronicId,
                  postalCode: payorResponse.address
                    ? payorResponse.address.postalCode
                    : '',
                  notes: payorResponse.notes,
                  addedByName: payorResponse.addedByName
                    ? payorResponse.addedByName
                    : 'unknown',
                  dateAdded:
                    new Date(payorResponse.dateAdded).toLocaleDateString() +
                    ' ' +
                    new Date(payorResponse.dateAdded).toLocaleTimeString(),
                  updatedByName: payorResponse.updatedByName
                    ? payorResponse.updatedByName
                    : 'unknown',
                  dateUpdated:
                    new Date(payorResponse.dateUpdated).toLocaleDateString() +
                    ' ' +
                    new Date(payorResponse.dateUpdated).toLocaleTimeString(),
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: error,
              disabled: false,
              loading: false,
            });
          });
      }
      //do we have a group id?
      //no
      else {
        this.setState({ loading: false });
      }
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  //this submits the form
  submit(e, navigation) {
    //did we disable the button?
    //yes
    if (this.state.disabled) {
      return;
    }

    //do we have a name?
    //no
    if (this.state.name.length === 0) {
      this.setState({
        error: "Please enter the payor's name",
      });
      return;
    }

    //do we have a line1?
    //no
    if (this.state.line1.length === 0) {
      this.setState({
        error: "Please enter the payor's address line 1",
      });
      return;
    }

    //do we have a city?
    //no
    if (this.state.city.length === 0) {
      this.setState({
        error: "Please enter the payor's city",
      });
      return;
    }

    //do we have a state?
    //no
    if (this.state.state.length === 0) {
      this.setState({
        error: "Please enter the payor's state",
      });
      return;
    }

    //do we have a postalCode?
    //no
    if (this.state.postalCode.length === 0) {
      this.setState({
        error: "Please enter the payor's zip code",
      });
      return;
    }

    //do we have a electronicId?
    //no
    if (this.state.electronicId.length === 0) {
      this.setState({
        error: "Please enter the payor's electronic ID",
      });
      return;
    }

    this.setState({
      disabled: true,
      error: '',
      loading: true,
    });

    // POST request using fetch with error handling
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
      body: JSON.stringify({
        //we set id because this is the payor record
        id: this.state.id,
        name: this.state.name,
        prefix: this.state.prefix,
        address: {
          id: this.state.addressId,
          line1: this.state.line1,
          line2: this.state.line2,
          city: this.state.city,
          state: this.state.state,
          postalCode: this.state.postalCode,
        },
        claimFiling: this.state.claimFiling,
        healthInsuranceType: this.state.healthInsuranceType,
        primaryClaimFilingMethod: this.state.primaryFilingMethod,
        secondaryClaimFilingMethod: this.state.secondaryFilingMethod,
        electronicId: this.state.electronicId,
        realtimeTranElectronicId: this.state.realtimeTranElectronicId,
        uBO4ERAElectronicId: this.state.uBO4ERAElectronicId,
        uBO4ClaimElectronicId: this.state.uBO4ClaimElectronicId,
        eligibilityElectronicId: this.state.eligibilityElectronicId,
        phoneNumber: this.state.phoneNumber,
        faxNumber: this.state.faxNumber,
        notes: this.state.notes,
      }),
    };

    fetch(window.configuration.PayorURL, requestOptions)
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          //did we get an unauthorized?
          //no
          else {
            //set the error
            this.setState({
              error: 'We were unable to add the payor',
              disabled: false,
              loading: false,
            });
          }
          // get error message from body or default to response status
          const error = response.status;
          return Promise.reject(error);
        }
        //did we get an error?
        //no
        else {
          this.Cancel();
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          disabled: false,
          loading: false,
        });
      });
  }

  render() {
    //are we loading?
    //yes
    if (this.state.loading) {
      return (
        <Loader
          height="0"
          width="0"
          type="ThreeDots"
          color="#00BFFF"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            background: '#99CCFF',
            opacity: '0.6',
            zIndex: '998',
            height: '100%',
            width: '100%',
          }}
        />
      );
    }
    //are we loading?
    //no
    else {
      return (
        <>
          {this.state.loading ? (
            <Loader
              height="0"
              width="0"
              type="ThreeDots"
              color="#00BFFF"
              style={{
                position: 'fixed',
                top: '0',
                left: '0',
                background: '#99CCFF',
                opacity: '0.6',
                zIndex: '998',
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            ''
          )}
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col xs={12} md={6}>
                <Form>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        {this.state.id ? 'Update a Payor' : 'Add a Payor'}
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            className={'has-label ' + this.state.nameState}
                          >
                            <Label>Name *</Label>
                            <Input
                              type="text"
                              maxLength="100"
                              onChange={(e) =>
                                this.setState({
                                  name: e.target.value,
                                  nameState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoFocus
                              autoCorrect="true"
                              value={this.state.name}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.electronicIdState
                            }
                          >
                            <Label>Electronic ID *</Label>
                            <Input
                              type="text"
                              maxLength="20"
                              onChange={(e) =>
                                this.setState({
                                  electronicId: e.target.value,
                                  electronicIdState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              value={this.state.electronicId}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' +
                              this.state.realtimeTranElectronicIdState
                            }
                          >
                            <Label>ERA Electronic ID</Label>
                            <Input
                              type="text"
                              maxLength="20"
                              onChange={(e) =>
                                this.setState({
                                  realtimeTranElectronicId: e.target.value,
                                  realtimeTranElectronicIdState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              value={this.state.realtimeTranElectronicId}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.uBO4ERAElectronicIdState
                            }
                          >
                            <Label>UB04 ERA Electronic ID</Label>
                            <Input
                              type="text"
                              maxLength="20"
                              onChange={(e) =>
                                this.setState({
                                  uBO4ERAElectronicId: e.target.value,
                                  uBO4ERAElectronicIdState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              value={this.state.uBO4ERAElectronicId}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' +
                              this.state.uBO4ClaimElectronicIdState
                            }
                          >
                            <Label>UB04 Electronic ID</Label>
                            <Input
                              type="text"
                              maxLength="20"
                              onChange={(e) =>
                                this.setState({
                                  uBO4ClaimElectronicId: e.target.value,
                                  uBO4ClaimElectronicIdState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              value={this.state.uBO4ClaimElectronicId}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' +
                              this.state.eligibilityElectronicIdState
                            }
                          >
                            <Label>Eligibility Electronic ID</Label>
                            <Input
                              type="text"
                              maxLength="20"
                              onChange={(e) =>
                                this.setState({
                                  eligibilityElectronicId: e.target.value,
                                  eligibilityElectronicIdState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              value={this.state.eligibilityElectronicId}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            className={'has-label ' + this.state.line1State}
                          >
                            <Label>Address *</Label>
                            <Input
                              type="text"
                              maxLength="300"
                              onChange={(e) =>
                                this.setState({
                                  line1: e.target.value,
                                  line1State:
                                    e.target.value.length > 4
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.line1}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            className={'has-label ' + this.state.line2State}
                          >
                            <Label>Suite, building, etc.</Label>
                            <Input
                              type="text"
                              maxLength="300"
                              onChange={(e) =>
                                this.setState({
                                  line2: e.target.value,
                                  line2State:
                                    e.target.value.length > 4
                                      ? 'has-success'
                                      : 'has-success',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.line2}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={'has-label ' + this.state.cityState}
                          >
                            <Label>City *</Label>
                            <Input
                              type="text"
                              maxLength="100"
                              onChange={(e) =>
                                this.setState({
                                  city: e.target.value,
                                  cityState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.city}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup className={'has-label'}>
                            <Label>State *</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsState}
                              onChange={(item) => {
                                this.setState({
                                  stateIndex: item.index,
                                  state: item.value,
                                });
                              }}
                              value={optionsState[this.state.stateIndex]}
                              placeholder="Select a state for this payor"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.postalCodeState
                            }
                          >
                            <Label>Zip Code *</Label>
                            <Input
                              type="text"
                              maxLength="10"
                              onChange={(e) =>
                                this.setState({
                                  postalCode: e.target.value,
                                  postalCodeState:
                                    e.target.value.length > 4
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.postalCode}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.phoneNumberState
                            }
                          >
                            <Label>Phone Number</Label>
                            <PhoneInput
                              inputProps={{
                                name: 'phone',
                                required: true,
                              }}
                              country={'us'}
                              regions={'north-america'}
                              placeholder="(512)-555-1212"
                              disableDropdown={true}
                              disableCountryCode={true}
                              countryCodeEditable={true}
                              inputStyle={{
                                fontSize: 16,
                                height: 40,
                                paddingLeft: 50,
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                width: '100%',
                              }}
                              value={this.state.phoneNumber}
                              onChange={(text) => this.phoneUpdated(text)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={'has-label ' + this.state.faxNumberState}
                          >
                            <Label>Fax Number</Label>
                            <PhoneInput
                              inputProps={{
                                name: 'fax',
                                required: true,
                              }}
                              country={'us'}
                              regions={'north-america'}
                              placeholder="(512)-555-1212"
                              disableDropdown={true}
                              disableCountryCode={true}
                              countryCodeEditable={true}
                              inputStyle={{
                                fontSize: 16,
                                height: 40,
                                paddingLeft: 50,
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                width: '100%',
                              }}
                              value={this.state.faxNumber}
                              onChange={(text) => this.faxUpdated(text)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={4}>
                          <FormGroup className={'has-label'}>
                            <Label>Health Insurance Type</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsHealthInsuranceType}
                              onChange={(item) => {
                                this.setState({
                                  healthInsuranceTypeIndex: item.index,
                                  healthInsuranceType: item.value,
                                });
                              }}
                              value={
                                optionsHealthInsuranceType[
                                  this.state.healthInsuranceTypeIndex
                                ]
                              }
                              placeholder="Select an insurance type"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup className={'has-label'}>
                            <Label>Claim Filing</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsClaimFiling}
                              onChange={(item) => {
                                this.setState({
                                  claimFilingIndex: item.index,
                                  claimFiling: item.value,
                                });
                              }}
                              value={
                                optionsClaimFiling[this.state.claimFilingIndex]
                              }
                              placeholder="Select a claim filng"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={4}>
                          <FormGroup className={'has-label'}>
                            <Label>Primary Filing Method</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsFilingMethod}
                              onChange={(item) => {
                                this.setState({
                                  primaryFilingMethodIndex: item.index,
                                  primaryFilingMethod: item.value,
                                });
                              }}
                              value={
                                optionsFilingMethod[
                                  this.state.primaryFilingMethodIndex
                                ]
                              }
                              placeholder="Select a primary filing type"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup className={'has-label'}>
                            <Label>Secondary Filing Method</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsFilingMethod}
                              onChange={(item) => {
                                this.setState({
                                  secondaryFilingMethodIndex: item.index,
                                  secondaryFilingMethod: item.value,
                                });
                              }}
                              value={
                                optionsFilingMethod[
                                  this.state.secondaryFilingMethodIndex
                                ]
                              }
                              placeholder="Select a secondary filing type"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            className={'has-label ' + this.state.notesState}
                          >
                            <Label>Notes</Label>
                            <Input
                              type="textarea"
                              maxLength="5000"
                              style={{
                                border: '1px solid #dadada',
                                minHeight: 200,
                              }}
                              onChange={(e) =>
                                this.setState({
                                  notes: e.target.value,
                                  notesState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.notes}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            style={{
                              textAlign: 'end',
                              display: this.state.error ? '' : 'none',
                            }}
                          >
                            <Label
                              style={{
                                fontSize: 14,
                                color: 'tomato',
                                paddingRight: 15,
                                display: this.state.error ? '' : 'none',
                              }}
                            >
                              {this.state.error}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginTop: 20,
                          display: this.state.addedByName ? '' : 'none',
                        }}
                      >
                        <Col xs={12} md={2}>
                          <FormGroup>
                            <Label>Added:</Label>
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={10}>
                          <FormGroup>
                            <Label>
                              {this.state.dateAdded +
                                ' by ' +
                                this.state.addedByName}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col
                          xs={12}
                          md={2}
                          style={{
                            display: this.state.updatedByName ? '' : 'none',
                          }}
                        >
                          <FormGroup>
                            <Label>Last Updated:</Label>
                          </FormGroup>
                        </Col>
                        <Col
                          xs={12}
                          md={10}
                          style={{
                            display: this.state.updatedByName ? '' : 'none',
                          }}
                        >
                          <FormGroup>
                            <Label>
                              {this.state.dateUpdated +
                                ' by ' +
                                this.state.updatedByName}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                      <Button color="success" onClick={(e) => this.submit(e)}>
                        {this.state.disabled
                          ? 'One moment...'
                          : this.state.id
                          ? 'Update payor'
                          : 'Add payor'}
                      </Button>
                      {/* spacer between buttons */ ' '}
                      <Button
                        style={{ marginLeft: 10 }}
                        color="secondary"
                        onClick={() => this.Cancel()}
                      >
                        Cancel
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default PayorForm;
