/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Input,
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Multiselect } from 'multiselect-react-dropdown';
import moment from 'moment';
import ImageUploader from 'react-images-upload';
import { v4 as uuidv4 } from 'uuid';
import Switch from '@material-ui/core/Switch';
import Axios from 'axios';
import config from 'config';

const queryString = require('query-string');

//set up our state options
const optionsState = [
  { index: 0, value: 'AL', label: 'Alabama' },
  { index: 1, value: 'AK', label: 'Alaska' },
  { index: 2, value: 'AZ', label: 'Arizona' },
  { index: 3, value: 'AR', label: 'Arkansas' },
  { index: 4, value: 'CA', label: 'California' },
  { index: 5, value: 'CO', label: 'Colorado' },
  { index: 6, value: 'CT', label: 'Connecticut' },
  { index: 7, value: 'DE', label: 'Delaware' },
  { index: 8, value: 'FL', label: 'Florida' },
  { index: 9, value: 'GA', label: 'Georgia' },
  { index: 10, value: 'HI', label: 'Hawaii' },
  { index: 11, value: 'ID', label: 'Idaho' },
  { index: 12, value: 'IL', label: 'Illinois' },
  { index: 13, value: 'IN', label: 'Indiana' },
  { index: 14, value: 'IA', label: 'Iowa' },
  { index: 15, value: 'KS', label: 'Kansas' },
  { index: 16, value: 'KY', label: 'Kentucky' },
  { index: 17, value: 'LA', label: 'Louisiana' },
  { index: 18, value: 'ME', label: 'Maine' },
  { index: 19, value: 'MD', label: 'Maryland' },
  { index: 20, value: 'MA', label: 'Massachusetts' },
  { index: 21, value: 'MI', label: 'Michigan' },
  { index: 22, value: 'MN', label: 'Minnesota' },
  { index: 23, value: 'MS', label: 'Mississippi' },
  { index: 24, value: 'MO', label: 'Missouri' },
  { index: 25, value: 'MT', label: 'Montana' },
  { index: 26, value: 'NE', label: 'Nebraska' },
  { index: 27, value: 'NV', label: 'Nevada' },
  { index: 28, value: 'NH', label: 'New Hampshire' },
  { index: 29, value: 'NJ', label: 'New Jersey' },
  { index: 30, value: 'NM', label: 'New Mexico' },
  { index: 31, value: 'NY', label: 'New York' },
  { index: 32, value: 'NC', label: 'North Carolina' },
  { index: 33, value: 'ND', label: 'North Dakota' },
  { index: 34, value: 'OH', label: 'Ohio' },
  { index: 35, value: 'OK', label: 'Oklahoma' },
  { index: 36, value: 'OR', label: 'Oregon' },
  { index: 37, value: 'PA', label: 'Pennsylvania' },
  { index: 38, value: 'RI', label: 'Rhode Island' },
  { index: 39, value: 'SC', label: 'South Carolina' },
  { index: 40, value: 'SD', label: 'South Dakota' },
  { index: 41, value: 'TN', label: 'Tennessee' },
  { index: 42, value: 'TX', label: 'Texas' },
  { index: 43, value: 'UT', label: 'Utah' },
  { index: 44, value: 'VT', label: 'Vermont' },
  { index: 45, value: 'VA', label: 'Virginia' },
  { index: 46, value: 'WA', label: 'Washington' },
  { index: 47, value: 'WV', label: 'West Virginia' },
  { index: 48, value: 'WI', label: 'Wisconsin' },
  { index: 49, value: 'WY', label: 'Wyoming' },
];

//set up our gender options
const optionsGender = [
  { index: 0, value: 'unknown', label: 'Unknown' },
  { index: 1, value: 'female', label: 'Female' },
  { index: 2, value: 'male', label: 'Male' },
  { index: 3, value: 'other', label: 'Other' },
];

const optionsRelation = [
  { index: 0, value: 'unknown', label: 'Unknown' },
  { index: 1, value: '18', label: 'Self' },
  { index: 2, value: '01', label: 'Spouse' },
  { index: 3, value: '19', label: 'Child' },
  { index: 4, value: '21', label: 'Other' },
];

const optionsMedicareCode = [
  { index: 0, value: '', label: 'None' },
  {
    index: 1,
    value: '12',
    label:
      'Medicare Secondary Working Aged Beneficiary or Spouse with Employer Group Health Plan',
  },
  {
    index: 2,
    value: '13',
    label:
      "Medicare Secondary End-Stage Renal Disease Beneficiary in the Mandated Coordination Period with an Employer's Group Health Plan",
  },
  {
    index: 3,
    value: '14',
    label: 'Medicare Secondary, No-fault Insurance including Auto is Primary',
  },
  { index: 4, value: '15', label: "Medicare Secondary Worker's Compensation" },
  {
    index: 5,
    value: '16',
    label:
      'Medicare Secondary Public Health Service (PHS)or Other Federal Agency',
  },
  { index: 6, value: '41', label: 'Medicare Secondary Black Lung' },
  {
    index: 7,
    value: '42',
    label: "Medicare Secondary Veteran's Administration",
  },
  {
    index: 8,
    value: '43',
    label:
      'Medicare Secondary Disabled Beneficiary Under Age 65 with Large Group Health Plan (LGHP)',
  },
  {
    index: 9,
    value: '47',
    label: 'Medicare Secondary, Other Liability Insurance is Primary',
  },
];

class PatientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPolicyHolder: 'none',
      ssn: '',
      ssnState: '',
      medicationName: '',

      payorData: [],

      insulinData: [],
      basalInsulinId: '',
      basalInsulinName: '',
      basalInsulinIndex: -1,
      bolusInsulinId: '',
      bolusInsulinName: '',
      bolusInsulinIndex: -1,
      mixedInsulinId: '',
      mixedInsulinName: '',
      mixedInsulinIndex: -1,

      pharmacyData: [],
      pharmacyId: '',
      pharmacyIndex: -1,
      pharmacyPostalCode: '',

      pcpsData: [],
      pcpId: '',
      pcpIndex: -1,
      referringPhysicianData: [],
      referringPhysicianId: '',
      referringPhysicianIndex: -1,

      primaryCarePhysician: {},

      glucoseLevelHypo: '70',
      glucoseLevelHypoState: 'has-success',
      glucoseLevelGoal: '120',
      glucoseLevelGoalState: 'has-success',
      glucoseLevelHigh: '200',
      glucoseLevelHighState: 'has-success',
      glucoseLevelDanger: '500',
      glucoseLevelDangerState: 'has-success',
      glucoseLevelImpossible: '800',
      glucoseLevelImpossibleState: 'has-success',
      medicationsData: [],
      medications: [],

      allergyHistory: '',
      medicalHistory: '',
      surgicalHistory: '',
      familyHistory: '',
      socialHistory: '',

      timeWakeUp: '8:00 AM',
      timeWakeUpState: 'has-success',
      timeBreakfast: '9:00 AM',
      timeBreakfastState: 'has-success',
      timeLunch: '12:00 PM',
      timeLunchState: 'has-success',
      timeDinner: '5:00 PM',
      timeDinnerState: 'has-success',
      timeSleep: '9:00 PM',
      timeSleepState: 'has-success',
      patientId: '',
      testPatient: false,
      firstName: '',
      firstNameState: '',
      middleName: '',
      middleNameState: '',
      lastName: '',
      lastNameState: '',
      nickName: '',

      phoneMobile: '',
      phoneMobileState: '',
      emailAddress: '',
      emailAddressState: '',
      addressId: '',
      line1: '',
      line1State: '',
      line2: '',
      line2State: '',
      city: '',
      cityState: '',
      county: '',
      countyState: '',
      state: optionsState[0].value,
      stateIndex: '0',
      postalCode: '',
      postalCodeState: '',

      //insurance information
      patientPolicy: {
        id: '',
        payorId: '',
        payorIndex: -1,
        dateStart: '',
        copay: '',
        policyHolder: {
          id: '',
          dateOfBirth: '',
          gender: '',
          genderIndex: '',
          firstName: '',
          lastName: '',
          ssn: '',
          addressHome: {
            id: '',
            line1: '',
            line2: '',
            city: '',
            state: '',
            stateIndex: -1,
            postalCode: '',
          },
        },
        relationship: '',
        relationshipIndex: -1,
        relatedCondition: '',
        policyNumber: '',
        groupNumber: '',
        medicareCode: '',
        medicareCodeIndex: -1,
        insuranceCardFront: '',
        insuranceCardBack: '',
      },

      dateAdded: '',
      addedByName: '',
      dateUpdated: '',
      updatedByName: '',
      disabled: false,
      error: '',
      gender: optionsGender[0].value,
      genderIndex: '0',
      dateOfBirth: '',
      loading: true,
      modal: false,
      pcpSearchFName: '',
      pcpSearchLName: '',
      pcpSearchCity: '',
      pcpSearchState: '',
      pcpSearchPostal: '',
      pcpSearchResponse: [],
      pcpSearchSelectIndex: null,
    };
  }

  Cancel() {
    this.props.history.push('/in/l/patients');
  }

  isValidDate(d) {
    try {
      //do we have a good date?
      //yes
      if (isNaN(Date.parse(d)) === false) {
        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
    return false;
  }

  formatSSN(text) {
    //do we have anything?
    //no
    if (!text) {
      return '';
    }

    var ssn = text.toString();

    ssn = ssn.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, '$1-$2-$3');

    return ssn;
  }

  phoneUpdated(phone) {
    this.setState({
      phoneMobile: phone,
      phoneMobileState: 'has-success',
    });
  }

  _handleNewMedication = (e) => {
    try {
      //did they press enter with a valid item?
      //yes
      if (this.state.medicationName.length > 1 && e.key === 'Enter') {
        this.addMedication();
      }
    } catch (error) {
      console.log(error);
    }
  };

  addMedication() {
    //build our new medication or find the existing one
    var medication =
      this.state.medicationsData.filter(
        (c) => c.name === this.state.medicationName,
      ).length > 0
        ? this.state.medicationsData.filter(
            (c) => c.name === this.state.medicationName,
          )[0]
        : {
            id: uuidv4(),
            text: this.state.medicationName,
            name: this.state.medicationName,
          };

    this.setState({
      medications: this.state.medications.concat(medication),
      disabled: false,
      loading: false,
      medicationName: '',
    });
  }

  loadPCPByState(stateId, cityName, selectedPcpId) {
    //this is our base to get the data
    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
    };

    fetch(
      window.configuration.PhysicianURL +
        '?state=' +
        stateId +
        '&city=' +
        cityName,
      requestOptions,
    )
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
        }
        //did we get an error?
        //no
        else {
          var pcpsResponse = await response.json();

          var index = -1;
          //create our rows
          var pcpsObjects = pcpsResponse.map(function (pcp) {
            index++;
            return {
              index: index,
              value: pcp.id,
              label:
                (pcp.prefix ? pcp.prefix + ' ' : '') +
                pcp.firstName +
                (pcp.MiddleName ? pcp.MiddleName + '. ' : ' ') +
                pcp.lastName +
                (pcp.practiceName ? ' at ' + pcp.practiceName : ''),
            };
          });

          this.setState({
            pcpsData: pcpsObjects,
            pcpId: selectedPcpId ? selectedPcpId : this.state.pcpId,
            pcpIndex: selectedPcpId
              ? pcpsObjects.filter((o) => o.value === selectedPcpId).length > 0
                ? pcpsObjects.filter((o) => o.value === selectedPcpId)[0].index
                : -1
              : -1,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadPhysicianByState(stateId, cityName, selectedPhysicianId) {
    //this is our base to get the data
    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
    };

    fetch(
      window.configuration.PhysicianURL +
        '?state=' +
        stateId +
        '&city=' +
        cityName,
      requestOptions,
    )
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
        }
        //did we get an error?
        //no
        else {
          var physiciansResponse = await response.json();

          var index = -1;
          //create our rows
          var physiciansObjects = physiciansResponse.map(function (physician) {
            index++;
            return {
              index: index,
              value: physician.id,
              label:
                (physician.prefix ? physician.prefix + ' ' : '') +
                physician.firstName +
                (physician.MiddleName ? physician.MiddleName + '. ' : ' ') +
                physician.lastName +
                (physician.practiceName ? ' at ' + physician.practiceName : ''),
            };
          });

          this.setState({
            physiciansData: physiciansObjects,
            referringPhysicianId: selectedPhysicianId
              ? selectedPhysicianId
              : this.state.referringPhysicianId,
            referringPhysicianIndex: selectedPhysicianId
              ? physiciansObjects.filter((o) => o.value === selectedPhysicianId)
                  .length > 0
                ? physiciansObjects.filter(
                    (o) => o.value === selectedPhysicianId,
                  )[0].index
                : -1
              : -1,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadPharmacyByPostalCode(postalCode, selectedPharmacyId) {
    //this is our base to get the data
    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
    };

    fetch(
      window.configuration.PharmacyURL + '?postalCode=' + postalCode,
      requestOptions,
    )
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
        }
        //did we get an error?
        //no
        else {
          var pharmacyResponse = await response.json();

          var index = -1;
          //create our rows
          var pharmacyObjects = pharmacyResponse.map(function (pharmacy) {
            index++;
            return {
              index: index,
              value: pharmacy.id,
              label: pharmacy.name + ' at ' + pharmacy.address.line1,
            };
          });

          this.setState({
            pharmacyData: pharmacyObjects,
            pharmacyId: selectedPharmacyId
              ? selectedPharmacyId
              : this.state.pharmacyId,
            pharmacyIndex: selectedPharmacyId
              ? pharmacyObjects.filter((o) => o.value === selectedPharmacyId)
                  .length > 0
                ? pharmacyObjects.filter(
                    (o) => o.value === selectedPharmacyId,
                  )[0].index
                : -1
              : -1,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);

      //this is our base to get the data
      var requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          SECRET: localStorage.getItem('SECRET'),
          PASSPHRASE: localStorage.getItem('PASSPHRASE'),
        },
      };

      fetch(window.configuration.PayorURL, requestOptions)
        .then(async (response) => {
          // check for error response
          //did we get an error?
          //yes
          if (!response.ok) {
            //did we get an unauthorized?
            //yes
            if (response.status === 401) {
              this.props.history.push('/a/logout');
            }

            // get error message from body or default to response status
            const error = response.status;
            return Promise.reject(error);
          }
          //did we get an error?
          //no
          else {
            var payorsResponse = await response.json();

            var payorIndex = -1;
            //create our rows
            var payorObjects = payorsResponse.map(function (payor) {
              payorIndex++;
              return {
                index: payorIndex,
                value: payor.id,
                label: payor.name,
              };
            });

            this.setState({
              payorData: payorObjects,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      fetch(window.configuration.MedicationURL, requestOptions)
        .then(async (response) => {
          // check for error response
          //did we get an error?
          //yes
          if (!response.ok) {
            //did we get an unauthorized?
            //yes
            if (response.status === 401) {
              this.props.history.push('/a/logout');
            }

            // get error message from body or default to response status
            const error = response.status;
            return Promise.reject(error);
          }
          //did we get an error?
          //no
          else {
            var medicationsResponse = await response.json();

            //create our rows
            var medicationsObjects = medicationsResponse.map(function (
              medication,
            ) {
              return {
                id: medication.id,
                text: medication.name,
                name: medication.name,
              };
            });

            this.setState({
              medicationsData: medicationsObjects,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      var qs = queryString.parse(this.props.location.search);
      //do we have a group id?
      //yes
      if (qs['id']) {
        fetch(
          window.configuration.PatientURL + '?id=' + qs['id'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to edit the patient',
                  disabled: true,
                  loading: false,
                });
              }

              // get error message from body or default to response status
              const error = response.status;
              return Promise.reject(error);
            }
            //did we get an error?
            //no
            else {
              var patientResponse = await response.json();

              console.log(patientResponse.primaryCarePhysician);
              //did we get a good one?
              //yes
              if (patientResponse.id) {
                let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

                var patientPolicy = patientResponse.patientPolicy;

                //do we have a patient policy?
                //yes
                if (patientPolicy !== null) {
                  patientPolicy.payorIndex =
                    this.state.payorData.filter(
                      (p) => p.value === patientPolicy.payorId,
                    ).length > 0
                      ? this.state.payorData.filter(
                          (p) => p.value === patientPolicy.payorId,
                        )[0].index
                      : -1;

                  patientPolicy.relationshipIndex =
                    optionsRelation.filter(
                      (r) => r.value === patientPolicy.relationship,
                    ).length > 0
                      ? optionsRelation.filter(
                          (r) => r.value === patientPolicy.relationship,
                        )[0].index
                      : -1;
                  patientPolicy.medicareCodeIndex =
                    optionsMedicareCode.filter(
                      (c) => c.value === patientPolicy.medicareCode,
                    ).length > 0
                      ? optionsMedicareCode.filter(
                          (c) => c.value === patientPolicy.medicareCode,
                        )[0].index
                      : -1;
                  patientPolicy.dateStart = patientPolicy.dateStart
                    ? patientPolicy.dateStart
                    : '';

                  patientPolicy.copay = (
                    Math.round(patientPolicy.copay * 100) / 100
                  ).toFixed(2);

                  //do we have a policy holder?
                  //no
                  if (patientPolicy.policyHolder === null) {
                    //add one
                    patientPolicy.policyHolder = {
                      id: '',
                      dateOfBirth: '',
                      gender: '',
                      genderIndex: '',
                      firstName: '',
                      lastName: '',
                      ssn: '',
                      addressHome: {
                        id: '',
                        line1: '',
                        line2: '',
                        city: '',
                        state: '',
                        stateIndex: -1,
                        postalCode: '',
                      },
                    };
                  }
                  //do we have a policy holder?
                  //yes
                  else {
                    //set the date of birth
                    patientPolicy.policyHolder.dateOfBirth = patientPolicy
                      .policyHolder.dateOfBirth
                      ? moment(patientPolicy.policyHolder.dateOfBirth).format(
                          'MM/DD/yyyy',
                        )
                      : '';
                    //set the gender  index
                    patientPolicy.policyHolder.genderIndex =
                      optionsGender.filter(
                        (o) => o.value === patientPolicy.policyHolder.gender,
                      ).length > 0
                        ? optionsGender.filter(
                            (o) =>
                              o.value === patientPolicy.policyHolder.gender,
                          )[0].index
                        : -1;
                    //set the state index
                    patientPolicy.policyHolder.addressHome.stateIndex =
                      optionsState.filter(
                        (o) =>
                          o.value ===
                          patientPolicy.policyHolder.addressHome.state,
                      ).length > 0
                        ? optionsState.filter(
                            (o) =>
                              o.value ===
                              patientPolicy.policyHolder.addressHome.state,
                          )[0].index
                        : -1;
                  }
                }
                //do we have a patient policy?
                //no
                else {
                  patientPolicy = {
                    id: '',
                    payorId: '',
                    payorIndex: -1,
                    dateStart: '',
                    copay: '',
                    policyHolder: {
                      id: '',
                      dateOfBirth: '',
                      gender: '',
                      genderIndex: '',
                      firstName: '',
                      lastName: '',
                      ssn: '',
                      addressHome: {
                        id: '',
                        line1: '',
                        line2: '',
                        city: '',
                        state: '',
                        stateIndex: -1,
                        postalCode: '',
                      },
                    },
                    relationship: '',
                    relationshipIndex: -1,
                    relatedCondition: '',
                    policyNumber: '',
                    groupNumber: '',
                    medicareCode: '',
                    medicareCodeIndex: -1,
                    insuranceCardFront: '',
                    insuranceCardBack: '',
                  };
                }

                var showPolicyHolder = patientPolicy
                  ? patientPolicy.relationship === '18' ||
                    patientPolicy.relationship === ''
                    ? 'none'
                    : ''
                  : '';

                this.setState({
                  showPolicyHolder,
                  error: '',
                  loading: false,
                  disabled: false,
                  patientId: patientResponse.id,
                  testPatient: patientResponse.testPatient,
                  dateOfBirth: patientResponse.dateOfBirth
                    ? moment(patientResponse.dateOfBirth).format('MM/DD/yyyy')
                    : '',
                  firstName: patientResponse.firstName,
                  firstNameState: 'has-success',
                  middleName: patientResponse.middleName,
                  middleNameState: 'has-success',
                  lastName: patientResponse.lastName,
                  lastNameState: 'has-success',
                  nickName: patientResponse.nickName,

                  phoneMobile: patientResponse.phoneMobile,
                  phoneMobileState: 'has-success',
                  gender: patientResponse.gender,
                  genderIndex:
                    optionsGender.filter(
                      (o) => o.value === patientResponse.gender,
                    ).length > 0
                      ? optionsGender.filter(
                          (o) => o.value === patientResponse.gender,
                        )[0].index
                      : -1,
                  ssn: patientResponse.ssn,
                  emailAddress: patientResponse.emailAddress,
                  emailAddressState:
                    reg.test(patientResponse.emailAddress) === true
                      ? 'has-success'
                      : 'has-danger',
                  addressId: patientResponse.addressHome
                    ? patientResponse.addressHome.id
                    : '',
                  line1: patientResponse.addressHome
                    ? patientResponse.addressHome.line1
                    : '',
                  line2: patientResponse.addressHome
                    ? patientResponse.addressHome.line2
                    : '',
                  city: patientResponse.addressHome
                    ? patientResponse.addressHome.city
                    : '',
                  county: patientResponse.addressHome
                    ? patientResponse.addressHome.county
                    : '',
                  state:
                    patientResponse.addressHome &&
                    patientResponse.addressHome.state
                      ? patientResponse.addressHome.state
                      : '',
                  stateIndex:
                    patientResponse.addressHome &&
                    patientResponse.addressHome.state
                      ? optionsState.filter(
                          (o) => o.value === patientResponse.addressHome.state,
                        ).length > 0
                        ? optionsState.filter(
                            (o) =>
                              o.value === patientResponse.addressHome.state,
                          )[0].index
                        : -1
                      : -1,
                  postalCode: patientResponse.addressHome
                    ? patientResponse.addressHome.postalCode
                    : '',

                  patientPolicy: patientPolicy,

                  medications: patientResponse.medications
                    ? patientResponse.medications.map((medication) => {
                        return {
                          id: medication.id,
                          text: medication.name,
                          name: medication.name,
                        };
                      })
                    : [],
                  pcpId: patientResponse.pcpId,
                  primaryCarePhysician: patientResponse.primaryCarePhysician,
                  referringPhysicianId: patientResponse.referringPhysicianId,
                  pharmacyId: patientResponse.pharmacyId,
                  pharmacyPostalCode: patientResponse.pharmacyPostalCode
                    ? patientResponse.pharmacyPostalCode
                    : patientResponse.addressHome
                    ? patientResponse.addressHome.postalCode
                    : '',

                  allergyHistory: patientResponse.allergyHistory,
                  medicalHistory: patientResponse.medicalHistory,
                  surgicalHistory: patientResponse.surgicalHistory,
                  familyHistory: patientResponse.familyHistory,
                  socialHistory: patientResponse.socialHistory,

                  timeWakeUp: patientResponse.timeWakeUp
                    ? new moment(patientResponse.timeWakeUp).format('h:mm a')
                    : this.state.timeWakeUp,
                  timeBreakfast: patientResponse.timeBreakfast
                    ? new moment(patientResponse.timeBreakfast).format('H:mm a')
                    : this.state.timeBreakfast,
                  timeLunch: patientResponse.timeLunch
                    ? new moment(patientResponse.timeLunch).format('h:mm a')
                    : this.state.timeLunch,
                  timeDinner: patientResponse.timeDinner
                    ? new moment(patientResponse.timeDinner).format('h:mm a')
                    : this.state.timeDinner,
                  timeSleep: patientResponse.timeSleep
                    ? new moment(patientResponse.timeSleep).format('h:mm a')
                    : this.state.timeSleep,
                  glucoseLevelHypo: patientResponse.glucoseLevelHypo,
                  glucoseLevelHypoState: patientResponse.glucoseLevelHypo
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelGoal: patientResponse.glucoseLevelGoal,
                  glucoseLevelGoalState: patientResponse.glucoseLevelGoal
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelHigh: patientResponse.glucoseLevelHigh,
                  glucoseLevelHighState: patientResponse.glucoseLevelHigh
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelDanger: patientResponse.glucoseLevelDanger,
                  glucoseLevelDangerState: patientResponse.glucoseLevelDanger
                    ? 'has-success'
                    : 'has-danger',
                  glucoseLevelImpossible:
                    patientResponse.glucoseLevelImpossible,
                  glucoseLevelImpossibleState: patientResponse.glucoseLevelImpossible
                    ? 'has-success'
                    : 'has-danger',

                  addedByName: patientResponse.addedByName
                    ? patientResponse.addedByName
                    : 'unknown',
                  dateAdded:
                    new Date(patientResponse.dateAdded).toLocaleDateString() +
                    ' ' +
                    new Date(patientResponse.dateAdded).toLocaleTimeString(),
                  updatedByName: patientResponse.updatedByName
                    ? patientResponse.updatedByName
                    : 'unknown',
                  dateUpdated:
                    new Date(patientResponse.dateUpdated).toLocaleDateString() +
                    ' ' +
                    new Date(patientResponse.dateUpdated).toLocaleTimeString(),
                });

                //do we have a state?
                //yes
                if (
                  patientResponse.addressHome &&
                  patientResponse.addressHome &&
                  patientResponse.addressHome.state
                ) {
                  //load the providers
                  this.loadPCPByState(
                    optionsState.filter(
                      (o) => o.value === patientResponse.addressHome.state,
                    )[0].value,
                    this.state.city,
                    patientResponse.pcpId,
                  );
                  //load the providers
                  this.loadPhysicianByState(
                    optionsState.filter(
                      (o) => o.value === patientResponse.addressHome.state,
                    )[0].value,
                    this.state.city,
                    patientResponse.referringPhysicianId,
                  );
                }

                //do we have a pharmacy postal code?
                //yes
                if (this.state.pharmacyPostalCode) {
                  //load the pharmacies
                  this.loadPharmacyByPostalCode(
                    this.state.pharmacyPostalCode,
                    patientResponse.pharmacyId,
                  );
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: error,
              disabled: false,
              loading: false,
            });
          });
      }
      //do we have a group id?
      //no
      else {
        this.loadPCPByState('', '');
        this.loadPhysicianByState('', '');
        this.setState({ loading: false });
      }
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  //this submits the form
  submit = async () => {
    //did we disable the button?
    //yes
    if (this.state.disabled) {
      return;
    }

    //do we have a first name?
    //no
    if (this.state.firstName.length === 0) {
      this.setState({
        error: 'Please enter their first name',
      });
      return;
    }

    //do we have a last name?
    //no
    if (this.state.lastName.length === 0) {
      this.setState({
        error: 'Please enter their last name',
      });
      return;
    }

    //do we have a phoneMobile?
    //no
    if (this.state.phoneMobile.length < 10) {
      this.setState({
        error: 'Please enter their phone number',
      });
      return;
    }

    //do we have an email address?
    //no
    if (
      this.isValidDate(this.state.dateOfBirth) === false ||
      new Date().getFullYear() -
        new Date(this.state.dateOfBirth).getFullYear() <=
        0 ||
      new Date().getFullYear() -
        new Date(this.state.dateOfBirth).getFullYear() >
        105
    ) {
      this.setState({
        error: 'Please enter a valid date of birth',
      });
      return;
    }

    //do we have an email address?
    //no
    if (this.state.emailAddress) {
      let reg = /.+@.+\..+/;
      if (reg.test(this.state.emailAddress) === false) {
        this.setState({
          error: 'Please enter a valid email address',
        });
        return;
      }
    }

    const physician = this.state.pcpSearchResponse[
      this.state.pcpSearchSelectIndex
    ];
    const address = physician.practiceAddress;

    // POST request using fetch with error handling
    var postOptions = {
      method: this.state.patientId === '' ? 'POST' : 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
      body: JSON.stringify({
        //we set id because this is the patient record
        id: this.state.patientId,
        testPatient: this.state.testPatient,
        firstName: this.state.firstName,
        middleName: this.state.middleName,
        lastName: this.state.lastName,
        nickName: this.state.nickName,
        phoneMobile: this.state.phoneMobile,
        emailAddress: this.state.emailAddress,
        gender: this.state.gender,
        dateOfBirth: this.state.dateOfBirth,
        ssn: this.state.ssn,
        pcpId: this.state.pcpId,
        primaryCarePhysician: {
          id: physician.id,
          prefix: physician.prefix,
          firstName: physician.firstName,
          middleName: physician.middleName,
          lastName: physician.lastName,
          suffix: physician.suffix,
          npi: physician.npi,
          practiceName: physician.practiceName,
          phoneNumber: physician.phoneNumber,
          faxNumber: physician.faxNumber,
          emailAddress: physician.emailAddress,
          practiceAddress: {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
          },
        },
        referringPhysicianId: this.state.referringPhysicianId,
        pharmacyId: this.state.pharmacyId,
        pharmacyPostalCode: this.state.pharmacyPostalCode,
        addressHome: {
          id: this.state.addressId,
          line1: this.state.line1,
          line2: this.state.line2,
          city: this.state.city,
          county: this.state.county,
          state: this.state.state,
          postalCode: this.state.postalCode,
        },

        //insurance information
        patientPolicy: this.state.patientPolicy,

        medications: this.state.medications,

        allergyHistory: this.state.allergyHistory,
        medicalHistory: this.state.medicalHistory,
        surgicalHistory: this.state.surgicalHistory,
        familyHistory: this.state.familyHistory,
        socialHistory: this.state.socialHistory,

        timeWakeUp: this.state.timeWakeUp,
        timeBreakfast: this.state.timeBreakfast,
        timeLunch: this.state.timeLunch,
        timeDinner: this.state.timeDinner,
        timeSleep: this.state.timeSleep,
        glucoseLevelHypo: this.state.glucoseLevelHypo,
        glucoseLevelGoal: this.state.glucoseLevelGoal,
        glucoseLevelHigh: this.state.glucoseLevelHigh,
        glucoseLevelDanger: this.state.glucoseLevelDanger,
        glucoseLevelImpossible: this.state.glucoseLevelImpossible,
        basalInsulinId: this.state.basalInsulinId,
        basalInsulinName: this.state.basalInsulinName,
        bolusInsulinId: this.state.bolusInsulinId,
        bolusInsulinName: this.state.bolusInsulinName,
        mixedInsulinId: this.state.mixedInsulinId,
        mixedInsulinName: this.state.mixedInsulinName,
      }),
    };

    await fetch(window.configuration.PatientURL, postOptions)
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          //did we get an unauthorized?
          //no
          else {
            //set the error
            this.setState({
              error: 'We were unable to add the patient',
              disabled: false,
              loading: false,
            });
          }
          // get error message from body or default to response status
          const error = response.status;
          return Promise.reject(error);
        }
        //did we get an error?
        //no
        else {
          this.Cancel();
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          disabled: false,
          loading: false,
        });
      });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
    if (
      this.state.pcpSearchResponse.length > 0 ||
      this.state.pcpSearchSelectIndex
    ) {
      this.setState({ pcpSearchResponse: [], pcpSearchSelectIndex: null });
    }
  };

  pcpSearch = async () => {
    const secret = localStorage.getItem('SECRET');
    const passphrase = localStorage.getItem('PASSPHRASE');
    try {
      await Axios.get(config.PhysicianURL, {
        params: {
          firstName: this.state.pcpSearchFName,
          lastName: this.state.pcpSearchLName,
          city: this.state.pcpSearchCity,
          state: this.state.pcpSearchState,
          postalCode: this.state.pcpSearchPostal,
        },
        headers: {
          SECRET: secret,
          PASSPHRASE: passphrase,
        },
      }).then(({ data }) => {
        this.setState({
          pcpSearchResponse: data,
        });
      });
    } catch (err) {
      console.warn(err);
    }
  };

  setPatientPcp = async () => {
    const secret = localStorage.getItem('SECRET');
    const passphrase = localStorage.getItem('PASSPHRASE');

    const physician = this.state.pcpSearchResponse[
      this.state.pcpSearchSelectIndex
    ];
    const address = physician.practiceAddress;
    try {
      await Axios.post(
        config.PhysicianURL,
        {
          id: physician.id,
          prefix: physician.prefix,
          firstName: physician.firstName,
          middleName: physician.middleName,
          lastName: physician.lastName,
          suffix: physician.suffix,
          npi: physician.npi,
          practiceName: physician.practiceName,
          phoneNumber: physician.phoneNumber,
          faxNumber: physician.faxNumber,
          emailAddress: physician.emailAddress,
          practiceAddress: {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
          },
        },
        {
          headers: {
            SECRET: secret,
            PASSPHRASE: passphrase,
          },
        },
      ).then(({ status }) => {
        if (status === 200) {
          this.submit();
        }
      });
    } catch (err) {
      console.warn(err);
    }
  };

  render() {
    //are we loading?
    //yes
    if (this.state.loading) {
      return (
        <Loader
          height="0"
          width="0"
          type="ThreeDots"
          color="#00BFFF"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            background: '#99CCFF',
            opacity: '0.6',
            zIndex: '998',
            height: '100%',
            width: '100%',
          }}
        />
      );
    }
    //are we loading?
    //no
    else {
      return (
        <>
          {this.state.loading ? (
            <Loader
              height="0"
              width="0"
              type="ThreeDots"
              color="#00BFFF"
              style={{
                position: 'fixed',
                top: '0',
                left: '0',
                background: '#99CCFF',
                opacity: '0.6',
                zIndex: '998',
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            ''
          )}
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col xs={12} lg={9}>
                <Form>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        {this.state.patientId
                          ? this.state.testPatient === true
                            ? 'UPDATE TEST PATIENT'
                            : 'Update patient'
                          : 'Add patient'}
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.firstState}
                          >
                            <Label>First Name *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="50"
                              onChange={(e) =>
                                this.setState({
                                  firstName: e.target.value,
                                  firstNameState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoFocus
                              autoCorrect="true"
                              value={this.state.firstName}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.middleNameState
                            }
                          >
                            <Label>Middle Name</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="50"
                              onChange={(e) =>
                                this.setState({
                                  middleName: e.target.value,
                                  middleNameState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-success',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.middleName}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.lastNameState}
                          >
                            <Label>Last Name *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="50"
                              onChange={(e) =>
                                this.setState({
                                  lastName: e.target.value,
                                  lastNameState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.lastName}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup className={'has-label'}>
                            <Label>Preferred Name</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="50"
                              onChange={(e) =>
                                this.setState({
                                  nickName: e.target.value,
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.nickName}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label'}>
                            <Label>Gender *</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsGender}
                              onChange={(item) => {
                                this.setState({
                                  genderIndex: item.index,
                                  gender: item.value,
                                });
                              }}
                              value={optionsGender[this.state.genderIndex]}
                              placeholder="Select a gender for this patient"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.dateOfBirthState
                            }
                          >
                            <Label>Date of Birth *</Label>
                            <Input
                              type="input"
                              maxLength="10"
                              value={this.state.dateOfBirth}
                              onChange={(e) => {
                                this.setState({
                                  dateOfBirth: e.target.value,
                                  error: '',
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={'has-label ' + this.state.ssnState}
                          >
                            <Label>SSN *</Label>
                            <Input
                              type="text"
                              maxLength="11"
                              onChange={(e) => {
                                this.setState({
                                  ssn: e.target.value,
                                  ssnState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                });
                              }}
                              autoCorrect="true"
                              value={
                                this.state.ssn
                                  ? this.formatSSN(this.state.ssn)
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}>
                          <FormGroup className={'has-label'}>
                            <h6>Contact Information</h6>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={8}>
                          <FormGroup
                            className={'has-label ' + this.state.line1State}
                          >
                            <Label>Address *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="300"
                              onChange={(e) =>
                                this.setState({
                                  line1: e.target.value,
                                  line1State:
                                    e.target.value.length > 4
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.line1}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.phoneMobileState
                            }
                          >
                            <Label>Cell Phone Number *</Label>
                            <PhoneInput
                              inputProps={{
                                name: 'phone',
                                required: true,
                              }}
                              country={'us'}
                              regions={'north-america'}
                              placeholder="(512)-555-1212"
                              disableDropdown={true}
                              disableCountryCode={true}
                              countryCodeEditable={true}
                              inputStyle={{
                                fontSize: 16,
                                height: 40,
                                paddingLeft: 50,
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                width: '100%',
                              }}
                              value={this.state.phoneMobile}
                              onChange={(text) => this.phoneUpdated(text)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={8}>
                          <FormGroup
                            className={'has-label ' + this.state.line2State}
                          >
                            <Label>Suite, apartment, etc.</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="300"
                              onChange={(e) =>
                                this.setState({
                                  line2: e.target.value,
                                  line2State:
                                    e.target.value.length > 4
                                      ? 'has-success'
                                      : 'has-success',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.line2}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.emailAddressState
                            }
                          >
                            <Label>Email Address *</Label>
                            <Input
                              type="text"
                              maxLength="250"
                              onChange={(e) =>
                                this.setState({
                                  emailAddress: e.target.value,
                                  emailAddressState:
                                    e.target.value.length >= 5
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.emailAddress}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.cityState}
                          >
                            <Label>City *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="300"
                              onChange={(e) =>
                                this.setState({
                                  city: e.target.value,
                                  cityState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.city}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.countyState}
                          >
                            <Label>County</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="300"
                              onChange={(e) =>
                                this.setState({
                                  county: e.target.value,
                                  countyState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.county}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup className={'has-label'}>
                            <Label>State *</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsState}
                              onChange={(item) => {
                                this.setState({
                                  stateIndex: item.index,
                                  state: item.value,
                                });

                                this.loadPCPByState(
                                  item.value,
                                  this.state.city,
                                );
                                this.loadPhysicianByState(
                                  item.value,
                                  this.state.city,
                                );
                              }}
                              value={optionsState[this.state.stateIndex]}
                              placeholder="Select a state for this patient"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.postalCodeState
                            }
                          >
                            <Label>Zip Code *</Label>
                            <Input
                              type="text"
                              maxLength="5"
                              onChange={(e) => {
                                this.setState({
                                  postalCode: e.target.value,
                                  postalCodeState:
                                    e.target.value.length > 4
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                });
                              }}
                              value={this.state.postalCode}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}>
                          <FormGroup className={'has-label'}>
                            <h6>Insurance Information</h6>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={3}>
                          <FormGroup className={'has-label'}>
                            <Label>Insurance Company</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={this.state.payorData}
                              onChange={(item) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.payorId = item.value;
                                patientPolicy.payorIndex = item.index;

                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={
                                this.state.patientPolicy.payorIndex >= 0
                                  ? this.state.payorData[
                                      this.state.patientPolicy.payorIndex
                                    ]
                                  : ''
                              }
                              placeholder="Select an insurance company"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label '}>
                            <Label>Policy Number</Label>
                            <Input
                              type="text"
                              maxLength="50"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyNumber = e.target.value;

                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={this.state.patientPolicy.policyNumber}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label '}>
                            <Label>Group Number</Label>
                            <Input
                              type="text"
                              maxLength="50"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.groupNumber = e.target.value;

                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={this.state.patientPolicy.groupNumber}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label '}>
                            <Label>Copay</Label>
                            <Input
                              type="text"
                              maxLength="10"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.copay = e.target.value.replace(
                                  '$',
                                  '',
                                );
                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={
                                this.state.patientPolicy.copay
                                  ? this.state.patientPolicy.copay
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup className={'has-label'}>
                            <Label>Policy holder</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsRelation}
                              onChange={(item) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.relationship = item.value;
                                patientPolicy.relationshipIndex = item.index;

                                var showPolicyHolder =
                                  item.value === '18' ? 'none' : '';

                                this.setState({
                                  showPolicyHolder,
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={
                                this.state.patientPolicy.relationshipIndex >= 0
                                  ? optionsRelation[
                                      this.state.patientPolicy.relationshipIndex
                                    ]
                                  : ''
                              }
                              placeholder="Select the relation to patient"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ display: this.state.showPolicyHolder }}>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}>
                          <FormGroup className={'has-label'}>
                            <h6>Policy Holder Information</h6>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ display: this.state.showPolicyHolder }}>
                        <Col xs={12} md={3}>
                          <FormGroup className={'has-label '}>
                            <Label>First Name *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="50"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyHolder.firstName =
                                  e.target.value;
                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              autoCorrect="true"
                              value={
                                this.state.patientPolicy.policyHolder &&
                                this.state.patientPolicy.policyHolder.firstName
                                  ? this.state.patientPolicy.policyHolder
                                      .firstName
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.lastNameState}
                          >
                            <Label>Last Name *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="50"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyHolder.lastName =
                                  e.target.value;
                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              autoCorrect="true"
                              value={
                                this.state.patientPolicy.policyHolder &&
                                this.state.patientPolicy.policyHolder.lastName
                                  ? this.state.patientPolicy.policyHolder
                                      .lastName
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label'}>
                            <Label>Gender *</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsGender}
                              onChange={(item) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyHolder.genderIndex =
                                  item.index;
                                patientPolicy.policyHolder.gender = item.value;

                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={
                                this.state.patientPolicy.policyHolder
                                  ? optionsGender[
                                      this.state.patientPolicy.policyHolder
                                        .genderIndex
                                    ]
                                  : ''
                              }
                              placeholder="Select their gender"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label'}>
                            <Label>Date of Birth *</Label>
                            <Input
                              type="text"
                              maxLength="10"
                              value={
                                this.state.patientPolicy.policyHolder &&
                                this.state.patientPolicy.policyHolder
                                  .dateOfBirth
                                  ? this.state.patientPolicy.policyHolder
                                      .dateOfBirth
                                  : ''
                              }
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyHolder.dateOfBirth =
                                  e.target.value;
                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={'has-label ' + this.state.ssnState}
                          >
                            <Label>SSN *</Label>
                            <Input
                              type="text"
                              maxLength="11"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyHolder.ssn = e.target.value;
                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={
                                this.state.patientPolicy.policyHolder &&
                                this.state.patientPolicy.policyHolder.ssn
                                  ? this.formatSSN(
                                      this.state.patientPolicy.policyHolder.ssn,
                                    )
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ display: this.state.showPolicyHolder }}>
                        <Col xs={12} md={8}>
                          <FormGroup className={'has-label '}>
                            <Label>Address *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="300"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyHolder.addressHome.line1 =
                                  e.target.value;
                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              autoCorrect="true"
                              value={
                                this.state.patientPolicy.policyHolder &&
                                this.state.patientPolicy.policyHolder
                                  .addressHome.line1
                                  ? this.state.patientPolicy.policyHolder
                                      .addressHome.line1
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ display: this.state.showPolicyHolder }}>
                        <Col xs={12} md={8}>
                          <FormGroup
                            className={'has-label ' + this.state.line2State}
                          >
                            <Label>Suite, apartment, etc.</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="300"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyHolder.addressHome.line2 =
                                  e.target.value;
                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              autoCorrect="true"
                              value={
                                this.state.patientPolicy.policyHolder &&
                                this.state.patientPolicy.policyHolder
                                  .addressHome.line2
                                  ? this.state.patientPolicy.policyHolder
                                      .addressHome.line2
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ display: this.state.showPolicyHolder }}>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={'has-label ' + this.state.cityState}
                          >
                            <Label>City *</Label>
                            <Input
                              type="text"
                              style={{ textTransform: 'capitalize' }}
                              maxLength="300"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyHolder.addressHome.city =
                                  e.target.value;
                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              autoCorrect="true"
                              value={
                                this.state.patientPolicy.policyHolder &&
                                this.state.patientPolicy.policyHolder
                                  .addressHome.city
                                  ? this.state.patientPolicy.policyHolder
                                      .addressHome.city
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label'}>
                            <Label>State *</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsState}
                              onChange={(item) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyHolder.addressHome.state =
                                  item.value;
                                patientPolicy.policyHolder.addressHome.stateIndex =
                                  item.index;

                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={
                                this.state.patientPolicy.policyHolder &&
                                this.state.patientPolicy.policyHolder
                                  .addressHome
                                  ? optionsState[
                                      this.state.patientPolicy.policyHolder
                                        .addressHome.stateIndex
                                    ]
                                  : ''
                              }
                              placeholder="Select their state"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.postalCodeState
                            }
                          >
                            <Label>Zip Code *</Label>
                            <Input
                              type="text"
                              maxLength="5"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.policyHolder.addressHome.postalCode =
                                  e.target.value;
                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={
                                this.state.patientPolicy.policyHolder &&
                                this.state.patientPolicy.policyHolder
                                  .addressHome
                                  ? this.state.patientPolicy.policyHolder
                                      .addressHome.postalCode
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={3}>
                          <FormGroup className={'has-label '}>
                            <Label>Effective Date</Label>
                            <Input
                              type="text"
                              maxLength="10"
                              onChange={(e) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.dateStart = e.target.value;

                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={
                                this.state.patientPolicy.dateStart
                                  ? moment(
                                      this.state.patientPolicy.dateStart,
                                    ).format('MM/DD/yyyy')
                                  : ''
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup className={'has-label'}>
                            <Label>Medicare Code</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={optionsMedicareCode}
                              onChange={(item) => {
                                var patientPolicy = this.state.patientPolicy;
                                patientPolicy.medicareCode = item.value;
                                patientPolicy.medicareCodeIndex = item.index;

                                this.setState({
                                  patientPolicy,
                                  error: '',
                                });
                              }}
                              value={
                                this.state.patientPolicy.medicareCodeIndex >= 0
                                  ? optionsMedicareCode[
                                      this.state.patientPolicy.medicareCodeIndex
                                    ]
                                  : ''
                              }
                              placeholder="Select the policy holder's medicare code"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={12}
                          md={6}
                          style={{
                            textAlign: 'center',
                            display: this.state.insuranceCardFront
                              ? ''
                              : 'none',
                          }}
                        >
                          <img
                            src={this.state.patientPolicy.insuranceCardFront}
                            alt="front"
                          ></img>
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          style={{
                            textAlign: 'center',
                            display: this.state.insuranceCardBack ? '' : 'none',
                          }}
                        >
                          <img
                            src={this.state.patientPolicy.insuranceCardBack}
                            alt="back"
                          ></img>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <ImageUploader
                            withIcon={false}
                            onChange={(image) => {
                              var reader = new FileReader();
                              reader.readAsDataURL(image[0]);

                              reader.onloadend = function (e) {
                                this.setState({
                                  insuranceCardFront: reader.result,
                                });
                              }.bind(this);
                            }}
                            imgExtension={['.jpg', '.gif', '.png']}
                            maxFileSize={5242880}
                            singleImage={true}
                            label="Select front of insurance card (jpg, gif, or png)"
                            buttonText="Select front of the insurance card"
                            withPreview={false}
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <ImageUploader
                            withIcon={false}
                            onChange={(image) => {
                              var reader = new FileReader();
                              reader.readAsDataURL(image[0]);

                              reader.onloadend = function (e) {
                                this.setState({
                                  insuranceCardBack: reader.result,
                                });
                              }.bind(this);
                            }}
                            imgExtension={['.jpg', '.gif', '.png']}
                            maxFileSize={5242880}
                            singleImage={true}
                            label="Select back of insurance card (jpg, gif, or png)"
                            buttonText="Select back of the insurance card"
                            withPreview={false}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ paddingTop: 20 }}>
                          <FormGroup className={'has-label'}>
                            <h6>Medical Information</h6>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={5}>
                          <Label>Primary Care Physician</Label>
                          <div>
                            {this.state.primaryCarePhysician?.firstName ? (
                              <>
                                <p>{`${this.state.primaryCarePhysician.prefix} ${this.state.primaryCarePhysician.firstName} ${this.state.primaryCarePhysician.lastName}, ${this.state.primaryCarePhysician.suffix}`}</p>
                                <p>{`${this.state.primaryCarePhysician.practiceAddress.city}, ${this.state.primaryCarePhysician.practiceAddress.state} ${this.state.primaryCarePhysician.practiceAddress.postalCode}`}</p>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                          <Button onClick={this.toggle} color="primary">
                            Select PCP
                          </Button>
                          <Modal isOpen={this.state.modal} toggle={this.toggle}>
                            <ModalHeader toggle={this.toggle}>
                              Select PCP
                            </ModalHeader>
                            <ModalBody>
                              <Row>
                                <Col xs={12} md={6}>
                                  <FormGroup>
                                    <Label>First Name</Label>
                                    <Input
                                      type="text"
                                      value={this.state.pcpSearchFName}
                                      onChange={(event) => {
                                        this.setState({
                                          pcpSearchFName: event.target.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                  <FormGroup>
                                    <Label>Last Name</Label>
                                    <Input
                                      type="text"
                                      value={this.state.pcpSearchLName}
                                      onChange={(event) => {
                                        this.setState({
                                          pcpSearchLName: event.target.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={4}>
                                  <FormGroup>
                                    <Label>City</Label>
                                    <Input
                                      type="text"
                                      value={this.state.pcpSearchCity}
                                      onChange={(event) => {
                                        this.setState({
                                          pcpSearchCity: event.target.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={12} md={4}>
                                  <FormGroup>
                                    <Label>State</Label>
                                    <Select
                                      className="react-select"
                                      classNamePrefix="react-select"
                                      options={optionsState}
                                      onChange={(item) => {
                                        this.setState({
                                          pcpSearchState: item.value,
                                        });
                                      }}
                                      value={
                                        optionsState[this.state.pcpSearchState]
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={12} md={4}>
                                  <FormGroup>
                                    <Label>Postal Code</Label>
                                    <Input
                                      maxLength={5}
                                      type="number"
                                      value={this.state.pcpSearchPostal}
                                      onChange={(event) => {
                                        this.setState({
                                          pcpSearchPostal: event.target.value,
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={12}>
                                  <Button
                                    color="primary"
                                    onClick={this.pcpSearch}
                                    size="sm"
                                  >
                                    Search
                                  </Button>
                                </Col>
                              </Row>
                              <ListGroup>
                                {this.state.pcpSearchResponse.map(
                                  (
                                    {
                                      firstName,
                                      lastName,
                                      practiceAddress,
                                      prefix,
                                    },
                                    index,
                                  ) => (
                                    <ListGroupItem
                                      style={{ cursor: 'pointer', zIndex: 1 }}
                                      active={
                                        this.state.pcpSearchSelectIndex ===
                                        index
                                      }
                                      onClick={() => {
                                        console.log(
                                          this.state.pcpSearchResponse[index],
                                        );
                                        this.setState({
                                          pcpSearchSelectIndex: index,
                                        });
                                      }}
                                      key={index}
                                    >
                                      {`${prefix} ${firstName} ${lastName} - ${practiceAddress.city}, ${practiceAddress.state} ${practiceAddress.postalCode}`}
                                    </ListGroupItem>
                                  ),
                                )}
                              </ListGroup>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="success"
                                onClick={this.setPatientPcp}
                              >
                                Select PCP
                              </Button>{' '}
                              <Button color="secondary" onClick={this.toggle}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </Col>
                        <Col xs={12} md={5}>
                          <FormGroup className={'has-label'}>
                            <Label>Referring Physician</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={this.state.referringPhysicianData}
                              onChange={(item) => {
                                this.setState({
                                  referringPhysicianId: item.value,
                                  referringPhysicianIndex: item.index,
                                });
                              }}
                              value={
                                this.state.referringPhysicianIndex >= 0
                                  ? this.state.referringPhysicianData[
                                      this.state.referringPhysicianIndex
                                    ]
                                  : ''
                              }
                              placeholder="Select a referring provider"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label '}>
                            <Label>Pharmacy Zip</Label>
                            <Input
                              type="text"
                              maxLength="5"
                              onChange={(e) => {
                                //do we have a valid postal code?
                                //yes
                                if (e.target.value.length > 4) {
                                  //load the pharmacies
                                  this.loadPharmacyByPostalCode(
                                    e.target.value,
                                    this.state.selectedPharmacyId,
                                  );
                                }

                                this.setState({
                                  pharmacyPostalCode: e.target.value,
                                  error: '',
                                });
                              }}
                              value={this.state.pharmacyPostalCode}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={5}>
                          <FormGroup className={'has-label'}>
                            <Label>Pharmacy</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={this.state.pharmacyData}
                              onChange={(item) => {
                                this.setState({
                                  pharmacyId: item.value,
                                  pharmacyIndex: item.index,
                                });
                              }}
                              value={
                                this.state.pharmacyIndex >= 0
                                  ? this.state.pharmacyData[
                                      this.state.pharmacyIndex
                                    ]
                                  : ''
                              }
                              placeholder="Select a pharmacy"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={9}>
                          <FormGroup className={'has-label '}>
                            <Label>
                              Select the patient's medications (if not found
                              enter to the right)
                            </Label>
                            <Multiselect
                              className="react-select"
                              classNamePrefix="react-select"
                              displayValue="name"
                              options={this.state.medicationsData}
                              selectedValues={this.state.medications}
                              onSelect={(selectedList) => {
                                //set the data
                                this.setState({ medications: selectedList });
                              }}
                              onRemove={(selectedList) => {
                                //set the data
                                this.setState({ medications: selectedList });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={3}>
                          <FormGroup className={'has-label'}>
                            <Label>
                              Add new patient medication + &lt;return&gt;
                            </Label>
                            <Input
                              type="text"
                              maxLength="100"
                              onChange={(e) => {
                                this.setState({
                                  medicationName: e.target.value,
                                  error: '',
                                });
                              }}
                              onKeyDown={this._handleNewMedication}
                              autoCorrect="true"
                              value={this.state.medicationName}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup className={'has-label'}>
                            <Label>Allergies</Label>
                            <Input
                              type="textarea"
                              style={{
                                border: '1px solid #dadada',
                                minHeight: 200,
                              }}
                              onChange={(e) =>
                                this.setState({
                                  allergyHistory: e.target.value,
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.allergyHistory}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup className={'has-label'}>
                            <Label>Medical History</Label>
                            <Input
                              type="textarea"
                              style={{
                                border: '1px solid #dadada',
                                minHeight: 200,
                              }}
                              onChange={(e) =>
                                this.setState({
                                  medicalHistory: e.target.value,
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.medicalHistory}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup className={'has-label'}>
                            <Label>Surgical History</Label>
                            <Input
                              type="textarea"
                              style={{
                                border: '1px solid #dadada',
                                minHeight: 200,
                              }}
                              onChange={(e) =>
                                this.setState({
                                  surgicalHistory: e.target.value,
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.surgicalHistory}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup className={'has-label'}>
                            <Label>Family History</Label>
                            <Input
                              type="textarea"
                              style={{
                                border: '1px solid #dadada',
                                minHeight: 200,
                              }}
                              onChange={(e) =>
                                this.setState({
                                  familyHistory: e.target.value,
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.familyHistory}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup className={'has-label'}>
                            <Label>Social History</Label>
                            <Input
                              type="textarea"
                              style={{
                                border: '1px solid #dadada',
                                minHeight: 200,
                              }}
                              onChange={(e) =>
                                this.setState({
                                  socialHistory: e.target.value,
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.socialHistory}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12} style={{ paddingTop: 20 }}>
                          <FormGroup className={'has-label'}>
                            <h6>Daily Schedule</h6>
                            <Label>
                              Please verify the times the patient does the
                              various things.
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.timeWakeUpState
                            }
                          >
                            <Label>Wake Up</Label>
                            <Input
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  timeWakeUp: e.target.value
                                    .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                    .toUpperCase(),
                                  timeWakeUpState:
                                    e.target.value.length > 3
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.timeWakeUp}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.timeBreakfastState
                            }
                          >
                            <Label>Eat Breakfast</Label>
                            <Input
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  timeBreakfast: e.target.value
                                    .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                    .toUpperCase(),
                                  timeBreakfastState:
                                    e.target.value.length > 3
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.timeBreakfast}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={'has-label ' + this.state.timeLunchState}
                          >
                            <Label>Eat Lunch</Label>
                            <Input
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  timeLunch: e.target.value
                                    .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                    .toUpperCase(),
                                  timeLunchState:
                                    e.target.value.length > 3
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.timeLunch}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.timeDinnerState
                            }
                          >
                            <Label>Eat Dinner</Label>
                            <Input
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  timeDinner: e.target.value
                                    .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                    .toUpperCase(),
                                  timeDinnerState:
                                    e.target.value.length > 3
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.timeDinner}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={'has-label ' + this.state.timeSleepState}
                          >
                            <Label>Go to Sleep</Label>
                            <Input
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  //just times
                                  timeSleep: e.target.value
                                    .replace(/[b-ln-oq-zB-LN-OQ-Z]/, '')
                                    .toUpperCase(),
                                  timeSleepState:
                                    e.target.value.length > 3
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                })
                              }
                              autoCorrect="true"
                              value={this.state.timeSleep}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12} style={{ paddingTop: 20 }}>
                          <FormGroup className={'has-label'}>
                            <h6>Blood Glucose Levels</h6>
                            <Label>
                              Please verify the following blood glucose levels.
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.glucoseLevelHypoState
                            }
                          >
                            <Label>Hypo</Label>
                            <Input
                              pattern="[0-9]*"
                              type="text"
                              maxLength={3}
                              onChange={(e) => {
                                this.setState({
                                  glucoseLevelHypo: e.target.value.replace(
                                    /\D/,
                                    '',
                                  ),
                                  glucoseLevelHypoState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                });
                              }}
                              value={this.state.glucoseLevelHypo}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.glucoseLevelGoalState
                            }
                          >
                            <Label>Goal</Label>
                            <Input
                              pattern="[0-9]*"
                              type="text"
                              maxLength={3}
                              onChange={(e) => {
                                this.setState({
                                  glucoseLevelGoal: e.target.value.replace(
                                    /\D/,
                                    '',
                                  ),
                                  glucoseLevelGoalState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                });
                              }}
                              value={this.state.glucoseLevelGoal}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.glucoseLevelHighState
                            }
                          >
                            <Label>High Level</Label>
                            <Input
                              pattern="[0-9]*"
                              type="text"
                              maxLength={3}
                              onChange={(e) => {
                                this.setState({
                                  glucoseLevelHigh: e.target.value.replace(
                                    /\D/,
                                    '',
                                  ),
                                  glucoseLevelHighState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                });
                              }}
                              value={this.state.glucoseLevelHigh}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' + this.state.glucoseLevelDangerState
                            }
                          >
                            <Label>Danger Level</Label>
                            <Input
                              pattern="[0-9]*"
                              type="text"
                              maxLength={3}
                              onChange={(e) => {
                                this.setState({
                                  glucoseLevelDanger: e.target.value.replace(
                                    /\D/,
                                    '',
                                  ),
                                  glucoseLevelDangerState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                });
                              }}
                              value={this.state.glucoseLevelDanger}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                          <FormGroup
                            className={
                              'has-label ' +
                              this.state.glucoseLevelImpossibleState
                            }
                          >
                            <Label>Impossible Level</Label>
                            <Input
                              pattern="[0-9]*"
                              type="text"
                              maxLength={3}
                              onChange={(e) => {
                                this.setState({
                                  glucoseLevelImpossible: e.target.value.replace(
                                    /\D/,
                                    '',
                                  ),
                                  glucoseLevelImpossibleState:
                                    e.target.value.length > 0
                                      ? 'has-success'
                                      : 'has-danger',
                                  error: '',
                                });
                              }}
                              value={this.state.glucoseLevelImpossible}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 20 }}>
                        <Col xs={12} md={2}>
                          <FormGroup className={'has-label'}>
                            <Label>QA Test Patient: </Label>
                            <Switch
                              checked={this.state.testPatient === true}
                              onChange={(e) => {
                                this.setState({
                                  testPatient: e.target.checked,
                                  error: '',
                                });
                              }}
                              color="primary"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginTop: 10,
                          display:
                            this.state.patientId.length > 1 ? '' : 'none',
                        }}
                      >
                        <Col xs={12} md={2}>
                          <FormGroup>
                            <Label>Added:</Label>
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={10}>
                          <FormGroup>
                            <Label>
                              {this.state.dateAdded +
                                ' by ' +
                                this.state.addedByName}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col
                          xs={12}
                          md={2}
                          style={{
                            display: this.state.updatedByName ? '' : 'none',
                          }}
                        >
                          <FormGroup>
                            <Label>Last Updated:</Label>
                          </FormGroup>
                        </Col>
                        <Col
                          xs={12}
                          md={10}
                          style={{
                            display: this.state.updatedByName ? '' : 'none',
                          }}
                        >
                          <FormGroup>
                            <Label>
                              {this.state.dateUpdated +
                                ' by ' +
                                this.state.updatedByName}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            style={{
                              textAlign: 'end',
                              display: this.state.error ? '' : 'none',
                            }}
                          >
                            <Label
                              style={{
                                fontSize: 14,
                                color: 'tomato',
                                paddingRight: 15,
                                display: this.state.error ? '' : 'none',
                              }}
                            >
                              {this.state.error}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                      <Button color="success" onClick={(e) => this.submit(e)}>
                        {this.state.disabled
                          ? 'One moment...'
                          : this.state.patientId
                          ? 'Update patient'
                          : 'Add patient'}
                      </Button>
                      {/* spacer between buttons */ ' '}
                      <Button
                        style={{ marginLeft: 10 }}
                        color="secondary"
                        onClick={() => this.Cancel()}
                      >
                        Cancel
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default PatientForm;
