/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Input,
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';

const queryString = require('query-string');

class RXNTPatientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request: '',
      response: '',
      error: '',
      loading: true,
    };
  }

  Cancel() {
    this.props.history.push('/in/l/rxntpatients#top');
  }

  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);

      //this is our base to get the data
      var requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          SECRET: localStorage.getItem('SECRET'),
          PASSPHRASE: localStorage.getItem('PASSPHRASE'),
        },
      };

      var qs = queryString.parse(this.props.location.search);
      //do we have a group id?
      //yes
      if (qs['id']) {
        fetch(
          window.configuration.RXNTPatientURL + '?id=' + qs['id'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to load the patient request',
                  disabled: true,
                  loading: false,
                });
              }

              // get error message from body or default to response status
              const error = response.status;
              return Promise.reject(error);
            }
            //did we get an error?
            //no
            else {
              var patientRequest = await response.json();

              //did we get a good one?
              //yes
              if (patientRequest.id) {
                var responses = patientRequest.responses;
                var request = patientRequest;
                delete request.responses;

                this.setState({
                  error: '',
                  loading: false,
                  disabled: false,
                  request: request,
                  response: responses,
                  addedByName: patientRequest.addedByName
                    ? patientRequest.addedByName
                    : 'unknown',
                  dateAdded:
                    new Date(patientRequest.dateAdded).toLocaleDateString() +
                    ' ' +
                    new Date(patientRequest.dateAdded).toLocaleTimeString(),
                  updatedByName: patientRequest.updatedByName
                    ? patientRequest.updatedByName
                    : 'unknown',
                  dateUpdated:
                    new Date(patientRequest.dateUpdated).toLocaleDateString() +
                    ' ' +
                    new Date(patientRequest.dateUpdated).toLocaleTimeString(),
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: error,
              disabled: false,
              loading: false,
            });
          });
      }
      //do we have a group id?
      //no
      else {
        this.setState({ loading: false });
      }
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  render() {
    //are we loading?
    //yes
    if (this.state.loading) {
      return (
        <Loader
          height="0"
          width="0"
          type="ThreeDots"
          color="#00BFFF"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            background: '#99CCFF',
            opacity: '0.6',
            zIndex: '998',
            height: '100%',
            width: '100%',
          }}
        />
      );
    }
    //are we loading?
    //no
    else {
      return (
        <>
          {this.state.loading ? (
            <Loader
              height="0"
              width="0"
              type="ThreeDots"
              color="#00BFFF"
              style={{
                position: 'fixed',
                top: '0',
                left: '0',
                background: '#99CCFF',
                opacity: '0.6',
                zIndex: '998',
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            ''
          )}
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col xs={12} lg={10}>
                <Form>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">RXNT Patient Request</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup className={'has-label'}>
                            <Label>Request</Label>
                            <Input
                              type="textarea"
                              value={JSON.stringify(this.state.request)}
                              style={{ minHeight: 400 }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup className={'has-label'}>
                            <Label>Response</Label>
                            <Input
                              type="textarea"
                              value={JSON.stringify(this.state.response)}
                              style={{ minHeight: 100 }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            style={{
                              textAlign: 'end',
                              display: this.state.error ? '' : 'none',
                            }}
                          >
                            <Label
                              style={{
                                fontSize: 14,
                                color: 'tomato',
                                paddingRight: 15,
                                display: this.state.error ? '' : 'none',
                              }}
                            >
                              {this.state.error}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginTop: 20,
                          display: this.state.request ? '' : 'none',
                        }}
                      >
                        <Col xs={12} md={2}>
                          <FormGroup>
                            <Label>Added:</Label>
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={10}>
                          <FormGroup>
                            <Label>
                              {this.state.dateAdded +
                                ' by ' +
                                this.state.addedByName}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col
                          xs={12}
                          md={2}
                          style={{
                            display: this.state.updatedByName ? '' : 'none',
                          }}
                        >
                          <FormGroup>
                            <Label>Last Updated:</Label>
                          </FormGroup>
                        </Col>
                        <Col
                          xs={12}
                          md={10}
                          style={{
                            display: this.state.updatedByName ? '' : 'none',
                          }}
                        >
                          <FormGroup>
                            <Label>
                              {this.state.dateUpdated +
                                ' by ' +
                                this.state.updatedByName}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                      <Button
                        style={{ marginLeft: 10 }}
                        color="secondary"
                        onClick={() => this.Cancel()}
                      >
                        Done
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default RXNTPatientForm;
