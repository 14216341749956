import React from 'react';
import MessageBubble from 'components/Messaging/MessageBubble';
import ScrollToBottom from 'react-scroll-to-bottom';
import { css } from 'emotion';

const ROOT_CSS = css({
  height: '100%',
  overflowY: 'auto',
});

const MessageArea = ({ activeThread, user }) => {
  return (
    <ScrollToBottom className={ROOT_CSS}>
      {activeThread?.messages?.map(
        ({ sendingId, text, dateSent, sendingName, _id }) => (
          <MessageBubble
            key={_id}
            admin={user}
            message={text}
            sender={sendingName}
            senderId={sendingId}
            sentTime={dateSent}
          />
        ),
      )}
    </ScrollToBottom>
  );
};

export default MessageArea;
