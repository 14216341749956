import React, { useEffect, useState } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from 'layouts/Admin.jsx';
import AuthLayout from 'layouts/Auth.jsx';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import Loader from 'react-loader-spinner';

const hist = createBrowserHistory();

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loginStatus = localStorage.getItem('LOGGEDIN');
    const loginExpiration = localStorage.getItem('DATEEXPIRES');
    const loginValid = moment(loginExpiration).isAfter(moment());

    if (loginStatus && loginStatus === 'true') {
      if (loginExpiration && loginValid) {
        setLoggedIn(true);
        return setLoading(false);
      }
    }
    setLoading(false);
  }, []);

  if (loading) return <Loader />;

  return (
    <Router history={hist}>
      <Switch>
        {/* this is for logged in users */}
        <Route
          path="/in"
          render={(props) => {
            return <AdminLayout {...props} />;
          }}
        />
        {/* this is the login page */}
        <Route
          path="/a"
          render={(props) => {
            return <AuthLayout {...props} />;
          }}
        />
        {/* if logged in direct to dashboard otherwise login screen */}
        <Redirect to={loggedIn ? '/in/dashboard' : '/a/login'} />
      </Switch>
    </Router>
  );
};

export default App;
