/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

//pages
import Dashboard from 'views/Dashboard/Dashboard.jsx';
import LoginPage from 'views/Pages/LoginPage.jsx';
import LogoutPage from 'views/Pages/LogoutPage.jsx';

//forms
import AppErrorForm from 'views/Forms/AppErrorForm.jsx';
import BillingCodeForm from 'views/Forms/BillingCodeForm.jsx';
import MedicationForm from 'views/Forms/MedicationForm.jsx';
import MemberForm from 'views/Forms/MemberForm.jsx';
import PatientForm from 'views/Forms/PatientForm.jsx';
import PayorForm from 'views/Forms/PayorForm.jsx';
import GoogleAuthForm from 'views/Forms/GoogleAuthForm.jsx';
import InsulinForm from 'views/Forms/InsulinForm';
import InsulinPrescriptionForm from 'views/Forms/InsulinPrescriptionForm';
import EducationContentForm from 'views/Forms/EducationContentForm';
import GlucoseReadingsForm from 'views/Forms/GlucoseReadingsForm';
import PortalErrorForm from 'views/Forms/PortalErrorForm.jsx';
import RXNTCaseForm from 'views/Forms/RXNTCaseForm';
import RXNTPatientForm from 'views/Forms/RXNTPatientForm';
import RXNTPayerForm from 'views/Forms/RXNTPayerForm';
import RXNTProviderForm from 'views/Forms/RXNTProviderForm';

//lists
import AppErrorList from 'views/Lists/AppErrorList.jsx';
import BillingCodeList from 'views/Lists/BillingCodeList.jsx';
import MedicationList from 'views/Lists/MedicationList.jsx';
import MemberList from 'views/Lists/MemberList.jsx';
import PatientList from 'views/Lists/PatientList.jsx';
import PayorList from 'views/Lists/PayorList.jsx';
import InsulinList from 'views/Lists/InsulinList';
import InsulinPrescriptionList from 'views/Lists/InsulinPrescriptionList';
import EducationContentList from 'views/Lists/EducationContentList';
import AuthList from 'views/Lists/AuthList.jsx';
import AppAuthList from 'views/Lists/AppAuthList.jsx';
import PortalErrorList from 'views/Lists/PortalErrorList.jsx';
import RXNTCaseList from 'views/Lists/RXNTCaseList.jsx';
import RXNTPatientList from 'views/Lists/RXNTPatientList.jsx';
import RXNTPayerList from 'views/Lists/RXNTPayerList.jsx';
import RXNTProviderList from 'views/Lists/RXNTProviderList.jsx';

//other
import Messaging from 'views/Messaging';
import InsulinPrescriptionAudit from 'views/Audits/InsulinPrescriptionAudit';

let routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fa fa-home',
    component: Dashboard,
    layout: '/in',
    hidden: false,
    admin: true,
    ma: true,
    provider: true,
  },
  {
    path: '/f/patient',
    name: 'Add a patient',
    icon: 'fa fa-user',
    component: PatientForm,
    layout: '/in',
    hidden: false,
    admin: true,
    ma: true,
    provider: true,
  },
  {
    path: '/messaging/:id',
    name: 'Messaging',
    icon: 'fa fa-comment',
    component: Messaging,
    layout: '/in',
    hidden: false,
    admin: true,
    ma: true,
    provider: true,
  },
  {
    path: '/l/insulinprescriptions',
    name: 'Patient Insulin Prescriptions',
    component: InsulinPrescriptionList,
    layout: '/in',
    hidden: true,
    admin: true,
    ma: true,
    provider: true,
  },
  {
    path: '/f/insulinprescription',
    name: 'Patient Insulin Prescription',
    component: InsulinPrescriptionForm,
    layout: '/in',
    hidden: true,
    admin: true,
    ma: true,
    provider: true,
  },
  {
    path: '/v/insulinprescription',
    name: 'View Patient Insulin Prescription',
    component: InsulinPrescriptionAudit,
    layout: '/in',
    hidden: true,
    admin: true,
    ma: true,
    provider: true,
  },
  {
    path: '/f/glucosereadings',
    name: 'Patient Glucose Readings',
    component: GlucoseReadingsForm,
    layout: '/in',
    hidden: true,
    admin: true,
    ma: true,
    provider: true,
  },
  {
    path: '/l/patients',
    name: 'Patients',
    icon: 'fa fa-user-injured',
    component: PatientList,
    layout: '/in',
    hidden: false,
    admin: true,
    ma: true,
    provider: true,
  },
  {
    collapse: true,
    path: '#dosehelper',
    name: 'Admin',
    state: 'openAdmin',
    icon: 'fa fa-tools',
    hidden: false,
    admin: true,
    ma: true,
    provider: true,
    views: [
      {
        mini: 'AA',
        path: '/l/appauths',
        name: 'App Auths',
        component: AppAuthList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'AE',
        path: '/l/apperrors',
        name: 'App Errors',
        component: AppErrorList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        path: '/f/apperror',
        name: 'App Error',
        component: AppErrorForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'AU',
        path: '/l/auths',
        name: 'Auths',
        component: AuthList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'BC',
        path: '/l/billingcodes',
        name: 'Billing Codes',
        component: BillingCodeList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        path: '/f/billingcode',
        name: 'Billing Code',
        component: BillingCodeForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'EC',
        path: '/l/educationcontent',
        name: 'Educational Content',
        component: EducationContentList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: true,
        provider: true,
      },
      {
        path: '/f/educationcontent',
        name: 'Education Content',
        component: EducationContentForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: true,
        provider: true,
      },
      {
        mini: 'I',
        path: '/l/insulins',
        name: 'Insulins',
        component: InsulinList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        path: '/f/insulin',
        name: 'Insulin',
        component: InsulinForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'M',
        path: '/l/medications',
        name: 'Medications',
        component: MedicationList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: true,
        provider: true,
      },
      {
        path: '/f/medication',
        name: 'Medication',
        component: MedicationForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: true,
        provider: true,
      },
      {
        mini: 'P',
        path: '/l/payors',
        name: 'Payors',
        component: PayorList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        path: '/f/payor',
        name: 'Payor',
        component: PayorForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'PE',
        path: '/l/portalerrors',
        name: 'Portal Errors',
        component: PortalErrorList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        path: '/f/portalerror',
        name: 'Portal Error',
        component: PortalErrorForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'RP',
        path: '/l/rxntpatients',
        name: 'RXNT Patient Requests',
        component: RXNTPatientList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        path: '/f/rxntpatient',
        name: 'RXNT Patient',
        component: RXNTPatientForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'RPa',
        path: '/l/rxntpayers',
        name: 'RXNT Payer Requests',
        component: RXNTPayerList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        path: '/f/rxntpayer',
        name: 'RXNT Payer',
        component: RXNTPayerForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'RPC',
        path: '/l/rxntcases',
        name: 'RXNT Patient Case Requests',
        component: RXNTCaseList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        path: '/f/rxntcase',
        name: 'RXNT Case',
        component: RXNTCaseForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'RP',
        path: '/l/rxntproviders',
        name: 'RXNT Provider Requests',
        component: RXNTProviderList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        path: '/f/rxntprovider',
        name: 'RXNT Provider',
        component: RXNTProviderForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: 'SM',
        path: '/l/members',
        name: 'Staff Members',
        component: MemberList,
        layout: '/in',
        hidden: false,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        path: '/f/member',
        name: 'Member',
        component: MemberForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
      {
        mini: '',
        path: '/f/googleauth',
        name: 'Google Auth',
        component: GoogleAuthForm,
        layout: '/in',
        hidden: true,
        admin: true,
        ma: false,
        provider: false,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    icon: '',
    component: LoginPage,
    layout: '/a',
    hidden: true,
    admin: true,
    ma: true,
    provider: true,
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: 'now-ui-icons media-1_button-power',
    component: LogoutPage,
    layout: '/a',
    hidden: false,
    admin: true,
    ma: true,
    provider: true,
  },
];

export default routes;
