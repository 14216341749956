import React, { useState } from 'react';
import moment from 'moment';
import UserAvatar from 'react-user-avatar';

const styles = {
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  l2Left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  patientName: {
    fontSize: 18,
    fontWeight: 700,
    fontFamily: 'Montserrat',
    color: '#e8e8e8',
    marginBottom: 8,
  },
  timeSent: {
    fontSize: 18,
    fontFamily: 'Montserrat',
    color: '#e8e8e8',
    marginBottom: 8,
  },
  messagePreview: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    color: '#fff',
    marginLeft: 16,
    marginBottom: 0,
  },
  unreadMessage: {
    backgroundColor: 'red',
    width: 20,
    height: 20,
    borderRadius: 4,
    marginLeft: 4,
  },
  hr: {
    border: 0,
    height: 0,
    margin: '16px 0 0 0',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderBottom: '1px solid #787878',
  },
};

const ThreadItem = ({ thread, admin, onClick }) => {
  const [hover, setHover] = useState(false);

  const me = `${admin?.firstName} ${admin?.lastName}`;
  const patient = `${thread?.firstName} ${thread?.lastName}`;
  const recentMessage = thread?.messages[thread?.messages?.length - 1];

  const avatar = () => {
    if (recentMessage?.sendingName === patient) {
      return <UserAvatar color="#54e4fc" size={40} name={patient} />;
    }
    return <UserAvatar color="#55abf9" size={40} name={me} />;
  };

  const snippet = () => {
    const message = recentMessage?.text;
    const stringArr = message.split(' ');
    if (stringArr.length > 10) {
      return `${stringArr.slice(0, 9).join(' ')}...`;
    }
    return message;
  };

  const unreadMessage = () => {
    if (
      recentMessage?.sendingName === patient &&
      recentMessage?.read === false
    ) {
      return <div style={styles.unreadMessage} />;
    }
    return null;
  };

  return (
    <div
      style={{
        padding: '8px 16px',
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        backgroundColor: hover ? '#374467' : 'transparent',
        cursor: hover && 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <div style={styles.line}>
        <p style={styles.patientName}>{patient}</p>
        <p style={styles.timeSent}>
          {
            //is this sent today?
            moment(recentMessage?.dateSent).format('M/D/YY') ===
            moment(new Date()).format('M/D/YY')
              ? //yes - mark it as today
                'Today at '
              : //no - was it sent yesterday?
              moment(recentMessage?.dateSent).format('M/D/YY') ===
                moment(new Date()).add(-1, 'day').format('M/D/YY')
              ? //yes - mark it as yesterday
                'Yesterday at '
              : //no - mark the date
                moment(recentMessage?.dateSent).format('M/D/YY') + ' at '
          }
          {moment(recentMessage?.dateSent).format('h:mm a')}
        </p>
      </div>
      <div style={styles.line}>
        <div style={styles.l2Left}>
          {avatar()}
          <p style={styles.messagePreview}>{snippet()}</p>
        </div>
        <div>{unreadMessage()}</div>
      </div>
      <hr style={styles.hr} />
    </div>
  );
};
export default ThreadItem;
