/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
// react plugin used to create charts
import { CardTitle } from 'reactstrap';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import ReactGA from 'react-ga';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      disabled: false,
      error: '',
      loading: true,
    };
  }

  //we get the data from the system on this call
  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.setState({ loading: false });
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height="0"
            width="0"
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              background: '#99CCFF',
              opacity: '0.6',
              zIndex: '998',
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          ''
        )}
        <PanelHeader
          size={'sm'}
          content={
            <>
              <CardTitle style={{ color: '#FFFFFF', textAlign: 'center' }}>
                <h4>Dose Helper</h4>
              </CardTitle>
            </>
          }
        />
        <div className="content" style={{ marginTop: 5 }}>
          Welcome
        </div>
      </>
    );
  }
}

export default Dashboard;
