/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';

const queryString = require('query-string');

class GoogleAuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      googleImage: '',
      googleManualEntry: '',
      firstName: '',
      lastName: '',
      emailAddressWork: '',
      disabled: false,
      error: '',
      loading: true,
    };
  }

  Cancel() {
    this.props.history.push('/in/l/members#top');
  }

  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);

      this.loadData();
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  loadData() {
    //this is our base to get the data
    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
    };

    var qs = queryString.parse(this.props.location.search);
    //do we have a group id?
    //yes
    if (qs['id']) {
      fetch(
        window.configuration.GoogleAuthURL + '?userId=' + qs['id'],
        requestOptions,
      )
        .then(async (response) => {
          // check for error response
          //did we get an error?
          //yes
          if (!response.ok) {
            //did we get an unauthorized?
            //yes
            if (response.status === 401) {
              this.props.history.push('/a/logout');
            }
            //did we get an unauthorized?
            //no
            else {
              //set the error
              this.setState({
                error:
                  'We were unable to get the Google Authenticator image for this member',
                disabled: true,
                loading: false,
              });
            }
          }
          //did we get an error?
          //no
          else {
            var memberResponse = await response.json();

            //did we get a good one?
            //yes
            if (memberResponse.manualEntryCode) {
              this.setState({
                error: '',
                loading: false,
                disabled: false,
                googleImage: memberResponse.image,
                googleManualEntry: memberResponse.manualEntryCode,
                firstName: memberResponse.firstName,
                lastName: memberResponse.lastName,
                emailAddressWork: memberResponse.emailAddressWork,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            error: error,
            disabled: false,
            loading: false,
          });
        });
    }
    //do we have a group id?
    //no
    else {
      this.setState({ loading: false });
    }
  }

  render() {
    //are we redirecting?
    //no - loading
    if (this.state.loading) {
      return (
        <Loader
          height="0"
          width="0"
          type="ThreeDots"
          color="#00BFFF"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            background: '#99CCFF',
            opacity: '0.6',
            zIndex: '998',
            height: '100%',
            width: '100%',
          }}
        />
      );
    } else {
      return (
        <>
          {this.state.loading ? (
            <Loader
              height="0"
              width="0"
              type="ThreeDots"
              color="#00BFFF"
              style={{
                position: 'fixed',
                top: '0',
                left: '0',
                background: '#99CCFF',
                opacity: '0.6',
                zIndex: '998',
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            ''
          )}
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col xs={12} md={5}>
                <Form>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4" style={{ textAlign: 'center' }}>
                        Staff Member Google Authenticator Image
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                          <FormGroup className={'has-label '}>
                            <img
                              src={this.state.googleImage}
                              alt="Google Auth"
                              style={{
                                width: 300,
                                height: 300,
                                display: this.state.googleImage ? '' : 'none',
                              }}
                            ></img>
                            <br />
                            <Label
                              style={{
                                fontSize: 14,
                              }}
                            >
                              {this.state.firstName + ' ' + this.state.lastName}
                            </Label>
                            <br />
                            <Label
                              style={{
                                fontSize: 14,
                              }}
                            >
                              {this.state.emailAddressWork}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            style={{
                              textAlign: 'end',
                              display: this.state.error ? '' : 'none',
                            }}
                          >
                            <Label
                              style={{
                                fontSize: 14,
                                color: 'tomato',
                                paddingRight: 15,
                                display: this.state.error ? '' : 'none',
                              }}
                            >
                              {this.state.error}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                      <Button color="success" onClick={() => this.Cancel()}>
                        {this.state.disabled && !this.state.error
                          ? 'One moment...'
                          : 'Back to Members'}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default GoogleAuthForm;
