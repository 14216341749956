import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Redirect, Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga';
import moment from 'moment';

class PatientList extends Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.state = {
      data: [],
      loading: true,
      filtered: [],
      disabled: false,
    };
  }

  //we get the data from the system on this call
  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.loadData(this);
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  setError(error, disabled, loading) {
    this.setState({
      error,
      disabled,
      loading,
    });
  }

  loadData(myThis) {
    // GET request using fetch with error handling
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
    };

    fetch(window.configuration.PatientURL, requestOptions)
      .then(async (response) => {
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          return null;
        }
        //did we get an error?
        //no
        else {
          const patients = await response.json();

          const roleAdmin = localStorage.getItem('Role_Admin') === 'true';

          //create our rows
          const patientObjects = patients.map(
            ({
              id,
              firstName,
              lastName,
              phoneMobile,
              emailAddress,
              addressHome: { city, state },
              billingId,
              deviceType,
              dateAppInstall,
              enabled,
              updatedByName,
              dateUpdated,
              testPatient,
            }) => {
              const name = `${firstName} ${lastName}`;
              return {
                id,
                name,
                phoneMobile: phoneMobile
                  ? phoneMobile.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3')
                  : '',
                emailAddress,
                city: city ? city : '',
                state: state ? state : '',
                billingFlag: billingId ? 'Yes' : 'No',
                deviceType,
                dateAppInstall: dateAppInstall
                  ? moment(dateAppInstall).format('MM/DD/YY')
                  : '',
                enabled,
                updatedByName: updatedByName ? updatedByName : 'Unknown',
                dateUpdated: moment(dateUpdated).format('MM/DD/YY'),
                actions: (
                  // we've added some custom button actions
                  <div className="actions-right">
                    <ReactTooltip />
                    <Link to={`/in/messaging/${id}`}>
                      <Button
                        data-tip="Message patient"
                        className="btn-icon btn-round"
                        color="info"
                        size="sm"
                      >
                        <i className="fa fa-comment-medical" />
                      </Button>
                    </Link>
                    {/* spacer between buttons */ ' '}
                    <Link
                      to={`/in/f/glucosereadings?patientId=${id}&testPatient=${testPatient}#top`}
                    >
                      <Button
                        data-tip="review glucose readings"
                        className="btn-icon btn-round"
                        color={'success'}
                        size="sm"
                      >
                        <i className="fa fa-table" />
                      </Button>
                    </Link>
                    {/* spacer between buttons */ ' '}
                    <Link
                      to={`/in/l/insulinprescriptions?patientId=${id}&patientName=${firstName}${lastName}&testPatient=${testPatient}#top`}
                    >
                      <Button
                        data-tip="manage patient insulin regimen"
                        className="btn-icon btn-round"
                        size="sm"
                        style={{ backgroundColor: '#808080' }}
                      >
                        <i className="fa fa-prescription-bottle-alt" />
                      </Button>
                    </Link>
                    {/* spacer between buttons */ ' '}
                    <Link
                      to={`/in/l/insulinprescriptions?audit=true&patientId=${id}&patientName=${firstName}${lastName}&testPatient=${testPatient}#top`}
                    >
                      <Button
                        data-tip="audit patient insulin regimen"
                        className="btn-icon btn-round"
                        style={{
                          display: !roleAdmin && 'none',
                          backgroundColor: '#808080',
                        }}
                        size="sm"
                      >
                        <i className="fa fa-eye" />
                      </Button>
                    </Link>
                    {/* spacer between buttons */ ' '}
                    <Link to={`/in/f/patient?id=${id}`}>
                      <Button
                        data-tip="edit"
                        className="btn-icon btn-round"
                        color={'success'}
                        size="sm"
                      >
                        <i className="fa fa-edit" />
                      </Button>
                    </Link>
                    {/* spacer between buttons */ ' '}
                    <Button
                      data-tip={enabled === true ? 'disable' : 'enable'}
                      onClick={() => {
                        //confirm that they want to remove it
                        confirmAlert({
                          closeOnEscape: true,
                          closeOnClickOutside: true,
                          message: `Are you sure you want to ${
                            enabled === true ? 'disable' : 'enable'
                          } the patient ${name}?`,
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                // DELETE request using fetch with error handling
                                const requestOptionsPatch = {
                                  method: 'PATCH',
                                  headers: {
                                    'Content-Type': 'application/json',
                                    SECRET: localStorage.getItem('SECRET'),
                                    PASSPHRASE: localStorage.getItem(
                                      'PASSPHRASE',
                                    ),
                                  },
                                  body: JSON.stringify({
                                    id: id,
                                    enabled: !enabled,
                                  }),
                                };

                                fetch(
                                  window.configuration.PatientURL,
                                  requestOptionsPatch,
                                )
                                  .then(async (response) => {
                                    // check for error response
                                    //did we get an error?
                                    //no
                                    if (response.ok) {
                                      //refresh the page
                                      myThis.loadData(myThis);
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              },
                            },
                            {
                              label: 'No',
                            },
                          ],
                        });
                      }}
                      className="btn-icon btn-round"
                      color={enabled === true ? 'success' : 'danger'}
                      size="sm"
                    >
                      <i
                        className={
                          enabled === true
                            ? 'fa fa-toggle-on'
                            : 'fa fa-toggle-off'
                        }
                      />
                    </Button>
                    {/* spacer between buttons */ ' '}
                    <Button
                      data-tip="remove"
                      onClick={() => {
                        //confirm that they want to remove it
                        confirmAlert({
                          closeOnEscape: true,
                          closeOnClickOutside: true,
                          message: `Are you sure you want to remove the patient ${name}?`,
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                // DELETE request using fetch with error handling
                                const requestOptionsDelete = {
                                  method: 'DELETE',
                                  headers: {
                                    'Content-Type': 'application/json',
                                    SECRET: localStorage.getItem('SECRET'),
                                    PASSPHRASE: localStorage.getItem(
                                      'PASSPHRASE',
                                    ),
                                  },
                                };

                                fetch(
                                  `${window.configuration.PatientURL}?id=${id}`,
                                  requestOptionsDelete,
                                )
                                  .then(async (response) => {
                                    // check for error response
                                    //did we get an error?
                                    //no
                                    if (response.ok) {
                                      //refresh the page
                                      myThis.loadData(myThis);
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              },
                            },
                            {
                              label: 'No',
                            },
                          ],
                        });
                      }}
                      className="btn-icon btn-round"
                      color={'danger'}
                      size="sm"
                    >
                      <i className="fa fa-times" />
                    </Button>
                  </div>
                ),
              };
            },
          );

          //grab our response
          this.setState({
            data: patientObjects,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          loading: false,
        });
        return null;
      });
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  render() {
    //are we redirecting?
    //yes
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div style={{ width: '100%', padding: 16, overflow: 'auto' }}>
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Patients</CardTitle>
                  <CardTitle>
                    <Link style={{ color: 'blue' }} to={'/in/f/patient'}>
                      Add a new patient
                    </Link>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    loading={this.state.loading}
                    style={{ width: '100%' }}
                    data={this.state.data}
                    filterable={true}
                    filtered={this.state.filtered}
                    onFilteredChange={(filtered, column, value) => {
                      this.onFilteredChangeCustom(
                        value,
                        column.id || column.accessor,
                      );
                    }}
                    defaultFilterMethod={(filter, row, column) => {
                      const id = filter.pivotId || filter.id;
                      if (typeof filter.value === 'object') {
                        return row[id] !== undefined
                          ? filter.value.indexOf(row[id]) > -1
                          : true;
                      } else {
                        return row[id] !== undefined
                          ? String(row[id].toLowerCase()).indexOf(
                              filter.value.toLowerCase(),
                            ) > -1
                          : true;
                      }
                    }}
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'name',
                        width: 220,
                      },
                      {
                        Header: 'Phone #',
                        accessor: 'phoneMobile',
                        width: 120,
                      },
                      {
                        Header: 'Device',
                        accessor: 'deviceType',
                        width: 80,
                      },
                      {
                        Header: 'Installed',
                        accessor: 'dateAppInstall',
                        width: 95,
                        //sort the dates
                        sortMethod: (a, b) => {
                          a = new Date(a).getTime();
                          b = new Date(b).getTime();
                          return b > a ? 1 : -1;
                        },
                      },
                      {
                        Header: 'Email Address',
                        accessor: 'emailAddress',
                        width: 200,
                      },
                      {
                        Header: 'City',
                        accessor: 'city',
                        width: 160,
                      },
                      {
                        Header: 'State',
                        accessor: 'state',
                        width: 60,
                      },
                      {
                        Header: 'Bill',
                        accessor: 'billingFlag',
                        width: 60,
                      },
                      {
                        Header: 'Updated',
                        accessor: 'dateUpdated',
                        width: 95,
                        //sort the dates
                        sortMethod: (a, b) => {
                          a = new Date(a).getTime();
                          b = new Date(b).getTime();
                          return b > a ? 1 : -1;
                        },
                      },
                      {
                        Header: 'Updated By',
                        accessor: 'updatedByName',
                        width: 150,
                      },
                      {
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={15}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PatientList;
