/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React, { Component } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga';
import moment from 'moment';

const queryString = require('query-string');

class InsulinPrescriptionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      patientName: '',
      loading: true,
      filtered: [],
      disabled: false,
    };
  }

  //we get the data from the system on this call
  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.loadData(this);
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  setError(error, disabled, loading) {
    this.setState({
      error,
      disabled,
      loading,
    });
  }

  loadData(myThis) {
    // GET request using fetch with error handling
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
    };

    var qs = queryString.parse(this.props.location.search);

    fetch(
      window.configuration.InsulinPrescriptionURL +
        '?patientId=' +
        qs['patientId'],
      requestOptions,
    )
      .then(async (response) => {
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          return null;
        }
        //did we get an error?
        //no
        else {
          var insulinPrescriptions = await response.json();

          //create our rows
          var insulinPrescriptionObjects = insulinPrescriptions.map(function (
            insulinPrescription,
          ) {
            return {
              id: insulinPrescription.id,
              patientId: qs['patientId'],
              dateStart: insulinPrescription.dateStart
                ? moment(insulinPrescription.dateStart).format('MM/DD/yyyy')
                : '',
              morningTrackGlucose: insulinPrescription.morningTrackGlucose
                ? 'Yes'
                : 'No',
              morningDose: insulinPrescription.morningDose
                ? insulinPrescription.morningInsulinName
                  ? insulinPrescription.morningInsulinName +
                    ' (' +
                    insulinPrescription.morningDose +
                    ')'
                  : ''
                : '',
              breakfastTrackGlucose: insulinPrescription.breakfastTrackGlucose
                ? 'Yes'
                : 'No',
              breakfastDose: insulinPrescription.breakfastDose
                ? insulinPrescription.breakfastMedicationName +
                  ' (' +
                  insulinPrescription.breakfastDose +
                  ')'
                : '',
              breakfastDose: insulinPrescription.breakfastDose
                ? insulinPrescription.breakfastInsulinName
                  ? insulinPrescription.breakfastInsulinName +
                    ' (' +
                    insulinPrescription.breakfastDose +
                    ')'
                  : ''
                : '',
              lunchTrackGlucose: insulinPrescription.lunchTrackGlucose
                ? 'Yes'
                : 'No',
              lunchDose: insulinPrescription.lunchDose
                ? insulinPrescription.lunchInsulinName
                  ? insulinPrescription.lunchInsulinName +
                    ' (' +
                    insulinPrescription.lunchDose +
                    ')'
                  : ''
                : '',
              dinnerTrackGlucose: insulinPrescription.dinnerTrackGlucose
                ? 'Yes'
                : 'No',
              dinnerDose: insulinPrescription.dinnerDose
                ? insulinPrescription.dinnerInsulinName
                  ? insulinPrescription.dinnerInsulinName +
                    ' (' +
                    insulinPrescription.dinnerDose +
                    ')'
                  : ''
                : '',
              nighttimeTrackGlucose: insulinPrescription.nighttimeTrackGlucose
                ? 'Yes'
                : 'No',
              nighttimeDose: insulinPrescription.nighttimeDose
                ? insulinPrescription.nighttimeInsulinName
                  ? insulinPrescription.nighttimeInsulinName +
                    ' (' +
                    insulinPrescription.nighttimeDose +
                    ')'
                  : ''
                : '',
              enabled: insulinPrescription.enabled,
              addedByName: insulinPrescription.addedByName
                ? insulinPrescription.addedByName
                : 'unknown',
              dateAdded: new Date(
                insulinPrescription.dateAdded,
              ).toLocaleDateString(),
              updatedByName: insulinPrescription.updatedByName
                ? insulinPrescription.updatedByName
                : 'unknown',
              dateUpdated: new Date(
                insulinPrescription.dateUpdated,
              ).toLocaleDateString(),
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  <ReactTooltip />
                  <Link
                    to={
                      '/in/f/insulinPrescription?id=' +
                      insulinPrescription.id +
                      '&patientId=' +
                      qs['patientId']
                    }
                  >
                    <Button
                      data-tip="view/duplicate this precription"
                      className="btn-icon btn-round"
                      color={'success'}
                      size="sm"
                    >
                      <i className="fa fa-edit" />
                    </Button>
                  </Link>
                </div>
              ),
            };
          });

          //this is an ask by Dustyn and rather than rewrite this I am just putting in some simple logic
          //we can make this more accurate and have this page be a supporting one for auditing.
          //do we have any?
          //yes
          if (insulinPrescriptionObjects.length > 0) {
            //send them to the audit or to the latest
            this.props.history.push(
              '/in/' +
                (qs['audit'] ? 'v' : 'f') +
                '/insulinPrescription?id=' +
                insulinPrescriptionObjects[0].id +
                '&patientId=' +
                qs['patientId'] +
                '&testPatient=' +
                qs['testPatient'] +
                '&patientName=' +
                qs['patientName'],
            );
            return;
          }
          //do we have any?
          //no
          else {
            //send them to the audit or build a new one
            this.props.history.push(
              '/in/' +
                (qs['audit'] ? 'v' : 'f') +
                '/insulinPrescription?patientId=' +
                qs['patientId'] +
                '&testPatient=' +
                qs['testPatient'] +
                '&patientName=' +
                qs['patientName'],
            );
            return;
          }

          /*
        for when we need to prop it back up for auditing
        //grab our response
        this.setState(
        {
          patientName: qs["patientName"],
          data: insulinPrescriptionObjects,
          loading: false
        });
        */
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: error,
          loading: false,
        });
        return null;
      });
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  render() {
    //are we loading?
    //yes
    if (this.state.loading === true) {
      return (
        <>
          <Loader
            height="0"
            width="0"
            type="ThreeDots"
            color="#00BFFF"
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              background: '#99CCFF',
              opacity: '0.6',
              zIndex: '998',
              height: '100%',
              width: '100%',
            }}
          />
        </>
      );
    }

    var qs = queryString.parse(this.props.location.search);

    return (
      <>
        {this.state.loading ? (
          <Loader
            height="0"
            width="0"
            type="ThreeDots"
            color="#00BFFF"
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              background: '#99CCFF',
              opacity: '0.6',
              zIndex: '998',
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          ''
        )}
        <PanelHeader size="sm" />
        <div className="content" id="top">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Insulin Regimens{' '}
                    {this.state.patientName
                      ? '(' + this.state.patientName + ')'
                      : ''}
                  </CardTitle>
                  <CardTitle>
                    <Link
                      style={{
                        color: 'blue',
                        display: this.state.data.length === 0 ? '' : 'none',
                      }}
                      to={
                        '/in/f/insulinprescription?patientId=' + qs['patientId']
                      }
                    >
                      Add a new insulin regimen or&nbsp;
                    </Link>
                    <Link style={{ color: 'blue' }} to={'/in/l/patients'}>
                      go back to patients
                    </Link>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable={true}
                    filtered={this.state.filtered}
                    onFilteredChange={(filtered, column, value) => {
                      this.onFilteredChangeCustom(
                        value,
                        column.id || column.accessor,
                      );
                    }}
                    defaultFilterMethod={(filter, row, column) => {
                      const id = filter.pivotId || filter.id;
                      if (typeof filter.value === 'object') {
                        return row[id] !== undefined
                          ? filter.value.indexOf(row[id]) > -1
                          : true;
                      } else {
                        return row[id] !== undefined
                          ? String(row[id].toLowerCase()).indexOf(
                              filter.value.toLowerCase(),
                            ) > -1
                          : true;
                      }
                    }}
                    columns={[
                      {
                        Header: 'Effective',
                        accessor: 'dateStart',
                        width: 100,
                        sortable: true,
                        filterable: false,
                      },
                      {
                        Header: 'Wake Up',
                        accessor: 'morningTrackGlucose',
                        width: 70,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Dose',
                        accessor: 'morningDose',
                        width: 140,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Breakfast',
                        accessor: 'breakfastTrackGlucose',
                        width: 90,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Dose',
                        accessor: 'breakfastDose',
                        width: 140,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Lunch',
                        accessor: 'lunchTrackGlucose',
                        width: 60,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Dose',
                        accessor: 'lunchDose',
                        width: 140,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Dinner',
                        accessor: 'dinnerTrackGlucose',
                        width: 70,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Dose',
                        accessor: 'dinnerDose',
                        width: 140,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Bedtime',
                        accessor: 'nighttimeTrackGlucose',
                        width: 70,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Dose',
                        accessor: 'nighttimeDose',
                        width: 140,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Updated',
                        accessor: 'dateUpdated',
                        width: 90,
                        //sort the dates
                        sortMethod: (a, b) => {
                          a = new Date(a).getTime();
                          b = new Date(b).getTime();
                          return b > a ? 1 : -1;
                        },
                      },
                      {
                        Header: 'Updated By',
                        accessor: 'updatedByName',
                        width: 150,
                      },
                      {
                        Header: '',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={this.state.data.length}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default InsulinPrescriptionList;
