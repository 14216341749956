/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Input,
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import ImageUploader from 'react-images-upload';
import moment from 'moment';

const queryString = require('query-string');

const fileReader = new FileReader();

class EducationContentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      nameState: '',
      text: '',
      textState: '',
      image: '',
      imageType: '',
      dateStart: moment(new Date()).format('MM/DD/yyyy'),
      dateEnd: '',
      disabled: false,
      error: '',
      loading: true,
      addedByName: '',
      dateAdded: '',
      updatedByName: '',
      dateUpdated: '',
    };
  }

  Cancel() {
    this.props.history.push('/in/l/educationcontent#top');
  }

  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);

      var qs = queryString.parse(this.props.location.search);
      //do we have a group id?
      //yes
      if (qs['id']) {
        //get the group from the server
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            SECRET: localStorage.getItem('SECRET'),
            PASSPHRASE: localStorage.getItem('PASSPHRASE'),
          },
        };

        fetch(
          window.configuration.EducationContentURL + '?id=' + qs['id'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to edit the condition',
                  disabled: true,
                  loading: false,
                });
              }

              // get error message from body or default to response status
              const error = response.status;
              return Promise.reject(error);
            }
            //did we get an error?
            //no
            else {
              var educationContentResponse = await response.json();

              //did we get a good one?
              //yes
              if (educationContentResponse.id) {
                this.setState({
                  error: '',
                  loading: false,
                  disabled: false,
                  id: educationContentResponse.id,
                  dateStart: moment(educationContentResponse.dateStart).format(
                    'MM/DD/yyyy',
                  ),
                  dateEnd:
                    !educationContentResponse.dateEnd ||
                    educationContentResponse.dateEnd === '0001-01-01T00:00:00'
                      ? ''
                      : moment(educationContentResponse.dateEnd).format(
                          'MM/DD/yyyy',
                        ),
                  name: educationContentResponse.name,
                  nameState: educationContentResponse.name
                    ? 'has-success'
                    : 'has-danger',
                  text: educationContentResponse.text,
                  textState: educationContentResponse.text
                    ? 'has-success'
                    : 'has-danger',
                  image: educationContentResponse.image
                    ? educationContentResponse.image
                    : '',
                  imageType: educationContentResponse.image.startsWith(
                    'data:image',
                  )
                    ? 'image'
                    : 'video',
                  addedByName: educationContentResponse.addedByName
                    ? educationContentResponse.addedByName
                    : 'unknown',
                  dateAdded:
                    new Date(
                      educationContentResponse.dateAdded,
                    ).toLocaleDateString() +
                    ' ' +
                    new Date(
                      educationContentResponse.dateAdded,
                    ).toLocaleTimeString(),
                  updatedByName: educationContentResponse.updatedByName
                    ? educationContentResponse.updatedByName
                    : 'unknown',
                  dateUpdated:
                    new Date(
                      educationContentResponse.dateUpdated,
                    ).toLocaleDateString() +
                    ' ' +
                    new Date(
                      educationContentResponse.dateUpdated,
                    ).toLocaleTimeString(),
                });
              }
            }
          })
          .catch((error) => {
            this.setState({
              error: error,
              disabled: false,
              loading: false,
            });
          });
      }
      //do we have a group id?
      //no
      else {
        this.setState({ loading: false });
      }
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  //this submits the form
  submit(e, navigation) {
    //did we disable the button?
    //yes
    if (this.state.disabled) {
      return;
    }

    //do we have a name?
    //no
    if (this.state.name.length === 0) {
      this.setState({
        error: 'Please enter the content name',
      });
      return;
    }

    //do we have a valid date?
    //no
    if (
      !this.state.dateStart ||
      this.state.dateStart === '' ||
      (this.state.id === '' &&
        new Date(this.state.dateStart) <= new Date(new Date().toDateString()))
    ) {
      this.setState({
        error: 'Please enter the start date starting tomorrow',
      });
      return;
    }

    this.setState({
      disabled: true,
      error: '',
      loading: true,
    });

    // POST request using fetch with error handling
    const requestOptions = {
      method: this.state.id === '' ? 'POST' : 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        SECRET: localStorage.getItem('SECRET'),
        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
      },
      body: JSON.stringify({
        id: this.state.id,
        name: this.state.name,
        text: this.state.text,
        image: this.state.image,
        dateStart: this.state.dateStart,
        dateEnd: this.state.dateEnd,
      }),
    };

    fetch(window.configuration.EducationContentURL, requestOptions)
      .then(async (response) => {
        // check for error response
        //did we get an error?
        //yes
        if (!response.ok) {
          //did we get an unauthorized?
          //yes
          if (response.status === 401) {
            this.props.history.push('/a/logout');
          }
          //did we get an unauthorized?
          //no
          else {
            //set the error
            this.setState({
              error: 'We were unable to add the content',
              disabled: false,
              loading: false,
            });
          }
        }
        //did we get an error?
        //no
        else {
          this.Cancel();
        }
      })
      .catch((error) => {
        this.setState({
          error: error,
          disabled: false,
          loading: false,
        });
      });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader
            height="0"
            width="0"
            type="ThreeDots"
            color="#00BFFF"
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              background: '#99CCFF',
              opacity: '0.6',
              zIndex: '998',
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          ''
        )}
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={8}>
              <Form>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      {this.state.id
                        ? 'Update educational content'
                        : 'Add educational content'}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row style={{ paddingTop: 40 }}>
                      <Col xs={12} md={6}>
                        <FormGroup
                          className={'has-label ' + this.state.nameState}
                        >
                          <Label>Name *</Label>
                          <Input
                            maxLength="150"
                            onChange={(e) =>
                              this.setState({
                                name: e.target.value,
                                nameState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                                error: '',
                              })
                            }
                            autoFocus
                            autoCorrect="true"
                            value={this.state.name}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup className={'has-label'}>
                          <Label>Starting on</Label>
                          <Input
                            maxLength="10"
                            value={this.state.dateStart}
                            onChange={(e) => {
                              this.setState({
                                dateStart: e.target.value,
                                error: '',
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup className={'has-label'}>
                          <Label>Ending on</Label>
                          <Input
                            maxLength="10"
                            value={this.state.dateEnd}
                            onChange={(e) => {
                              this.setState({
                                dateEnd: e.target.value,
                                error: '',
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <FormGroup
                          className={'has-label ' + this.state.textState}
                        >
                          <Label>Text *</Label>
                          <Input
                            type="textarea"
                            style={{
                              border: '1px solid #dadada',
                              minHeight: 200,
                            }}
                            maxLength="1000"
                            onChange={(e) =>
                              this.setState({
                                text: e.target.value,
                                textState:
                                  e.target.value.length > 0
                                    ? 'has-success'
                                    : 'has-danger',
                                error: '',
                              })
                            }
                            autoCorrect="true"
                            value={this.state.text}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        style={{
                          textAlign: 'center',
                          paddingTop: this.state.image ? 30 : 0,
                          paddingBottom: this.state.image ? 50 : 0,
                        }}
                      >
                        {this.state.imageType === 'image' ? (
                          <img
                            src={this.state.image}
                            alt="educational imagery"
                            style={{
                              border: '1px solid #555',
                            }}
                          ></img>
                        ) : this.state.imageType ? (
                          <Label>
                            <h4>Video file</h4>
                          </Label>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} style={{ textAlign: 'center' }}>
                        <Label>Image Upload</Label>
                        <ImageUploader
                          withIcon={false}
                          onChange={(image) => {
                            var reader = new FileReader();
                            reader.readAsDataURL(image[0]);

                            reader.onloadend = function (e) {
                              document.getElementById('file_input_file').value =
                                '';
                              this.setState({
                                image: reader.result,
                                imageType: 'image',
                              });
                            }.bind(this);
                          }}
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={5242880}
                          singleImage={true}
                          label="Select an image file (jpg/jpeg, gif, or png)"
                          buttonText="Select a new image"
                          withPreview={false}
                        />
                      </Col>
                      <Col xs={12} md={6} style={{ textAlign: 'center' }}>
                        <Label>Video Upload</Label>
                        <input
                          id="file_input_file"
                          type="file"
                          accept=".mp4"
                          style={{ width: '100%' }}
                          onChange={(event) => {
                            fileReader.onloadend = () => {
                              this.setState({
                                image: fileReader.result,
                                imageType: 'video',
                              });
                            };
                            fileReader.readAsText(event.target.files[0]);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <FormGroup
                          style={{
                            textAlign: 'end',
                            display: this.state.error ? '' : '',
                          }}
                        >
                          <Label
                            style={{
                              fontSize: 14,
                              color: 'tomato',
                              paddingRight: 15,
                              display: this.state.error ? '' : 'none',
                            }}
                          >
                            {this.state.error}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: 20,
                        display: this.state.addedByName ? '' : 'none',
                      }}
                    >
                      <Col xs={12} md={2}>
                        <FormGroup>
                          <Label>Added:</Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={10}>
                        <FormGroup>
                          <Label>
                            {this.state.dateAdded +
                              ' by ' +
                              this.state.addedByName}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        style={{
                          display: this.state.updatedByName ? '' : 'none',
                        }}
                      >
                        <FormGroup>
                          <Label>Last Updated:</Label>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={10}
                        style={{
                          display: this.state.updatedByName ? '' : 'none',
                        }}
                      >
                        <FormGroup>
                          <Label>
                            {this.state.dateUpdated +
                              ' by ' +
                              this.state.updatedByName}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button color="success" onClick={(e) => this.submit(e)}>
                      {this.state.disabled
                        ? 'One moment...'
                        : this.state.id
                        ? 'Update educational content'
                        : 'Add educational content'}
                    </Button>
                    {/* spacer between buttons */ ' '}
                    <Button
                      style={{ marginLeft: 10 }}
                      color="secondary"
                      onClick={() => this.Cancel()}
                    >
                      Cancel
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default EducationContentForm;
