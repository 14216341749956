/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
// react plugin for creating notifications
import NotificationAlert from 'react-notification-alert';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import routes from 'routes.js';

var ps;

class Admin extends React.Component {
  state = {
    sidebarMini: true,
    backgroundColor: 'blue',
  };

  notificationAlert = React.createRef();
  mainPanel = React.createRef();

  componentDidMount() {
    try {
      //are they using windows?
      //yes
      if (navigator.platform.indexOf('Win') > -1) {
        document.documentElement.className += ' perfect-scrollbar-on';
        document.documentElement.classList.remove('perfect-scrollbar-off');
        ps = new PerfectScrollbar(this.mainPanel.current);
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentWillUnmount() {
    try {
      if (ps && navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.documentElement.className += ' perfect-scrollbar-off';
        document.documentElement.classList.remove('perfect-scrollbar-on');
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(e) {
    try {
      if (e.history.action === 'PUSH') {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.mainPanel.current.scrollTop = 0;
      }
    } catch (error) {
      console.log(error);
    }
  }

  minimizeSidebar = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle('sidebar-mini');
  };

  getRoutes = (routes) => {
    //get our values
    var admin = JSON.parse(localStorage.getItem('Role_Admin'));
    var ma = JSON.parse(localStorage.getItem('Role_MA'));
    var provider = JSON.parse(localStorage.getItem('Role_Provider'));

    return routes.map((prop, key) => {
      //is this collapsed?
      //yes
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }

      //do we have an in layout with the right type?
      //yes
      else if (
        prop.layout === '/in' &&
        (prop.admin === admin || prop.ma === ma || prop.provider === provider)
      ) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            hidden={prop.hidden}
            type={prop.type}
          />
        );
      }
      //do we have an in layout with the right type?
      //no
      else {
        return null;
      }
    });
  };

  getActiveRoute = (routes) => {
    let activeRoute = 'DoseHelpers';

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path,
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    } else {
      return (
        <div className="wrapper">
          <NotificationAlert ref={this.notificationAlert} />
          <Sidebar
            {...this.props}
            routes={routes}
            minimizeSidebar={this.minimizeSidebar}
            backgroundColor={this.state.backgroundColor}
          />
          <div className="main-panel" ref={this.mainPanel}>
            <AdminNavbar
              {...this.props}
              brandText={this.getActiveRoute(routes)}
            />
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="/in" to="/in/dashboard" />
            </Switch>
            {
              // we don't want the Footer to be rendered on full screen maps page
              window.location.href.indexOf('full-screen-maps') !== -1 ? null : (
                <Footer fluid />
              )
            }
          </div>
        </div>
      );
    }
  }
}

export default Admin;
