/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
  Form,
  FormGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import JSONPretty from 'react-json-pretty';

const queryString = require('query-string');
const JSONPrettyMon = require('react-json-pretty/dist/monikai');

class InsulinPrescriptionAudit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      insulinPrescription: {},
      patientName: '',
      testPatient: false,
      loading: true,
    };
  }

  Cancel() {
    this.props.history.push('/in/l/patients#top');
  }

  componentDidMount() {
    //are we logged in?
    //yes
    if (
      localStorage.getItem('LOGGEDIN') &&
      localStorage.getItem('LOGGEDIN') === 'true' &&
      localStorage.getItem('DATEEXPIRES') &&
      new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
    ) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      var qs = queryString.parse(this.props.location.search);

      //do we have a group id?
      //yes
      if (qs['id']) {
        //get the group from the server
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            SECRET: localStorage.getItem('SECRET'),
            PASSPHRASE: localStorage.getItem('PASSPHRASE'),
          },
        };

        fetch(
          window.configuration.InsulinPrescriptionURL + '?id=' + qs['id'],
          requestOptions,
        )
          .then(async (response) => {
            // check for error response
            //did we get an error?
            //yes
            if (!response.ok) {
              //did we get an unauthorized?
              //yes
              if (response.status === 401) {
                this.props.history.push('/a/logout');
              }
              //did we get an unauthorized?
              //no
              else {
                //set the error
                this.setState({
                  error: 'We were unable to get the prescription',
                  loading: false,
                });
              }
              return;
            }
            //did we get an error?
            //no
            else {
              var prescriptionResponse = await response.json();

              //did we get a good one?
              //yes
              if (prescriptionResponse.id) {
                this.setState({
                  error: '',
                  insulinPrescription: prescriptionResponse,
                  patientName: qs['patientName'],
                  testPatient: qs['testPatient'] === 'true',
                  loading: false,
                });
              }
            }
          })
          .catch((error) => {
            this.setState({
              error: error,
              loading: false,
            });
          });
      }
    }
    //are we logged in?
    //no
    else {
      this.props.history.push('/a/logout');
    }
  }

  render() {
    //are we still loading?
    //yes
    if (this.state.loading) {
      return (
        <Loader
          height="0"
          width="0"
          type="ThreeDots"
          color="#00BFFF"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            background: '#99CCFF',
            opacity: '0.6',
            zIndex: '998',
            height: '100%',
            width: '100%',
          }}
        />
      );
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={6}>
              <Form>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Patient:{' '}
                      {this.state.patientName +
                        (this.state.testPatient === true
                          ? ' (TEST PATIENT)'
                          : '')}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={12} md={12}>
                        <FormGroup className={'has-label'}>
                          <JSONPretty
                            id="json-pretty"
                            theme={JSONPrettyMon}
                            data={this.state.insulinPrescription}
                          ></JSONPretty>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-right">
                    <Button
                      style={{ marginLeft: 10 }}
                      color="secondary"
                      onClick={() => this.Cancel()}
                    >
                      Done
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default InsulinPrescriptionAudit;
