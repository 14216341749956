import React from 'react';
import UserAvatar from 'react-user-avatar';
import moment from 'moment';

const styles = {
  bubbleContainer: {
    width: '100%',
    marginBottom: 16,
    padding: '0 16px',
  },
};

const MessageBubble = ({ senderId, sender, message, sentTime, admin }) => {
  return (
    <div style={styles.bubbleContainer}>
      <div
        style={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: senderId === admin?.userId ? 'row-reverse' : 'row',
        }}
      >
        <UserAvatar
          size={40}
          name={sender}
          color={senderId === admin?.userId ? '#55abf9' : '#54e4fc'}
        />
        {/* Message */}
        <p
          style={{
            backgroundColor: senderId === admin?.userId ? '#55abf9' : '#2e3956',
            color: '#fff',
            padding: 8,
            borderRadius: 8,
            maxWidth: '65%',
            margin: '0 4px',
            fontSize: 16,
            borderTopLeftRadius: senderId !== admin?.userId ? 0 : 8,
            borderTopRightRadius: senderId === admin?.userId ? 0 : 8,
          }}
        >
          {message}
        </p>
        <div>
          <p
            style={{
              fontSize: 12,
              fontFamily: 'Montserrat',
              color: '#fff',
              margin: 0,
              textAlign: senderId === admin?.userId ? 'end' : 'start',
            }}
          >
            {
              //is this sent today?
              moment(sentTime).format('M/D/YY') ===
              moment(new Date()).format('M/D/YY')
                ? //yes - mark it as today
                  'Today'
                : //no - was it sent yesterday?
                moment(sentTime).format('M/D/YY') ===
                  moment(new Date()).add(-1, 'day').format('M/D/YY')
                ? //yes - mark it as yesterday
                  'Yesterday'
                : //no - mark the date
                  moment(sentTime).format('M/D/YY')
            }
          </p>
          <p
            style={{
              fontSize: 12,
              fontFamily: 'Montserrat',
              color: '#fff',
              margin: 0,
              textAlign: senderId === admin?.userId ? 'end' : 'start',
            }}
          >
            {moment(sentTime).format('h:mm a')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MessageBubble;
