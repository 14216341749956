/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React from 'react';
import { NavLink } from 'react-router-dom';
// used for making the prop types of this component
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
// reactstrap components
import { Nav, Collapse, Button } from 'reactstrap';
import logo from '../../../src/assets/img/logo.png';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      ...this.getCollapseStates(props.routes),
    };
    this.sidebar = React.createRef();
  }

  componentDidMount() {
    try {
      if (navigator.platform.indexOf('Win') > -1) {
        ps = new PerfectScrollbar(this.sidebar.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentWillUnmount() {
    try {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }

      // to stop the warning of calling setState of unmounted component
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /in/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    var isAdmin = localStorage.getItem('Role_Admin') === 'true';
    var isMA = localStorage.getItem('Role_MA') === 'true';
    var isProvider = localStorage.getItem('Role_Provider') === 'true';

    return routes.map((prop, key) => {
      //is this one they get?
      //no
      if (
        //not an admin
        isAdmin === false &&
        //not an MA
        isMA === false &&
        //is a provider
        isProvider === true &&
        //not a provider item
        prop.provider == false
      ) {
        return null;
      }
      //is this one they get?
      //no
      else if (
        //not an admin
        isAdmin === false &&
        //not a provider
        isProvider === false &&
        //is an MA
        isMA === true &&
        //is not an MA item
        prop.ma == false
      ) {
        return null;
      }
      //is this one they get?
      //no
      else if (isAdmin === true && prop.admin == false) {
        return null;
      }
      //is this one they get?
      //yes
      else if (!prop.hidden) {
        //is this collapsed?
        //yes
        if (prop.collapse) {
          var st = {};
          st[prop['state']] = !this.state[prop.state];
          return (
            <li
              className={
                this.getCollapseInitialState(prop.views) ? 'active' : ''
              }
              key={key}
            >
              <a
                href="#dosehelper"
                data-toggle="collapse"
                aria-expanded={this.state[prop.state]}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(st);
                }}
              >
                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon} />
                    <p>
                      {prop.name}
                      <b className="caret" />
                    </p>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">
                      {prop.name}
                      <b className="caret" />
                      companyResponse.contactEmailAddress
                    </span>
                  </>
                )}
              </a>
              <Collapse isOpen={this.state[prop.state]}>
                <ul className="nav">{this.createLinks(prop.views)}</ul>
              </Collapse>
            </li>
          );
        }
        return (
          <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
            <NavLink to={prop.layout + prop.path} activeClassName="">
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            </NavLink>
          </li>
        );
      }
    });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    //grab the base URL
    var urlPath = window.location.href.split('?')[0];
    return urlPath.indexOf(routeName) > -1 ? 'active' : '';
  };

  render() {
    return (
      <>
        <div className="sidebar" data-color={this.props.backgroundColor}>
          <div className="logo">
            <a
              href="/in/dashboard"
              className="simple-text logo-mini"
              style={{ paddingLeft: 5 }}
            >
              <div className="logo-img">
                <img src={logo} alt="react-logo" />
              </div>
            </a>
            <a href="/in/dashboard" className="simple-text logo-normal">
              Dose Helper
            </a>
            <div className="navbar-minimize">
              <Button
                outline
                className="btn-round btn-icon"
                color="neutral"
                id="minimizeSidebar"
                onClick={() => this.props.minimizeSidebar()}
              >
                <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
              </Button>
            </div>
          </div>

          <div className="sidebar-wrapper" ref={this.sidebar}>
            <Nav>{this.createLinks(this.props.routes)}</Nav>
          </div>
        </div>
      </>
    );
  }
}

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: 'blue',
  minimizeSidebar: () => {},
};

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf(['blue']),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

export default Sidebar;
