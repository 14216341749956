/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import {
  Row,
  CardHeader,
  Card,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  InputGroup,
  Button,
  Label,
  Input,
} from 'reactstrap';
import bgImage from 'assets/img/blurred-image-1.jpg';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import ReactGA from 'react-ga';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      emailAddress: '',
      code: '',
      error: '',
      loading: false,
    };
  }

  componentDidMount() {
    try {
      ReactGA.pageview(window.location.pathname + window.location.search);
      document.body.classList.add('login-page');
    } catch (error) {
      console.log(error);
    }
  }

  /*
  componentWillUnmount() 
  {
    try
    {
      document.body.classList.remove("login-page");
    }
    catch(error)
    {
      console.log(error);
    }
  }
  */

  //this submits the phone number or the code
  submitForm() {
    try {
      //did we disable the button or need to wait?
      //yes
      if (
        this.state.disabled ||
        this.state.emailAddress === '' ||
        this.state.code.length < 6
      ) {
        return;
      }

      //is this disabled?
      //yes
      if (this.state.disabled === false) {
        //enable it
        this.setState({
          disabled: true,
          loading: true,
        });
      }

      // POST request using fetch with error handling
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailAddress: this.state.emailAddress,
          code: this.state.code,
        }),
      };

      fetch(window.configuration.GoogleAuthURL, requestOptions)
        .then(async (response) => {
          //did we get an error?
          //yes
          if (!response.ok) {
            //set the error
            this.setState({
              error: 'We were unable to verify your account. Try again.',
              loading: false,
              disabled: false,
            });

            var error = response.status;
            return Promise.reject(error);
          }
          //did we get an error?
          //no
          else {
            //grab our response
            var googleResponse = await response.json();

            //did we get a good response?
            //no
            if (googleResponse.verified === false) {
              //hide the phone number and show the code field
              this.setState({
                error: 'Unable to verify your account. Try again',
                disabled: false,
                loading: false,
              });
            }
            //did we get a good response?
            //yes
            else if (googleResponse.verified === true) {
              //set the remaining global items
              localStorage.setItem('userId', googleResponse.userId);
              localStorage.setItem('firstName', googleResponse.firstName);
              localStorage.setItem('lastName', googleResponse.lastName);
              localStorage.setItem('emailAddress', this.state.emailAddress);
              localStorage.setItem(
                'SECRET',
                googleResponse.authResponse.secret,
              );
              localStorage.setItem(
                'PASSPHRASE',
                googleResponse.authResponse.passPhrase,
              );
              localStorage.setItem(
                'DATEEXPIRES',
                googleResponse.authResponse.dateExpires,
              );
              //set our roles
              localStorage.setItem(
                'Role_Admin',
                googleResponse.roles.indexOf('Admin') > -1,
              );
              localStorage.setItem(
                'Role_MA',
                googleResponse.roles.indexOf('Medical Assistant') > -1,
              );
              localStorage.setItem(
                'Role_Provider',
                googleResponse.roles.indexOf('Provider') > -1,
              );
              //mark them as logged in
              localStorage.setItem('LOGGEDIN', 'true');

              //are they a physician or ma?
              //yes
              if (
                googleResponse.roles.indexOf('Medical Assistant') > -1 ||
                googleResponse.roles.indexOf('Provider') > -1
              ) {
                //send them to the messaging
                this.props.history.push('/in/messaging/:id');
              }
              //are they a physician or ma?
              //no
              else {
                this.props.history.push('/in/dashboard');
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            error: 'There was a problem confirming your account. Try again.',
            disabled: false,
            loading: false,
          });
        });
    } catch (error) {
      console.log(error);
      this.setState({
        error: 'There was a problem confirming your account. Try again.',
        disabled: false,
        loading: false,
      });
    }
  }

  _handleKeyDown = (e) => {
    try {
      //did they press enter?
      //yes
      if (e.key === 'Enter') {
        this.submitForm(null, null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader
            height="0"
            width="0"
            type="ThreeDots"
            color="#00BFFF"
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              background: '#99CCFF',
              opacity: '0.6',
              zIndex: '998',
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          ''
        )}
        <div className="content">
          <div className="login-page">
            <Container>
              <Col xs={12} md={6} lg={6} className="ml-auto mr-auto">
                <Form>
                  <Card className="card-login card-plain">
                    <CardHeader
                      style={{
                        color: '#FFFFFF',
                        textAlign: 'center',
                      }}
                    >
                      Enter your email address and authenticator code
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs={12} md={12}>
                          <InputGroup
                            className={'no-border form-control-lg'}
                            style={{ textAlign: 'center' }}
                          >
                            <Input
                              id="username"
                              name="username"
                              style={{
                                paddingLeft: 10,
                                fontSize: 18,
                                height: 45,
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                textAlign: 'center',
                              }}
                              type="text"
                              autoFocus={true}
                              placeholder="name@domain.com"
                              maxLength="255"
                              value={
                                this.state.emailAddress
                                  ? this.state.emailAddress
                                  : ''
                              }
                              onChange={(e) =>
                                this.setState({
                                  emailAddress: e.target.value,
                                  error: '',
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <InputGroup className={'no-border form-control-lg'}>
                            <Input
                              style={{
                                paddingLeft: 10,
                                fontSize: 18,
                                height: 45,
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                textAlign: 'center',
                              }}
                              type="text"
                              placeholder="123 456"
                              maxLength="7"
                              value={this.state.code ? this.state.code : ''}
                              onKeyDown={this._handleKeyDown}
                              onChange={(e) =>
                                this.setState({
                                  code: e.target.value,
                                  error: '',
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <InputGroup className={'no-border form-control-lg'}>
                            <Button
                              block
                              color="primary"
                              disabled={this.state.disabled}
                              onClick={(e) => this.submitForm()}
                              size="lg"
                              className="mb-3 btn-round"
                            >
                              {this.state.disabled ? 'One moment...' : 'Login'}
                            </Button>
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Label
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          display: this.state.error.length > 0 ? '' : 'none',
                        }}
                      >
                        {this.state.error}
                      </Label>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundImage: 'url(' + bgImage + ')' }}
        />
      </>
    );
  }
}

export default LoginPage;
